import React from 'react'
import BrowserRouter from "./routes"
//var cors = require('cors')
// import Header from './Header';
// import Menu from './Menu';
// import Footer from './Footer';
// import Content from './Content';
const TOKEN_KEY = "KEY_APP_BGG";
 
const App = () => {
          //console.log("logout");
          const teste = sessionStorage.removeItem(TOKEN_KEY); //sessionStorage.removeItem(TOKEN_KEY);
          if (teste) { };
          {/* <Navigate to="/login" />; */ }
          //this.props.history.push("/login");
          window.open("/login", "_self");
};
//App.use(cors())

export default App;


// export default class App extends Component {
//   render() {
//     return (
//       <div>
//       <Header />
//       <Menu/>
//       <Content/>
//       <Footer/>
//       </div>
//     )
//   }
// }
