import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import Loading from "./assets/loading11.gif";
import Loading2 from "./assets/loading4.gif";
import Logo from "./assets/login.png";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './clientes.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import '../node_modules/moment/locale/pt-br';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link, animateScroll as scroll } from "react-scroll";
import Hotkeys from 'react-hot-keys';
import date from 'date-and-time';
import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType
} from "docx";
import { CL } from "pdfmake/build/pdfmake";
//import * as fs from "fs";
//const fs = require('fs')

var gGlobal = require('./components/globalvar.js');

const wordIcon = function (cell, formatterParams) { //plain text value
  return "<i class='far fa-file-word'></i>";
};
const editIcon = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-edit'></i>";
};
const iconContrato = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-file-download'></i>";
};
const iconwhatsappweb = function (cell, formatterParams) { //plain text value
  return "<i class='fa fa-whatsapp'></i>";
}

const iconLixeira = function (cell, formatterParams) { //plain text value
  //if (cell.getRow().getData().progress < 11){
  //return "<i class='fa fa-trash-o'></i>";
  return "❌";
  //} else {
  //  return "-";
  //}
}

global.gLog = [];

function rowClick(e, row) {
  ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
  //cl("rowClick id: " + row.getData().id, row, e);
  ////cl(rep);

};

function Gerador() {

  const formatData = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    let dia = cell.getValue().substr(8, 2)
    let mes = cell.getValue().substr(5, 2)
    let ano = cell.getValue().substr(0, 4)
    let result = dia + '/' + mes + '/' + ano

    return result; //return the contents of the cell;  
  }

  const formatDataHora = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    //cl("clcell",cell.getValue());
    if (!cell.getValue()) { return }
    let dia = cell.getValue().substr(8, 2)
    let mes = cell.getValue().substr(5, 2)
    let ano = cell.getValue().substr(2, 2)
    let hora = cell.getValue().substr(11, 2)
    let minuto = cell.getValue().substr(14, 2)
    let result = dia + '/' + mes + '/' + ano + ' ' + hora + 'h' + minuto

    return result; //return the contents of the cell;  
  }

  const formatDataHoraSpan = function (params) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    //cl("clcell",cell.getValue());
    if (!params) { return }
    let dia = params.substr(8, 2)
    let mes = params.substr(5, 2)
    let ano = params.substr(2, 2)
    let hora = params.substr(11, 2)
    let minuto = params.substr(14, 2)
    let result = dia + '/' + mes + ' ' + hora + 'h' + minuto

    return result; //return the contents of the cell;  
  }

  const formatStatus = function (cell, formatterParams, onRendered) {
    let result = ''
    if (cell.getValue() === 'inicial') {
      result = '<div class="statusDisp">DISPONÍVEL</div>'
    }
    if (cell.getValue() === 'atendimento') {
      result = '<div class="statusAnda">ANDAMENTO</div>'
    }
    if (cell.getValue() === 'finalizado') {
      result = '<div class="aprovsim">FINALIZADA</div>'
    }

    return result; //return the contents of the cell;  
  }

  const formatProf = function (cell, formatterParams, onRendered) {
    let result = cell.getValue()
    if (cell.getValue() === 'Disponível') {
      result = 'localizando...'
    }


    return result; //return the contents of the cell;  
  }

  global.inicial = 0;

  const [Atu, setAtu] = useState([]);
  const [rep, setrep] = useState([]);
  const [repmeus, setrepmeus] = useState([]);
  const [repCliout, setrepCliout] = useState([]);
  const [AtuCad, setAtuCad] = useState([]);
  const [NovaObs, setNovaObs] = useState([]);  
  const [EventoCancelar, setEventoCancelar] = useState([]);
  const [AgendaProfAtu, setAgendaProfAtu] = useState([]);
  const [AgendaProfFim, setAgendaProfFim] = useState([]);
  const [AgendaStatus, setAgendaStatus] = useState([]);  

  const [DocNow, setDocNow] = useState([]);
  const isFirstRunAtuCad = useRef(true);
  const isFirstRunEventoCancelar = useRef(true);
  const isFirstRunAgendaProfAtu = useRef(true);
  const isFirstRunAgendaProfFim = useRef(true);
  const isFirstRunAgendaStatus = useRef(true);
  const isFirstRunRep = useRef(true);


  function abreviar(str) {

    if (str) {
  
      const [nome, ...sobrenomes] = str.split(' ');
  
      const abreviaturas = sobrenomes.reduce((arr, str) => {
        const letraGrande = str.match(/[A-ZÖÄÅÀÁÂÃÌÍÒÓÉÊÚ]/);
        if (!letraGrande) return arr;
        return arr.concat(`${letraGrande[0]}.`);
      }, []);
  
      return [nome, ...abreviaturas].join(' ');
    } else {
      return str;
    }
  }


  function montarFichas(params) {
    let edivFichas = document.getElementById('divfichas');
    let edivFichasdisp = document.getElementById('divfichasdisp');
    let edivFichasDetalhes = document.getElementById('divFichasDetalhes');
    if (edivFichasDetalhes) { edivFichasDetalhes.remove(); }
    let edivFichasDetalhesdisp = document.getElementById('divFichasDetalhesdisp');
    if (edivFichasDetalhesdisp) { edivFichasDetalhesdisp.remove(); }
    if (rep.length < 1) {
      return
    }


    let divresult = document.createElement('div');
    divresult.className = 'row';
    divresult.id = 'divFichasDetalhes';

    let divresultdisp = document.createElement('div');
    divresultdisp.className = 'row';
    divresultdisp.id = 'divFichasDetalhesdisp';

    let repMeusSer = rep.filter(function (item, num) {
      return ((item.resourceId === global.gIDProf) && (item.status === 'atendimento'));
    });
    cl("rep",rep)
    let repSerDisp = rep.filter(function (item, num) {
      return ((item.resourceId === '1') || ((item.resourceId === global.gIDProf) && (item.status === 'inicial')));
    });


    let k = 0;
    for (k = 0; k < repMeusSer.length; k++) {
      let div1 = document.createElement('div');
      div1.className = 'col-md-4 col-sm-6 col-12'
      let div2 = document.createElement('div');
      div2.className = 'info-box'
      let span1 = document.createElement('span')
      let statusLabel = ''

      switch (repMeusSer[k].status) {
        case 'inicial':
          span1.className = 'info-box-icon bg-info';
          statusLabel = 'Aguardando';
          break;
        case 'atendimento':
          span1.className = 'info-box-icon bg-warning';
          statusLabel = 'Em Andamento';
          break;
        case 'finalizado':
          span1.className = 'info-box-icon bg-success';
          statusLabel = 'Finalizado';
          break;
        default:
          span1.className = 'info-box-icon bg-info';
          break;
      }
      let repdatahora = formatDataHoraSpan(repMeusSer[k].start);
      //span1.innerHTML = repMeusSer[k].id + '<br><div class="dataid">' + repdatahora + '</div>'
      span1.innerHTML = '<div class="datadivid">' + repMeusSer[k].id + '</div><br><div class="dataid">' + repdatahora + '<br>⏱' + repMeusSer[k].tempdisp + ' min.<br>R$ ' + repMeusSer[k].valor + '</div>'

      let div3 = document.createElement('div');
      div3.className = 'info-box-content'

      let spanNome = document.createElement('span')
      spanNome.className = 'info-box-text'
      spanNome.innerHTML = '<b>' + repMeusSer[k].title + '</b>';

      let spanTipo = document.createElement('span')
      spanTipo.className = 'info-box-text'
      spanTipo.innerHTML = '<b>' + repMeusSer[k].nomeentrega + '</b>';


      let span2 = document.createElement('span')
      span2.className = 'info-box-text'
      if(repMeusSer[k].setor.length > 16){
        span2.innerHTML = 'Setor: ' + repMeusSer[k].setor.substr(0, 16) + '...';
      } else {
        span2.innerHTML = 'Setor: ' + repMeusSer[k].setor;
        
      }

      let span3 = document.createElement('span')
      span3.className = 'info-box-text'
      span3.innerHTML = 'End.: ' + repMeusSer[k].entrega;

      let textarea1 = document.createElement('textarea')
      let rowscount = 3;
      //rowscount = repMeusSer[k].pedido.split("\n").length;
      textarea1.className = 'form-control textarea'
      textarea1.id = 'txtaCard'
      textarea1.disabled = true;
      textarea1.rows = rowscount;
      textarea1.value = repMeusSer[k].pedido;

      let span4 = document.createElement('span')
      span4.className = 'info-box-text'
      let motorista = '';
      if (repMeusSer[k].resourceTitle === 'Disponível') {
        motorista = 'localizando...'
      } else {
        motorista = repMeusSer[k].resourceTitle;
      }
      span4.innerHTML = 'Profissional: ' + motorista;

      let btn1 = document.createElement('button');
      btn1.className = 'btn btn-danger bggcardbtn';
      btn1.innerHTML = 'Cancelar';
      let selId = repMeusSer[k].id;
      let selTitle = repMeusSer[k].title;
      let selColeta = repMeusSer[k].coleta;
      let selEntrega = repMeusSer[k].entrega;
      //btn1.onclick = () => confirmDelProp(selId, selTitle, selColeta, selEntrega);

      let btn2 = document.createElement('button');
      btn2.className = 'btn btn-info bggcardbtn';
      btn2.innerHTML = 'Visualizar';
      let visId = repMeusSer[k].id;
      let visTitle = repMeusSer[k].title;
      let visColeta = repMeusSer[k].coleta;
      let visEntrega = repMeusSer[k].entrega;
      let visFoneEntrega = repMeusSer[k].foneentrega;
      let visNomeEntrega = repMeusSer[k].nomeentrega;
      let visSetor = repMeusSer[k].setor;
      let visPedido = repMeusSer[k].pedido;
      let visTempdisp = repMeusSer[k].tempdisp;
      let visValor = repMeusSer[k].valor;      
      let visData = formatDataHoraSpan(repMeusSer[k].start);
      let visTipo = repMeusSer[k].servicos;
      btn2.onclick = () => visualizarProp(visId, visTitle, visColeta, visEntrega, visNomeEntrega, visFoneEntrega, motorista, visData, visTipo, statusLabel, visSetor, visPedido, visTempdisp, visValor);
      //numprop, cliente, Coleta, Entrega, nomeEntrega, foneEntrega, motorista, data, tipo,
      //numprop, cliente, Coleta, Entrega, motorista, data, tipo

      let btn3 = document.createElement('button');
      btn3.className = 'btn btn-secondary bggcardbtn';
      btn3.innerHTML = 'Obs.';
      btn3.onclick = () => incluirObs(visId, visTitle, visColeta, visEntrega, visNomeEntrega, visFoneEntrega, motorista, visData, visTipo, statusLabel, visSetor, visPedido, visTempdisp, visValor);

      let btn5 = document.createElement('button');
      btn5.className = 'btn btn-danger bggcardbtn';
      btn5.id = 'btnDevolver';
      btn5.innerHTML = 'Cancelar';
      btn5.onclick = () => cancelaProf(visId, visTitle, visColeta, visEntrega, visNomeEntrega, visFoneEntrega, motorista, visData, visTipo, statusLabel, visSetor, visPedido, visTempdisp, visValor);



      let btn4 = document.createElement('button');
      btn4.className = 'btn btn-primary bggcardbtn';
      btn4.innerHTML = 'Finalizar';
      btn4.onclick = () => finalizarProp(visId, visTitle, visColeta, visEntrega, motorista, visData, visTipo, statusLabel);
      //numprop, cliente, Coleta, Entrega, motorista, data, tipo

      let btnWhatsApp = document.createElement('i')
      btnWhatsApp.className = 'btn btn-success fa fa-whatsapp btnmot';

      let zapTel = repMeusSer[k].fone;
      let clititle = repMeusSer[k].title;
      btnWhatsApp.onclick = () => whatsappweb(clititle, zapTel);

      let btnWhatsAppEntrega = document.createElement('i')
      btnWhatsAppEntrega.className = 'btn btn-success fa fa-whatsapp btnmot';
      btnWhatsAppEntrega.id = "btnWhatsAppEntrega";
      let zapNomeEntrega = repMeusSer[k].nomeentrega;
      let zapTelEntrega = repMeusSer[k].foneentrega;
      btnWhatsAppEntrega.onclick = () => whatsappweb(zapNomeEntrega, zapTelEntrega);


      let divBtns = document.createElement('div');
      divBtns.id = 'divbtnsAction'

      if (repMeusSer[k].status !== 'inicial') {
        spanNome.appendChild(btnWhatsApp)
      }

      div3.appendChild(spanNome)
      div3.appendChild(span2)
      div3.appendChild(span3)
      div3.appendChild(spanTipo)
      div3.appendChild(textarea1)
      //div3.appendChild(span4)
      /*       if (repMeusSer[k].status !== 'inicial') {
              span4.appendChild(btnWhatsApp)
            } */
      divBtns.appendChild(btn2)
      divBtns.appendChild(btn4)
      //divBtns.appendChild(btn3)
      if (repMeusSer[k].status !== 'inicial') {
        spanTipo.appendChild(btnWhatsAppEntrega)
      }
      if (repMeusSer[k].status === 'atendimento') {
        divBtns.appendChild(btn3)
        divBtns.appendChild(btn5)
      }      
      div3.appendChild(divBtns)

      div2.appendChild(span1)
      div2.appendChild(div3)
      div1.appendChild(div2)
      divresult.appendChild(div1)

    }

    k = 0;
    for (k = 0; k < repSerDisp.length; k++) {
      let div1b = document.createElement('div');
      div1b.className = 'col-md-4 col-sm-6 col-12'
      let div2b = document.createElement('div');
      div2b.className = 'info-box'
      let span1b = document.createElement('span')
      let statusLabel = ''

      switch (repSerDisp[k].status) {
        case 'inicial':
          span1b.className = 'info-box-icon bg-info';
          statusLabel = 'Aguardando';
          break;
        case 'atendimento':
          span1b.className = 'info-box-icon bg-warning';
          statusLabel = 'Em Andamento';
          break;
        case 'finalizado':
          span1b.className = 'info-box-icon bg-success';
          statusLabel = 'Finalizado';
          break;
        default:
          span1b.className = 'info-box-icon bg-info';
          break;
      }
      let repdatahora = formatDataHoraSpan(repSerDisp[k].start);
      //span1b.innerHTML = repSerDisp[k].id + '<br><div class="dataid">' + repdatahora + '</div>'
      span1b.innerHTML = '<div class="datadivid">' + repSerDisp[k].id + '</div><br><div class="dataid">' + repdatahora + '<br>⏱' + repSerDisp[k].tempdisp + ' min.</div>'

      let div3b = document.createElement('div');
      div3b.className = 'info-box-content'

      let spanNomeb = document.createElement('span')
      spanNomeb.className = 'info-box-text'
      spanNomeb.innerHTML = '<b>' + repSerDisp[k].title + '</b>';

      let spanTipob = document.createElement('span')
      spanTipob.className = 'info-box-text'
      spanTipob.innerHTML = '<b>' + repSerDisp[k].servicos + '</b> - ' + statusLabel;


      let span2b = document.createElement('span')
      span2b.className = 'info-box-text'
      span2b.innerHTML = 'Setor: ' + repSerDisp[k].setor;

      let span3b = document.createElement('span')
      span3b.className = 'info-box-text'
      span3b.innerHTML = 'End.: ' + repSerDisp[k].entrega;



      let span4b = document.createElement('span')
      span4b.className = 'info-box-text'
      let motorista = '';
      if (repSerDisp[k].resourceTitle === 'Disponível') {
        motorista = 'localizando...'
      } else {
        motorista = repSerDisp[k].resourceTitle;
      }
      span4b.innerHTML = 'Profissional: ' + motorista;

      let btn1b = document.createElement('button');
      btn1b.className = 'btn btn-danger bggcardbtn';
      btn1b.innerHTML = 'Cancelar';
      let selId = repSerDisp[k].id;
      let selTitle = repSerDisp[k].title;
      let selColeta = repSerDisp[k].coleta;
      let selEntrega = repSerDisp[k].entrega;
      //btn1b.onclick = () => confirmDelProp(selId, selTitle, selColeta, selEntrega);

      let btn2b = document.createElement('button');
      btn2b.className = 'btn btn-info bggcardbtn';
      btn2b.innerHTML = 'Visualizar';
      let visId = repSerDisp[k].id;
      let visTitle = repSerDisp[k].title;
      let visColeta = repSerDisp[k].coleta;
      let visEntrega = repSerDisp[k].entrega;
      let visFoneEntrega = repSerDisp[k].foneentrega;
      let visNomeEntrega = repSerDisp[k].nomeentrega;
      let visSetor = repSerDisp[k].setor;
      let visPedido = repSerDisp[k].pedido;
      let visTempdisp = repSerDisp[k].tempdisp;
      let visValor = repSerDisp[k].valor;       
      let visData = formatDataHoraSpan(repSerDisp[k].start);
      let visTipo = repSerDisp[k].servicos;
      btn2b.onclick = () => visualizarProp(visId, visTitle, visColeta, visEntrega, 'Somente após iniciar', '', motorista, visData, visTipo, statusLabel,visSetor, 'SOMENTE APÓS INICIAR', visTempdisp, visValor);
      //numprop, cliente, Coleta, Entrega, motorista, data, tipo

      let btn3b = document.createElement('button');
      btn3b.className = 'btn btn-success bggcardbtn';
      btn3b.innerHTML = 'Iniciar';
      btn3b.onclick = () => iniciarProp(visId, visTitle, visColeta, visEntrega, motorista, visData, visTipo, statusLabel);
      //numprop, cliente, Coleta, Entrega, motorista, data, tipo

      let btnWhatsAppb = document.createElement('i')
      btnWhatsAppb.className = 'btn btn-success fa fa-whatsapp btnmot';

      let zapTel = repSerDisp[k].fone;
      let clititle = repSerDisp[k].title;
      btnWhatsAppb.onclick = () => whatsappweb(clititle, zapTel);


      let divBtns = document.createElement('div');
      divBtns.id = 'divbtnsAction'

      if (repSerDisp[k].status !== 'inicial') {
        spanNomeb.appendChild(btnWhatsAppb)
      }

      div3b.appendChild(spanNomeb)
      div3b.appendChild(spanTipob)
      div3b.appendChild(span2b)
      div3b.appendChild(span3b)
      //div3b.appendChild(textarea1)
      //div3b.appendChild(span4b)
      /*       if (repSerDisp[k].status !== 'inicial') {
              span4b.appendChild(btnWhatsApp)
            } */
      divBtns.appendChild(btn2b)
      divBtns.appendChild(btn3b)
      if (repSerDisp[k].status === 'inicial') {
        //divBtns.appendChild(btn1b)
      }
      div3b.appendChild(divBtns)

      div2b.appendChild(span1b)
      div2b.appendChild(div3b)
      div1b.appendChild(div2b)
      divresultdisp.appendChild(div1b)

    }



    edivFichas.appendChild(divresult);
    edivFichasdisp.appendChild(divresultdisp);


  }

  //atualiza automatico
  useEffect(() => {
    async function agendachecaAtu(atu) {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('user', global.gIdUser);
      bodyFormData.set('validauser', 1);

      const response = await axios({
        method: 'post',
        url: 'https://api.youevolution.com.br/listaAgendaAtu.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if(response['data'][0]['status'] === '0') {
        //cl("desativado")
        window.open("/logout", "_self");
      }
      
      
      global.AgendaAtu = new Date(response['data'][0]['res']);

      if (atu === 1) {
        global.AgendaAtuComp = new Date(response['data'][0]['res']);
        //cl("Atu", atu)

      }
      /*     //cl("Ax-AgendaAtu", global.AgendaAtu)
          //cl("AX-AgendaAtuComp", global.AgendaAtuComp) */

    }
    let timeset = 3000;

    agendachecaAtu(1);

    const interval = setInterval(() => {

      /*      //cl("Inter-AgendaAtu", global.AgendaAtu)
          //cl("Inter-AgendaAtuComp", global.AgendaAtuComp)  */
      ////cl("atualizando...linha 126")

      if (global.AgendaAtuComp.getTime() !== global.AgendaAtu.getTime()) {
  /*       //cl("-AgendaAtu", global.AgendaAtu)
        //cl("-AgendaAtuComp", global.AgendaAtuComp)
   */        agendachecaAtu(1);
        global.AgendaAtuComp = global.AgendaAtu;
        /*       //cl("-2AgendaAtu", global.AgendaAtu)
              //cl("-2AgendaAtuComp", global.AgendaAtuComp) */
        setAtu({ '0': '0' });

      } else {
        /*       //cl("M-AgendaAtu", global.AgendaAtu)
              //cl("M-AgendaAtuComp", global.AgendaAtuComp) */
        agendachecaAtu();

      }
    }, timeset)


    return () => clearInterval(interval)
  }, [])

  useEffect(() => {

    //cl("clrepmeus", repmeus);

  }, [repmeus])

  useEffect(() => {
    /*     if (isFirstRunRep.current) {
          isFirstRunRep.current = false;
          return;
        }  */
    //cl("clrep", rep);
    if (rep.length > 0) {
      function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }

      async function loopDisplay(params, o3) {
        let ecardOcorrencias = document.getElementById('cardOcorrencias');
        let ecardOcorrencias3 = document.getElementById('gridListaServ');
        if ((ecardOcorrencias) && (o3 !== 1)) {
          ecardOcorrencias.style.display = params;

          if (o3 !== 1) {
            montarFichas();
          }

          if (ecardOcorrencias3) {
            let repMeusSerRT = rep.filter(function (item, num) {
              return item.resourceId === global.gIDProf;
            });
            //await delay(500);
            setrepmeus(repMeusSerRT);
           //cl("clrepmeus",repmeus); 
          } else {
            await delay(500);
            loopDisplay(params, 1);
          }
        } else if ((o3 === 1) && (ecardOcorrencias3)){

            let repMeusSerRT = rep.filter(function (item, num) {
              return item.resourceId === global.gIDProf;
            });
            setrepmeus(repMeusSerRT);
            //cl("clrepmeus",repmeus); 
            o3 = 0;         

        } else {
          await delay(1000);
          loopDisplay(params,o3);
        };

      }

      if ((!rep[0]['id']) || (rep[0]['id'] === null) || (rep[0]['id'] === undefined)) {
        //cl("rep clentrou");

        loopDisplay('');
        //loopDisplay('none');

        return
      } else {
        //cl("rep else");
        loopDisplay('');

      }
    }

  }, [rep])

  useEffect(() => {



  }, [DocNow])

  function removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }


  useEffect(() => {

    if (isFirstRunEventoCancelar.current) {
      isFirstRunEventoCancelar.current = false;
      return;
    }

    const { id, user } = EventoCancelar

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', id);
      bodyFormData.set('user', user);
      bodyFormData.set('status', 'cancelado');
      const response = await axios({
        method: 'post',

        url: 'https://api.youevolution.com.br/EventoCancelar.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        //cl("result", response)
        setrepCliout({ '0': '0' });
        setAtu({ '0': '0' });
      };


      if (!ignore) {
      }
    }

    //cl('gEventoCancelar', global.gEventoCancelar)
    if (global.gEventoCancelar === 1) {

      fetchdata();
      global.gEventoCancelar = 0;

    } else { return };
    return () => { ignore = true };

  }, [EventoCancelar])

  useEffect(() => {

    cl("clNovaObs",NovaObs);
    if (global.setNovaObs !== 1) {
      return;
    }

    const { id, nome, obs, novaobs, user } = NovaObs
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', id);
      bodyFormData.set('nome', nome);
      bodyFormData.set('obs', obs);
      bodyFormData.set('novaobs', novaobs);
      bodyFormData.set('user', user);
      const response = await axios({
        method: 'post',

        url: 'https://api.youevolution.com.br/novaObs.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        //cl("result", response)
        //setrepCliout({ '0': '0' });
        setAtu({ '0': '0' });
      };


      if (!ignore) {
      }
    }
    
    //cl('gEventoCancelar', global.gEventoCancelar)
    if (global.setNovaObs === 1) {

      fetchdata();
      global.setNovaObs = 0;

    } else { return };
    return () => { ignore = true };

  }, [NovaObs])

  useEffect(() => {

    if (isFirstRunAgendaProfFim.current) {
      isFirstRunAgendaProfFim.current = false;
      return;
    }

    const { id, idprof } = AgendaProfFim

    //cl("clAgendaProfFim",AgendaProfFim);

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', id);
      bodyFormData.set('idprof', idprof);
      //bodyFormData.set('status', 'cancelado');
      const response = await axios({
        method: 'post',

        url: 'https://api.youevolution.com.br/AgendaProfFim.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        //cl("result", response)
        setrepCliout({ '0': '0' });
        setAtu({ '0': '0' });
      };


      if (!ignore) {
      }
    }

    //cl('gAgendaProfFim', global.gAgendaProfFim)
    if (global.gAgendaProfFim === 1) {

      fetchdata();
      global.gAgendaProfFim = 0;

    } else { return };
    return () => { ignore = true };

  }, [AgendaProfFim])

  useEffect(() => {

    if (isFirstRunAgendaProfAtu.current) {
      isFirstRunAgendaProfAtu.current = false;
      return;
    }

    const { id, idprof } = AgendaProfAtu

    //cl("clAgendaProfAtu",AgendaProfAtu);

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', id);
      bodyFormData.set('idprof', idprof);
      bodyFormData.set('user', global.gIdUser);      
      //bodyFormData.set('status', 'cancelado');
      const response = await axios({
        method: 'post',

        url: 'https://api.youevolution.com.br/AgendaProfAtu.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        //cl("result", response)
        setrepCliout({ '0': '0' });
        setAtu({ '0': '0' });
      };


      if (!ignore) {
      }
    }

    //cl('gAgendaProfAtu', global.gAgendaProfAtu)
    if (global.gAgendaProfAtu === 1) {

      fetchdata();
      global.gAgendaProfAtu = 0;

    } else { return };
    return () => { ignore = true };

  }, [AgendaProfAtu])


  useEffect(() => {

    if (isFirstRunAgendaStatus.current) {
      isFirstRunAgendaStatus.current = false;
      return;
    }

    const { id, cli, prof, status, user } = AgendaStatus

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', id);
      bodyFormData.set('idcli', cli);
      bodyFormData.set('idprof', prof);
      bodyFormData.set('status', status);
      bodyFormData.set('user', user);
      const response = await axios({
        method: 'post',

        url: 'https://api.youevolution.com.br/AgendaStatus.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        ////cl("result", response)
        setAtu({ '0': '0' });

      };


      if (!ignore) {
      }
    }
    if (global.gAgendaStatus === 1) {

      fetchdata();
      global.gAgendaStatus = 0;

    } else { return };
    return () => { ignore = true };

  }, [AgendaStatus])

  useEffect(() => {
    //cl("clrepCliout['data']",repCliout['data']);
    //se for vazio sai
    if ((!repCliout['data']) || (repCliout['data'] === null)) {
      //cl("clentrou");
      let epanelActions = document.getElementById('panelActions');
      if (epanelActions) { epanelActions.style.display = 'none' };
      let eplistagenda = document.getElementById('plistagenda');
      if (eplistagenda) { eplistagenda.innerText = '❌ Nenhum agendamento disponível' };
      return
    } else {
      let epanelActions = document.getElementById('panelActions');
      if (epanelActions) { epanelActions.style.display = '' };

    }

    let k;
    let eSelectServ = document.getElementById('SelectServ');
    if (eSelectServ) {
      removeOptions(document.getElementById('SelectServ'));
      //removeOptions(document.getElementById('SelectMedium'));
      //removeOptions(document.getElementById('SelectHorario'));
      if (global.inicial > 0) {
        //validaSelectServ();
        //validaSelectMedium();
      }
      let einputEndColeta = document.getElementById('inputEndColeta');
      let einputEndEntrega = document.getElementById('inputEndEntrega');
      einputEndColeta.value = '';
      einputEndEntrega.value = '';
      global.inicial = global.inicial + 1;
      let opt = document.createElement('option');
      opt.value = 0;
      opt.innerHTML = 'Selecione...';
      eSelectServ.appendChild(opt);
      global.Serv = repCliout['data'];
      //cl("global.Serv",global.Serv) ;
      /* var GiraFilter = global.Serv.filter(function (item, num) {
        return item.id === 4;
      });
      let startAgendam = GiraFilter[0]['start'].substr(0,11)+'08:35'+':00' */
      //cl("GiraFilter",GiraFilter) ;
      //cl("GiraFilterstart",GiraFilter[0]['start']) ;
      //cl("startAgendam",startAgendam) ;

      for (k = 0; k < repCliout['data'].length; k++) {
        let opt = document.createElement('option');
        //let dayname3 = moment(repCliout['data'][k]['start'], "YYYY-MM-DD HH:mm:ss").format('dddd').substring(0, 3);
        //let dayname = moment(repCliout['data'][k]['start'], "YYYY-MM-DD HH:mm:ss").format('dddd')
        opt.value = repCliout['data'][k]['id'];
        //opt.innerHTML = repCliout['data'][k]['start'].substr(8, 2) + '/' + repCliout['data'][k]['start'].substr(5, 2) + '/' + repCliout['data'][k]['start'].substr(0, 4) + '(' + dayname3.toUpperCase() + ')' + ' - ' + repCliout['data'][k]['titulo'];
        opt.innerHTML = repCliout['data'][k]['descricao'];
        eSelectServ.appendChild(opt);

      }
    }


  }, [repCliout])


  useEffect(() => {
    /*     if (isFirstRunDados.current) {
          isFirstRunDados.current = false;
          //cl("FirstRunDados false")
          return;
        } */

    let ignore = false;

    async function fetchdataCli() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      //bodyFormData.set('comp', 1);
      bodyFormData.set('idprof', global.gIDProf);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaAgendaProf.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //repCliout(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];

        if (redata2) {
          setrep(redata2);
        } else {
          setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);



        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
        let edivPross = document.getElementById('divPross');
        if (edivPross) { edivPross.style.display = 'none' };



      }
    }
    fetchdataCli();

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('comp', 1);
      bodyFormData.set('idcli', global.gIdCliFromUsu);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaServ.php';
      //const urlfull = 'https://api.youevolution.com.br/listaGira.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      setrepCliout(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];

        if (redata2) {
          //setrep(redata2);
        } else {
          //setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [Atu])

  useEffect(() => {
    if (isFirstRunAtuCad.current) {
      isFirstRunAtuCad.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', AtuCad.id);
      bodyFormData.set('descricao', AtuCad.descricao);
      bodyFormData.set('grupo', AtuCad.grupo);
      bodyFormData.set('valor', realParaNumber(AtuCad.valor));
      bodyFormData.set('porc', AtuCad.porc);
      bodyFormData.set('tempomedio', AtuCad.tempomedio);
      bodyFormData.set('log', JSON.stringify(global.gLog));
      bodyFormData.set('user', global.gIdUser);
      //cl("bodyFormData",bodyFormData);
      const urlfull = 'https://api.youevolution.com.br/ServAtu.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //setrepCliout(response);
      setAtu({ '0': '0' });
      if (!ignore) {
        /*         const redata = [{}];
                const redata2 = response['data'];
                if (redata2) {
                  setrep(redata2);
                } else {
                  setrep(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    if (global.gAtuCad === 1) {

      fetchdata();
      global.gAtuCad = 0;

    } else { return };
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuCad])

  function validaSelectServ(params) {
    const eNnome = document.getElementById('SelectServ')
    const nome = eNnome.value;
    //cl("validaSelectServValue",nome);
    //cl("validaSelectServIndex",eNnome.selectedIndex);
    //cl("validaSelectServ",nome);
    //global.cadnome = nome;
    if (nome > 0) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputSelectServ = true;
      //eNnome.disabled = true;
    } else {
      global.inpAddFrmC.inputSelectServ = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
      return ('pare');

    }
  }

  function validaSelectMedium(params) {
    const eNnome = document.getElementById('SelectMedium')
    const nome = eNnome.value;
    const eNnome2 = document.getElementById('SelectServ')
    //cl("validaSelectMedium",nome);
    //global.cadnome = nome;
    if (nome > 0) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputSelectMedium = true;
      // eNnome.disabled = true;
      // eNnome2.disabled = true;
    } else {
      global.inpAddFrmC.inputSelectMedium = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
      return ('pare');

    }
  }

  function validaSelectHorario(params) {
    const eNnome = document.getElementById('SelectHorario')
    if (eNnome.selectedIndex > 0) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputSelectHorario = true;
    } else {
      global.inpAddFrmC.inputSelectHorario = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
      return ('pare');

    }
  }

  function selectGiraOk(params) {
    let edivPross = document.getElementById('divPross');
    edivPross.style.display = '';

    if (validaSelectServ(1) == 'pare') {
      CloseEditReset();
      return
    };
    let ignore = false;
    let eSelectServ = document.getElementById('SelectServ');
    let idGira = eSelectServ.value;
    //cl("idGira",idGira);

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('idGira', idGira);

      //cl("bodyFormData",bodyFormData);
      const urlfull = 'https://api.youevolution.com.br/listaMedium.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //setrepCliout(response);
      //setAtu({ '0': '0' });
      if (!ignore) {
        let k;
        let eSelectMedium = document.getElementById('SelectMedium');
        if (eSelectMedium) {
          removeOptions(document.getElementById('SelectMedium'));
          let opt = document.createElement('option');
          opt.value = 0;
          opt.innerHTML = 'Selecione...';
          eSelectMedium.appendChild(opt);
          for (k = 0; k < response['data'].length; k++) {
            let opt = document.createElement('option');
            opt.value = response['data'][k]['id'];
            opt.innerHTML = response['data'][k]['nome'];
            eSelectMedium.appendChild(opt);

          }
          edivPross.style.display = 'none';
          eSelectMedium.focus();
        }


      }

    }

    fetchdata();


  }

  function selectServOk(params) {

    let edivPross = document.getElementById('divPross');
    edivPross.style.display = '';
    let eSelectServ = document.getElementById('SelectServ');
    let einputEndColeta = document.getElementById('inputEndColeta');
    let einputEndEntrega = document.getElementById('inputEndEntrega');

    //cl("selectServOk")
    //cl("a",eSelectServ.options[eSelectServ.selectedIndex].innerHTML)
    let serv = eSelectServ.options[eSelectServ.selectedIndex].innerHTML;
    if (serv === "ENTREGA") {
      einputEndColeta.value = global.gEnd + ',' + global.gNum + ' - ' + global.gCompl;
      einputEndEntrega.value = '';
      einputEndEntrega.focus();

    }
    if (serv === "RETIRADA") {
      einputEndEntrega.value = global.gEnd + ',' + global.gNum + ' - ' + global.gCompl;
      einputEndColeta.value = '';
      einputEndColeta.focus();

    }
    if (serv === "VIAGEM") {
      einputEndColeta.value = global.gEnd + ',' + global.gNum + ' - ' + global.gCompl;
      einputEndEntrega.value = '';
      einputEndEntrega.focus();

    }

    validaSelectServ();





    edivPross.style.display = 'none';

  }

  function selectMediumOk(params) {
    let edivPross = document.getElementById('divPross');
    edivPross.style.display = '';
    if (validaSelectMedium(1) == 'pare') {
      edivPross.style.display = 'none';
      CloseEditReset();
      return
    };


    let ignore = false;
    let eSelectMedium = document.getElementById('SelectMedium');
    let idProf = eSelectMedium.value;
    let eSelectServ = document.getElementById('SelectServ');
    let idGira = eSelectServ.value;
    let arrtransf = global.Serv;
    var arrFound = arrtransf.filter(function (item, num) {
      return item.id === Number(idGira);
    });
    //cl("arrFound",arrFound);

    let start = arrFound[0]['start'];
    let end = arrFound[0]['end'];
    let divAtendMin = arrFound[0]['divAtendMin'];
    //cl("idProf",idProf);
    //cl("idGira",idGira);
    //cl("start",start);
    //cl("end",end);
    //cl("divAtendMin",divAtendMin);

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('idGira', idGira);
      bodyFormData.set('idProf', idProf);
      bodyFormData.set('start', start);
      bodyFormData.set('end', end);
      bodyFormData.set('divAtendMin', divAtendMin);

      //cl("bodyFormData",bodyFormData);
      const urlfull = 'https://api.youevolution.com.br/listaHoraMedium.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //setrepCliout(response);
      //setAtu({ '0': '0' });
      if (!ignore) {
        let k;
        let eSelectHorario = document.getElementById('SelectHorario');
        if (eSelectHorario) {
          removeOptions(document.getElementById('SelectHorario'));
          let opt = document.createElement('option');
          opt.value = 0;
          opt.innerHTML = 'Selecione...';
          eSelectHorario.appendChild(opt);
          for (k = 0; k < response['data'].length; k++) {
            let opt = document.createElement('option');
            opt.value = response['data'][k]['hora'];
            opt.innerHTML = response['data'][k]['hora'];
            eSelectHorario.appendChild(opt);

          }
          edivPross.style.display = 'none';
          eSelectHorario.focus();
        }

      }

    }

    fetchdata();

  }

  const ref = null;


  const columns = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    { title: 'Id', field: 'id', hozAlign: 'center', width: 50, visible: true, responsive: 0 },
    { title: 'Data', field: 'start', width: 114, responsive: 0, formatter: formatDataHora },
    //{ title: 'Data', field: 'start' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Tipo', field: 'servicos', hozAlign: "left", width: 80, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
    { title: 'Cliente', field: 'title', hozAlign: "center", width: 90, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc", formatter: formatProf },
    { title: 'Status', field: 'status', hozAlign: "center", width: 90, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc", formatter: formatStatus },
    { title: 'Entrega', field: 'entrega', hozAlign: "left", width: 150, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
    { title: 'Coleta', field: 'coleta', hozAlign: "left", width: 150, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
    /*     { title: 'Grupo', field: 'grupo', hozAlign: "left", width: 200, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
        { title: 'Valor R$', field: 'valor', hozAlign: "right", width: 100, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
        {
          title: 'Profis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
    
            let textoAjustado = cell.getValue();
    
            return textoAjustado + " %";
          }
        },
        {
          title: 'Tempo Médio', field: 'tempomedio', hozAlign: "center", width: 130, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
    
            let textoAjustado = cell.getValue();
    
            return textoAjustado + " h";
          }
        },
     */    //{ title: 'Sindico', field: 'sindico', align: "center", width: 150, responsive: 0, headerSort: false },
    /*     {
      title: 'NF Data', field: 'nfe_data', align: 'center', width: 66, responsive: 0, formatter: "datetime", formatterParams: {
        inputFormat: "YYYY-MM-DD",
        outputFormat: "DD/MM/YY",
        invalidPlaceholder: "",
      }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
      }, */
    //{ title: 'Cidade', field: 'cidade', hozAlign: "left", width: 100, responsive: 0, },
    //{ title: 'Valor Parcelas', field: 'vlr_parc', align: "center", width: 150, responsive: 0, headerSort: false },
    //{ title: 'Prazo', field: 'qtdDias', align: "center", width: 80, responsive: 0, headerSort: false },
    //{ title: 'Editar', formatter: editIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) {cl(cell.getRow().getData().prop, cell.getRow().getData().nome, cell.getRow().getData().tipo, cell.getRow().getData().sindico, cell.getRow().getData().eleito, cell.getRow().getData().rg, cell.getRow().getData().cpf, cell.getRow().getData().profiss, cell.getRow().getData().estadocivil, cell.getRow().getData().nomet1, cell.getRow().getData().rgt1, cell.getRow().getData().nomet2, cell.getRow().getData().rgt2) } },
    //{ title: 'Editar', formatter: editIcon, width: 50, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { editCadastro(cell.getRow().getData().id) } },
    //{ title: 'Gerar', formatter: wordIcon, width: 60, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { geraWordContr(cell.getRow().getData().numcontr) } },
    /* { formatter: iconLixeira, width: 62, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().id, cell.getRow().getData().gira) } }, */
    //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { gGlobal.gContrato = cell.getRow().getData().prop; gerContratoLink(); } },

    // { title: 'Nome', field: 'nameC', width: 150, responsive: 0, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    // { title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
    // { title: 'Cidade/Estado', field: 'cidstate', align: 'center', width: 120, responsive: 0 },
    // { title: 'Fone', field: 'fone', align: 'center', width: 100, responsive: 0 },
    // { title: 'Coleta', field: 'origem', align: 'center', headerFilter: "input", width: 100, responsive: 0 },
    //{ title: 'Age', field: 'age', align: 'left', formatter: 'progress' },
    //{ title: 'Favourite Color', field: 'color' },
    //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
    //{ title: 'Rating', field: 'rating', align: 'center', formatter: 'star' },
    // { title: 'Link', field: 'passed', headerSort: false, align: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
    //{ title: 'Info', field: 'msginfo', align: "center", width: 80, headerSort: false, formatter: infoIcon, responsive: 0, cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
    //{ title: 'Q?', field: 'resp', headerSort: false, align: "center", formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
    //{ title: '@', field: 'passed', width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave) } },
    //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().name, cell.getRow().getData().fone) } },
    //{ title: 'Del', formatter: iconLixeira, width: 25, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { if (cell.getRow().getData().progress < 11){ confirmDel(cell.getRow().getData().nameC, cell.getRow().getData().chave) }} },
    // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    // { title: 'Custom', field: 'custom', align: 'center', formatter: reactFormatter(<SimpleButton />) },
    //{ title: 'Custom', field: 'custom', align: 'center' },
  ];

  /*   const columnsProp = [
      //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
      //{ title: 'Id', field: 'id', align: 'center', width: 40, visible: false, responsive: 0 },
      // {
      //  title: 'Data', field: 'createdAt', align: 'center', width: 80, responsive: 0, formatter: "datetime", formatterParams: {
      //    inputFormat: "YYYY-MM-DD",
      //   outputFormat: "DD/MM/YY",
      //   invalidPlaceholder: "(invalid date)",
      // }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
      // },
      // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
      { title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
      { title: 'Tipo', field: 'tipo', align: "center", width: 200, responsive: 0, },
      { title: 'Numero', field: 'prop', align: "center", width: 150, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Valor Total', field: 'vlr_total', align: "center", width: 150, responsive: 0, headerSort: false },
      { title: 'Parcelas', field: 'parc', align: "center", width: 80, responsive: 0, headerSort: false },
      { title: 'Valor Parcelas', field: 'vlr_parc', align: "center", width: 150, responsive: 0, headerSort: false },
      { title: 'Prazo', field: 'qtdDias', align: "center", width: 80, responsive: 0, headerSort: false },
      //{ title: 'Download', formatter: wordIcon, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { geraWordProp(cell.getRow().getData().chave, cell.getRow().getData().prop) } },
      //{ title: 'Deletar', formatter: iconLixeira, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().prop, cell.getRow().getData().tipo, cell.getRow().getData().vlr_total, cell.getRow().getData().chave) } },
      //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert('Finalizando o desenvolvimento...') } },
      //{ title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
      //{ title: 'Cidade/Estado', field: 'cidstate', align: 'center', width: 120, responsive: 0 },
      //{ title: 'Fone', field: 'fone', align: 'center', width: 130, responsive: 0 },
      //{ title: 'Info', field: 'msginfo', cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
      //{ title: 'Coleta', field: 'origem', align: 'center', headerFilter: "input", width: 100, responsive: 0 },
      //{ title: 'Age', field: 'age', align: 'left', formatter: 'progress' },
      //{ title: 'Favourite Color', field: 'color' },
      //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
      //{ title: 'Rating', field: 'rating', align: 'center', formatter: 'star' },
      // { title: 'Link', field: 'passed', headerSort: false, align: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
      //{ title: 'Q?', field: 'resp', headerSort: false, formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
      //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().nameC, cell.getRow().getData().fone) } },
      // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
      // { title: 'Custom', field: 'custom', align: 'center', formatter: reactFormatter(<SimpleButton />) },
      //{ title: 'Custom', field: 'custom', align: 'center' },
    ]; */

  const options = {
    //height: 215,
    movableRows: false,
    clipboard: true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    initialSort: [
      { column: "id", dir: "desc" }, //sort by this first
    ]
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  function Cadastro(params) {

    global.gAtuCad = 1;

    let setid = 0;
    //cl("global.gLog",global.gLog);
    //cl("global.gLog.id",global.gLog['id']);


    if (global.gLog['id'] > 0) { setid = global.gLog['id'] };




    setAtuCad(
      {
        'id': setid,
        'descricao': document.getElementById('inputNome').value,
        'grupo': document.getElementById('inputGrupo').value,
        'valor': document.getElementById('inputValor').value,
        'tempomedio': document.getElementById('inputTempoMedio').value,
        'porc': document.getElementById('inputPorc').value,
      });

    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';




  }

  function GravarAgenda(params) {
    let edivPross = document.getElementById('divPross');
    edivPross.style.display = '';

    let ignore = false;

    let eSelectServ = document.getElementById('SelectServ');
    let einputEndEntrega = document.getElementById('inputEndEntrega');
    let einputEndColeta = document.getElementById('inputEndColeta');
    //let eSelectHorario = document.getElementById('SelectHorario');
    //let horIn = eSelectHorario.children[eSelectHorario.selectedIndex].textContent
    //cl("horIns", horIn);

    //cl("eSelectServValue", eSelectServ.value);
    //cl("global.Serv", global.Serv);

    /*     let GiraFilter2 = global.Serv.filter(function (item, num) {
          return item.id === Number(eSelectServ.value);
        }); */
    let ServFilter = global.Serv.filter(function (item, num) {
      return item.id === Number(eSelectServ.value);
    });
    //cl("GiraFilter", GiraFilter2);
    //let startAgendam = GiraFilter2[0]['start'].substr(0, 11) + horIn + ':00'
    let startAgendam = moment().format('YYYY-MM-DD HH:mm:ss');
    //cl("startAgendam", startAgendam);

    let serv = ServFilter[0]['descricao'];
    let tempomedio = ServFilter[0]['tempomedio'];
    let endAgendam = moment().add(tempomedio, 'hours').format('YYYY-MM-DD HH:mm:ss');
    //cl("endAgendam", endAgendam);

    //let eSelectMedium = document.getElementById('SelectMedium');
    //let idprof = eSelectMedium.value;
    //return;

    let jservval = '[{"id":' + eSelectServ.value + ',"value":1,"label":"' + serv + ' - ' + tempomedio + 'h","porc":"0,00","valor":"0,00"}]'
    let entrega = einputEndEntrega.value;
    let coleta = einputEndColeta.value;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEssdkgfsdjkgf2323');
      bodyFormData.set('idcli', global.gIdCliFromUsu);
      bodyFormData.set('idprof', 1);
      bodyFormData.set('start', startAgendam);
      bodyFormData.set('end', endAgendam);
      bodyFormData.set('servicos', serv);
      bodyFormData.set('status', 'inicial');
      bodyFormData.set('user', global.gIdUser);
      bodyFormData.set('usercreated', global.gIdUser);
      bodyFormData.set('jservicos', jservval);
      bodyFormData.set('endEntrega', entrega);
      bodyFormData.set('endColeta', coleta);
      const response = await axios({
        method: 'post',

        url: 'https://api.youevolution.com.br/AgendaReservaTU.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response['data'] === 'existe') {
        //cl("response existe?=", response['data']);
        alert('ATENÇÃO: Não realizado, já existe 1 agendamento em seu nome para esta data, atualize a pagina e tente novamente.');

      } else if (response['data'] === 'jareservado') {
        //cl("response jareservado?=", response['data']);
        alert('ATENÇÃO: Não realizado, o horário já foi reservado, é possível que outra pessoa reservou enquanto você confirmava, selecione outro horário e tente novamente !');


      } else if (response['data'] === undefined) {
        //cl("response jareservado?=", response['data']);
        alert('ATENÇÃO: Não realizado, erro de conexão por favor tente novamente !');


      } else if (response['data']['id'] > 0) {
        //cl("response jareservado?=", response['data']);
        //cl("response ?=", response['data']);
        let msg = 'AGENDA CONFIRMADA : Protocolo n° 2023/' + response['data']['id'];
        alert(msg);
        CloseEditReset();
      } else {

        alert('ATENÇÃO: Não realizado, algo deu errado, por favor tente novamente !');

      }

      edivPross.style.display = 'none';
      setAtu({ '0': '0' });


    };

    fetchdata();

  }

  function confirmGravarAgenda(params) {
    if (!global.inpAddFrmC.inputSelectServ) {
      validaSelectServ()
      return
    }
    /*     if (!global.inpAddFrmC.inputSelectMedium) {
          validaSelectMedium()
          return
        }
        if (!global.inpAddFrmC.inputSelectHorario) {
          validaSelectHorario()
          return
        } */

    let eSelectServ = document.getElementById('SelectServ');
    let einputEndColeta = document.getElementById('inputEndColeta');
    let einputEndEntrega = document.getElementById('inputEndEntrega');


    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <p id='pmodaltualertatitulo'><b>Confirme a Solicitação ?</b></p>
            <p id='pmodaltualerta'><b>SEM VALIDADE<br></br>AMBIENTE DE TESTE</b></p>
            <p id='pmodaltu'>Serviço: <b>{eSelectServ.children[eSelectServ.selectedIndex].textContent}</b></p>
            <p id='pmodaltu'>Entrega/Destino: <b>{einputEndEntrega.value}</b></p>
            <p id='pmodaltu'>Coleta/Partida: <b>{einputEndColeta.value}h</b></p>

            <br></br>
            <button className="btn bg-gradient-info btn-sm"
              onClick={() => {

                onClose();
              }}
            >
              Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                GravarAgenda();
                onClose();
              }}
            >
              Sim, agendar agora!
            </button>
          </div>
        );
      }
    });



  }


  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }

  function novoCadastro(params) {
    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Novo Cadastro ..."

    global.gLog = [];


    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;
    elenome.focus();


    let einputValor = document.getElementById('inputValor');
    einputValor.value = '';

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = '';

    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = '';

  }


  function editCadastro(params) {

    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Editar Serviço ..."

    var arrFound = rep.filter(function (item, num) {
      return item.id === params;
    });

    global.gLog = arrFound[0];
    //cl("global.gLog", global.gLog);
    let dtfinal = '';
    /*    if(arrFound[0].dtnasc){
          
          let dt = arrFound[0].dtnasc.split("/");
          dtfinal = ''+dt[2]+'-'+dt[1]+'-'+dt[0]+'';
        } else {
          dtfinal = '';
        } */

    //cl(dtfinal);

    let elenome = document.getElementById('inputNome');
    elenome.value = arrFound[0].descricao;
    //elenome.disabled = true;

    let einputValor = document.getElementById('inputValor');
    einputValor.value = arrFound[0].valor;

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = arrFound[0].grupo;

    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = parseFloat(arrFound[0].porc.replace(',', '.'));

    let einputTempoMedio = document.getElementById('inputTempoMedio');
    einputTempoMedio.value = arrFound[0].tempomedio;

    //validaFone();
    CalcSimule();


  }




  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  function CalcSimule(params) {

    /*     var iNf_valor = document.getElementById("inputNf_valor");
        let V_Nf_valor = realParaNumber(iNf_valor.value);
        if (V_Nf_valor) { iNf_valor.value = V_Nf_valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    var iVlr_pago = document.getElementById("inputValor");
    let V_pago = realParaNumber(iVlr_pago.value);
    if (V_pago) { iVlr_pago.value = V_pago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
    /* 
        var iDif = document.getElementById("inputDif");
        let V_dif = realParaNumber(iDif.value);
        if (V_dif) { iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     var iPcc = document.getElementById("inputPcc");
        let V_Pcc = realParaNumber(iPcc.value);
        if (V_Pcc) { iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     if ((iNf_valor.disabled === false) && (iVlr_pago.disabled === false) && (iDif.disabled === false)) {
          V_dif = V_pago - V_Nf_valor;
          if (V_dif > 0) {
            iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */
    /* 
        if ((iNf_valor.disabled === false) && (iPcc.disabled === false)) {
          V_Pcc = V_Nf_valor * 0.045;
          if (V_Pcc > 0) {
            iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */




  }

  function confirmDelProp(numprop, cliente, Coleta, Entrega) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h3>Deseja Cancelar a solicitação ?</h3><br></br>
            <p id='pmodal'><b>{numprop} - {cliente}</b></p>
            <p id='pmodal'><b>Coleta: {Coleta}</b></p>
            <p id='pmodal'><b>Entrega: {Entrega}</b></p>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                global.gEventoCancelar = 1;
                setEventoCancelar(
                  {
                    'id': numprop,
                    'user': global.gIdUser
                  }

                );
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }

  function visualizarProp(numprop, cliente, Coleta, Entrega, nomeEntrega, foneEntrega, motorista, data, tipo, sstatus,visSetor, visPedido, visTempdisp, visValor) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h3>Dados da solicitação</h3><br></br>
            <p id='pmodal2'>Id :<b> {numprop} - {tipo} - {data}</b></p>
            <p id='pmodal2'>Para<b> {nomeEntrega} {foneEntrega}</b></p>
            {/* <p id='pmodal2'>Cole<b>ta: {nomeEntrega} {foneEntrega} {Coleta}</b></p> */}
            <p id='pmodal2'>End.:<b> {Entrega}</b></p>
            <p id='pmodal2'>Setor:<b> {visSetor}</b></p>
            {/* <p id='pmodal2'>Profissional:<b> {motorista}</b></p> */}
            {/* <p id='pmodal2'>Valor do serviço:<b> R${visValor}</b></p> */}
            <p id='pmodal2'>Preparo:<b> ⏱ {visTempdisp} minutos</b></p>
            <p id='pmodal2'>Pedido<b></b></p>
            <textarea className="form-control textarea" disabled id="inputPedido" defaultValue={visPedido} rows={6} cols={40} />
            <p id='pmodal2'>Status:<b> {sstatus}</b></p>
            <p id='pmodal'><b>Cliente : {cliente}</b></p>
            <p id='pmodal'><b>Coleta:  {Coleta}</b></p>

            <button className="btn bg-gradient-info btn-sm " onClick={onClose}>Ok</button>
          </div>
        );
      }
    });
  }

  function iniciarProp(numprop, cliente, Coleta, Entrega, motorista, data, tipo, sstatus) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h3>Iniciar Entrega/Viagem ?</h3><br></br>
            <p id='pmodal'><b>Id : {numprop} - {tipo}</b></p>
            <p id='pmodal'><b>Cliente : {cliente}</b></p>
            <p id='pmodal'><b>Coleta: {Coleta}</b></p>
            <p id='pmodal'><b>Entrega: {Entrega}</b></p>
            <p id='pmodal'><b>Profissional: {motorista}</b></p>
            <p id='pmodal'><b>Data: {data}</b></p>
            <p id='pmodal'><b>Status: {sstatus}</b></p>

            <button className="btn bg-gradient-info btn-sm " onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-success btn-sm float-right"
              onClick={() => {
                global.gAgendaProfAtu = 1;
                setAgendaProfAtu(
                  {
                    'id': numprop,
                    'idprof': global.gIDProf
                  }

                );
                onClose();
              }}
            >
              Sim, iniciar!
            </button>
          </div>
        );
      }
    });
  }

  function finalizarProp(numprop, cliente, Coleta, Entrega, motorista, data, tipo, sstatus) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h3>Finalizar Entrega/Viagem ?</h3><br></br>
            <p id='pmodal'><b>Id : {numprop} - {tipo}</b></p>
            <p id='pmodal'><b>Cliente : {cliente}</b></p>
            <p id='pmodal'><b>Coleta: {Coleta}</b></p>
            <p id='pmodal'><b>Entrega: {Entrega}</b></p>
            <p id='pmodal'><b>Profissional: {motorista}</b></p>
            <p id='pmodal'><b>Data: {data}</b></p>
            <p id='pmodal'><b>Status: {sstatus}</b></p>

            <button className="btn bg-gradient-info btn-sm " onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-success btn-sm float-right"
              onClick={() => {
                global.gAgendaProfFim = 1;
                setAgendaProfFim(
                  {
                    'id': numprop,
                    'idprof': global.gIDProf
                  }

                );
                onClose();
              }}
            >
              Sim, Finalizar!
            </button>
          </div>
        );
      }
    });
  }


  function incluirObs(numprop, cliente, Coleta, Entrega, nomeEntrega, foneEntrega, motorista, data, tipo, sstatus, visSetor, visPedido, visTempdisp, visValor) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h3>Incluir Obeservção</h3><br></br>
            <p id='pmodal2'>Id :<b> {numprop} - {tipo}</b></p>
            <p id='pmodal2'>Para<b> {nomeEntrega} {foneEntrega}</b></p>
            {/* <p id='pmodal2'>Cole<b>ta: {nomeEntrega} {foneEntrega} {Coleta}</b></p> */}
            <p id='pmodal2'>Profissional:<b> {motorista}</b></p>
            <p id='pmodal2'>Pedido<b></b></p>
            <textarea className="form-control textarea" disabled id="inputPedidoORG" defaultValue={visPedido} rows={6} cols={40} />
            <p id='pmodal2'>Status:<b> {sstatus}</b></p>
            <p id='pmodal2'>Nova Observação<b></b></p>
            <textarea className="form-control textarea" id="inputPedidoObs" rows={6} cols={40} />
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Cancelar</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                global.setNovaObs = 1;
               
                setNovaObs(
                  {
                    'id': numprop,
                    'nome' : abreviar(global.gNome),
                    'obs': document.getElementById('inputPedidoORG').value,
                    'novaobs': document.getElementById('inputPedidoObs').value,
                    'user' : global.gIdUser
                  }

                );
                onClose();
              }}
            >
              Enviar Observação
            </button>
          </div>
        );
      }
    });
  }

  function cancelaProf(numprop, cliente, Coleta, Entrega, nomeEntrega, foneEntrega, motorista, data, tipo, sstatus, visSetor, visPedido, visTempdisp, visValor) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h3>Não deseja mais realizar ?</h3><br></br>
            <p id='pmodal2'>Id :<b> {numprop} - {tipo}</b></p>
            <p id='pmodal2'>Para<b> {nomeEntrega} {foneEntrega}</b></p>
            {/* <p id='pmodal2'>Cole<b>ta: {nomeEntrega} {foneEntrega} {Coleta}</b></p> */}
            <p id='pmodal2'>Profissional:<b> {motorista}</b></p>
            <p id='pmodal2'>Status:<b> {sstatus}</b></p>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Cancelar</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                global.gAgendaStatus = 1;
                
                let idprofalt = global.idProf;
                  idprofalt = 1
                setAgendaStatus(
                  {
                    'id': numprop,
                    'cli': global.idCliente,
                    'prof': idprofalt,
                    'status': 'inicial',
                    'user': global.gIdUser
                  }
            
                );
                onClose();
              }}
            >
              Sim, Cancelar !
            </button>
          </div>
        );
      }
    });
  }

  function dataTable(params) {

    return (
      <>
        <div className="card card-success cardGridBgg" id="cardOcorrencias" style={{ display: 'none' }}>
          <div className="card-header ui-sortable-handle" >
            <h3 className="card-title locCont">Meus Serviços</h3>

            <div className="card-tools locCont">
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={null} style={{ margin: '-3px 20px' }}>Novo Contato</button> */}
              <Hotkeys
                keyName="shift+c"
                onKeyDown={novoCadastro}
              //onKeyUp={novoContato}
              />
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={novoCadastro} style={{ margin: '-3px 20px' }}>Agendamento On-line &#40;Shift+C&#41;</button> */}
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggI" />
              </button>

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={enviarEmail} style={{ margin: '-3px 20px' }}>Email</button> */}

            </div>
          </div>


          <div className="container-fluid" id="divfichas">

          </div>
        </div>
        <div className="card card-info cardGridBgg" id="cardOcorrencias2" style={{ display: '' }}>
          <div className="card-header ui-sortable-handle" >
            <h3 className="card-title locCont">Serviços Disponíveis</h3>

            <div className="card-tools locCont">
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={null} style={{ margin: '-3px 20px' }}>Novo Contato</button> */}

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={novoCadastro} style={{ margin: '-3px 20px' }}>Agendamento On-line &#40;Shift+C&#41;</button> */}
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggI" />
              </button>

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={enviarEmail} style={{ margin: '-3px 20px' }}>Email</button> */}

            </div>
          </div>

          <div className="card-body locCont" id="cardGridBggBody">

            <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          </div>

          <div className="container-fluid" id="divfichasdisp">

          </div>
        </div>
        <div className="card card-secondary cardGridBgg" id="cardOcorrencias3" style={{ display: '' }}>
          <div className="card-header ui-sortable-handle" >
            <h3 className="card-title locCont">Todos meus Serviços</h3>

            <div className="card-tools locCont">
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={null} style={{ margin: '-3px 20px' }}>Novo Contato</button> */}

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={novoCadastro} style={{ margin: '-3px 20px' }}>Agendamento On-line &#40;Shift+C&#41;</button> */}
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggI" />
              </button>

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={enviarEmail} style={{ margin: '-3px 20px' }}>Email</button> */}

            </div>
          </div>

          <div className="card-body locCont" id="cardGridBggBody">

            <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          </div>

          <div className="card-body locCont" id="cardGridBggBody2">
            <ReactTabulator
              // ref={(ref)}
              columns={columns}
              data={repmeus}
              id ={"gridListaServ"}
              /*               rowClick={rowClick}
                            cellClick={rowClick} */
              options={options}
              events={{}}
            //data-custom-attr="test-custom-attribute"
            //className="#example-table-theme"
            />

            <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          </div>
        </div>
      </>
    )

  }



  function page1(params) {
    return (
      <div id="panelActions" name="panelActions" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div id="divPross" className="divimgloadingProcess" style={{ display: 'none' }}>
                  <img id="imgloadingProcess" style={{ display: '' }} alt="Loading.." src={Loading2}></img>
                </div>
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Solicitar <b>**TESTE SEM VALIDADE**</b></h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewHC">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="lNome">Selecione o Serviço que deseja ?</label>
                        <select className="form-control select2 select2-hidden-accessible" id="SelectServ" onChange={() => selectServOk()} data-select2-id={1} tabIndex={-1} aria-hidden="true">
                        </select>
                      </div>
                    </div>
                    <div className="form-group ">
                      <label htmlFor="lNome">Entrega ou Destino (Detalhes, Endereço,...)</label>
                      <input type="text" className="form-control" id="inputEndEntrega" />
                    </div>
                    <div className="form-group ">
                      <label htmlFor="lNome">Coleta ou Partida (Detalhes, Endereço,...)</label>
                      <input type="text" className="form-control" id="inputEndColeta" />
                    </div>

                    <button type="button" className="btn btn-info " id="btnGravar" onClick={e => confirmGravarAgenda()}>Solicitar</button>
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={e => CloseEditReset()}>Cancelar</button>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function page0(params) {
    return (
      <div id="panelListAgenda" name="panelListAgenda" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-success cardAddBgg" >
                <div id="divPross0" className="divimgloadingProcess" style={{ display: 'none' }}>
                  <img id="imgloadingProcess" style={{ display: '' }} alt="Loading.." src={Loading2}></img>
                </div>
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Seus Agendamentos</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewHC">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <p id="plistagenda">❌ Nenhum agendamento encontrado.</p>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }


  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCidade')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      //global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("(99) 99999-9999");
    im.mask(eNnome);

  }

  function formataFone(params) {
    const nome = params.replace(/_|-/g, '');
    ////cl(nome)
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    let result = im.mask(params);
    return result;

  }


  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function CloseEditContr(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;

    let einputValor = document.getElementById('inputValor');
    einputValor.value = '';

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = '';

    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = '';

  }

  function CloseEditReset(params) {
    let edivPross = document.getElementById('divPross');
    if (edivPross) { edivPross.style.display = '' };
    let eSelectServ = document.getElementById('SelectServ');
    //let eSelectMedium = document.getElementById('SelectMedium');
    //let eSelectHorario = document.getElementById('SelectHorario');
    if (eSelectServ) { removeOptions(eSelectServ) };
    //if (eSelectMedium) { removeOptions(eSelectMedium) };
    //if (eSelectHorario) { removeOptions(eSelectHorario) };
    validaSelectServ();
    //validaSelectMedium();
    //validaSelectHorario();
    setAtu({ '0': '0' });
    eSelectServ.focus();

  }

  if (repCliout.length < 1) {
    //if (0 == 0) {
    return (
      <div className="content-wrapper" >
        <div className="container-login100-form-btn">
          <img id="imgloadingbg" width="110%" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30"></div>
      </div >
    )
  } else {
    return (
      <div className="content-wrapper">
        {/*      {page0()} */}
        {/* {page1()} */}
        {dataTable()}


      </div>
    )

  }


}

export default Gerador   