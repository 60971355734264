import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import Loading from "./assets/loading4.gif";
import Logo from "./assets/login.png";
import './caixa.css';
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import dateFormat from "dateformat";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link, animateScroll as scroll } from "react-scroll";
import Hotkeys from 'react-hot-keys';
import date from 'date-and-time';
import DualListBox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType
} from "docx";
//import * as fs from "fs";
//const fs = require('fs')

var gGlobal = require('./components/globalvar.js');

const wordIcon = function (cell, formatterParams) { //plain text value
  return "<i class='far fa-file-word'></i>";
};
const editIcon = function (cell, formatterParams) { //plain text value

  return "<i class='fas fa-edit'></i>";
};
const apagarIcon = function (cell, formatterParams) { //plain text value
  // cl("cell",cell)
  if (cell.getRow().getData().tipo == 1) {
    return "<i class='fa fa-trash-o'></i>";
  } else {
    return "-";
  }
};
const addIcon = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-plus'></i>";
};
const iconContrato = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-file-download'></i>";
};
const iconwhatsappweb = function (cell, formatterParams) { //plain text value
  return "<i class='fa fa-whatsapp'></i>";
}

const iconLixeira = function (cell, formatterParams) { //plain text value
  //if (cell.getRow().getData().progress < 11){
  return "<i class='fa fa-trash-o'></i>";
  //} else {
  //  return "-";
  //}
}

global.gLog = [];
global.gOpencaixa = 1;
global.gSubTotal = 0;
global.animatedComponents = makeAnimated();
global.gAlertaCaixa = 0;


function rowClick(e, row) {
  ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
  //cl("rowClick id: " + row.getData().id, row, e);
  ////cl(rep);

};

function Gerador() {

  const [Atu, setAtu] = useState([]);
  const [AtuSaldoAnterior, setAtuSaldoAnterior] = useState([]);
  const [rep, setrep] = useState([]);
  const [repCaixaOpen, setrepCaixaOpen] = useState([]);
  const [FinalizaPag, setFinalizaPag] = useState([]);
  const [AtuLanc, setAtuLanc] = useState([]);
  const [Lanc, setLanc] = useState([]);
  const [LancSelected, setLancSelected] = useState([]);
  const [openCaixa, setopenCaixa] = useState([]);
  const [AtuLancamentos, setAtuLancamentos] = useState('');
  const [Lancamentos, setLancamentos] = useState('');
  const [AddLanc, setAddLanc] = useState('');
  const [AddLancServ, setAddLancServ] = useState('');
  const [EditLancamentos, setEditLancamentos] = useState('');
  const [ApagarLancamentos, setApagarLancamentos] = useState('');
  const [searchProdutos, setsearchProdutos] = useState('');
  const [ProdutosOpt, setProdutosOpt] = useState([]);
  const [Produtos, setProdutos] = useState([]);
  const [ProdutosPre, setProdutosPre] = useState([]);
  const [searchServicos, setsearchServicos] = useState('');
  const [ServicosOpt, setServicosOpt] = useState([]);
  const [Servicos, setServicos] = useState([]);
  const [ServicosPre, setServicosPre] = useState([]);
  const [ReservaAgenda, setReservaAgenda] = useState([]);
  const [ProfisPre, setProfisPre] = useState([]);
  const [Profis, setProfis] = useState([]);


  const [DocNow, setDocNow] = useState([]);
  const isFirstRunAtuLanc = useRef(true);
  const isFirstRunOpenCaixa = useRef(true);
  const isFirstRunAtuLancamentos = useRef(true);
  const isFirstRunEditLancamentos = useRef(true);
  const isFirstRunApagarLancamentos = useRef(true);
  const isFirstRunFinalizaPag = useRef(true);
  const isFirstRunAddLanc = useRef(true);
  const isFirstRunAddLancServ = useRef(true);
  const isFirstRunSaldoAnterior = useRef(true);
  const isFirstRunReservaAgenda = useRef(true);


  moment.updateLocale('pt-br', {
    week: {
      dow: 1,
      //doy: 1,
    },
  });

  useEffect(() => {

    let ignoreProf = false;
    async function fetchprofis() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('vend', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        //url: global.gLinkBase + '/appyou/rest.php?apikey=79873WR9N8W4ABNAVNUQ4ÁW69376',
        url: 'https://api.youevolution.com.br/listaProfisSel.php',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })


      if (!ignoreProf) {
        const redata = response['data'];
        //cl('redata', redata);

        setProfis(redata);
        //setLocalizer(ll);
        ignoreProf = true;

      }
    }
    fetchprofis();

  }, [ProfisPre])

  useEffect(() => {


    //cl("Profis",Profis)


  }, [Profis])

  useEffect(() => {

    if (isFirstRunReservaAgenda.current) {
      isFirstRunReservaAgenda.current = false;
      return;
    }
  //cl("ReservaAgenda", ReservaAgenda)
    const { criar } = ReservaAgenda

    if (criar !== 1) {
      return
    }

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('idcli', 422);
      bodyFormData.set('idprof', 20);
      bodyFormData.set('start', moment().format('YYYY-MM-DD 00:00:00'));
      bodyFormData.set('end', moment().add(5, 'minutes').format('YYYY-MM-DD 00:00:00'));
      bodyFormData.set('servicos', 'VENDA DIRETA');
      bodyFormData.set('status', 'finalizado');
      bodyFormData.set('obs', 'VENDA AVULSA GERADA NO CAIXA');
      bodyFormData.set('user', global.gIdUser);
      bodyFormData.set('usercreated', global.gIdUser);
      //bodyFormData.set('jservicos', JSON.stringify(''));
      const response = await axios({
        method: 'post',

        url: 'https://api.youevolution.com.br/AgendaReserva.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        //cl("result",response)
        //setAtu({ '0': '0' });

        setAtuLanc({ '0': '0' });
        setReservaAgenda({ 'criar': 0 })


      };


      if (!ignore) {
      }
    }

    fetchdata();

    return () => { ignore = true };

  }, [ReservaAgenda])

  useEffect(() => {


  }, [rep])

  useEffect(() => {


  }, [Lanc])




  useEffect(() => {


    //cl("LancSelected", LancSelected)
    if (LancSelected) {

      global.gLancSelected = LancSelected.toString();
    } else {

      global.gLancSelected = 'nenhum';
    }


  }, [LancSelected])

  useEffect(() => {
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('comp', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaProd.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      global.gProdutosSel = response['data'];

      // cl("global.gProdutosSel", global.gProdutosSel);

      setProdutos(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          //setrep(redata2);
        } else {
          //setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl("sev",redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
      // cl("global.gProdutosSel", global.gProdutosSel);
      var i;
      const result = [];
      if (global.gProdutosSel.length) {
        for (i = 0; i < global.gProdutosSel.length; i++) {
          let valor = [{
            id: global.gProdutosSel[i].id,
            value: global.gProdutosSel[i].id, //necessario para dualist
            label: global.gProdutosSel[i].ean + ' - ' + global.gProdutosSel[i].descricao,
            ean: global.gProdutosSel[i].ean,
            valor: global.gProdutosSel[i].valor
          }];
          result.push(valor[0]);
        };
      }
      //cl('result', result);
      setProdutosOpt(result);
    }
    fetchdata();



    return () => { ignore = true };



  }, [ProdutosPre])

  useEffect(() => {
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('comp', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaServ.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      global.gServicosSel = response['data'];

      //cl("global.gServicosSel", global.gServicosSel);

      setServicos(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          //setrep(redata2);
        } else {
          //setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl("sev",redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
      // cl("global.gServicosSel", global.gServicosSel);
      var i;
      const result = [];
      if (global.gServicosSel.length) {
        for (i = 0; i < global.gServicosSel.length; i++) {
          let valor = [{
            id: global.gServicosSel[i].id,
            value: global.gServicosSel[i].id, //necessario para dualist
            label: global.gServicosSel[i].descricao,
            valor: global.gServicosSel[i].valor,
            porc: global.gServicosSel[i].porc
          }];
          result.push(valor[0]);
        };
      }
      //cl('result', result);
      setServicosOpt(result);
    }
    fetchdata();



    return () => { ignore = true };



  }, [ServicosPre])


  useEffect(() => {



  }, [Produtos])




  useEffect(() => {

    //cl("searchProdutos mudou", searchProdutos)

  }, [searchProdutos])

  useEffect(() => {



  }, [Servicos])




  useEffect(() => {

    //cl("searchProdutos mudou", searchProdutos)

  }, [searchServicos])

  useEffect(() => {





  }, [ProdutosOpt])
  useEffect(() => {





  }, [ServicosOpt])

  useEffect(() => {



  }, [DocNow])

  useEffect(() => {
    if (isFirstRunAtuLancamentos.current) {
      isFirstRunAtuLancamentos.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { idagenda } = AtuLancamentos
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('idagenda', idagenda);
      const urlfull = 'https://api.youevolution.com.br/listaLanc.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
    //cl("response_data", response['data'])
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setLancamentos(redata2);
        } else {
          setLancamentos(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);

        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    if (idagenda) {
      if (idagenda.length > 0) {
        fetchdata()
      } else {
        setLancamentos()
      };
    }
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuLancamentos])

  useEffect(() => {
    if (isFirstRunFinalizaPag.current) {
      isFirstRunFinalizaPag.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { cliente, caixa, idagenda, tipo, desc, valor, cash, troco, debito, creditoavista, creditoparc, pix, cc } = FinalizaPag
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('cliente', cliente);
      bodyFormData.set('caixa', caixa);
      bodyFormData.set('idagenda', idagenda);
      bodyFormData.set('tipo', tipo);
      bodyFormData.set('desc', desc);
      bodyFormData.set('valor', valor);
      bodyFormData.set('cash', cash);
      bodyFormData.set('troco', troco);
      bodyFormData.set('debito', debito);
      bodyFormData.set('creditoavista', creditoavista);
      bodyFormData.set('creditoparc', creditoparc);
      bodyFormData.set('pix', pix);
      bodyFormData.set('cc', cc);
      bodyFormData.set('user', global.gIdUser);
      const urlfull = 'https://api.youevolution.com.br/FinalizaPg.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          //setLancamentos(redata2);
          CloseEditContr();
        } else {
          //setLancamentos(redata);
        }

        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [FinalizaPag])

  useEffect(() => {
    if (isFirstRunAddLanc.current) {
      isFirstRunAddLanc.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;
    // cl("AddLan", AddLanc)
    const { idagenda, idproduto,idprof ,idservico, cliente, valor, qtd } = AddLanc
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('idagenda', idagenda);
      bodyFormData.set('idservico', idservico);
      bodyFormData.set('idprof', idprof);
      bodyFormData.set('idproduto', idproduto);
      bodyFormData.set('cliente', cliente);
      bodyFormData.set('valor', valor);
      bodyFormData.set('qtd', qtd);
      bodyFormData.set('user', global.gIdUser);
      const urlfull = 'https://api.youevolution.com.br/AddLancProd.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      setAtuLancamentos({ idagenda: global.gParams })
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          //setLancamentos(redata2);
          //CloseEditContr();
        } else {
          //setLancamentos(redata);
        }


      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [AddLanc])

  useEffect(() => {
    if (isFirstRunAddLancServ.current) {
      isFirstRunAddLancServ.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;
  //cl("AddLan", AddLancServ)
    const { idagenda, idservico, valor, porc, qtd } = AddLancServ
    //cl("idagenda", idagenda)
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('idagenda', idagenda);
      bodyFormData.set('idservico', idservico);
      //bodyFormData.set('idproduto', idproduto);
      //bodyFormData.set('cliente', cliente);
      //bodyFormData.set('prof', idprof);
      bodyFormData.set('valor', valor);
      bodyFormData.set('porc', porc);
      bodyFormData.set('qtd', qtd);
      bodyFormData.set('user', global.gIdUser);
      const urlfull = 'https://api.youevolution.com.br/AddLancServ.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response_data", response['data'])
      setAtuLancamentos({ idagenda: global.gParams })
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          //setLancamentos(redata2);
          //CloseEditContr();
        } else {
          //setLancamentos(redata);
        }


      }
    }
    fetchdata();
    return () => { ignore = true };
  }, [AddLancServ])


  useEffect(() => {
    if (isFirstRunEditLancamentos.current) {
      isFirstRunEditLancamentos.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { id, valor, qtd, idagenda } = EditLancamentos

    // cl("qtd",qtd)

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', id);
      bodyFormData.set('valor', valor);
      bodyFormData.set('qtd', qtd);
      bodyFormData.set('idagenda', idagenda);
      bodyFormData.set('user', global.gIdUser);
      const urlfull = 'https://api.youevolution.com.br/listaLancAlt.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (!ignore) {
        //const redata = [{}];
        //const redata2 = response['data'];
        /*         if (redata2) {
                  setLancamentos(redata2);
                } else {
                  setLancamentos(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };

        //setLancamentos({0:0});
        //setAtuLancamentos({ idagenda: global.gidAgenda })
        //cl('gcontrato');
      }
      setAtuLancamentos({ idagenda: global.gParams })
    }
    fetchdata();

    // cl("Edit Lancamentos mudou", Lancamentos)
    return () => { ignore = true };
  }, [EditLancamentos])

  useEffect(() => {
    if (isFirstRunApagarLancamentos.current) {
      isFirstRunApagarLancamentos.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { id, valor, qtd, idagenda } = ApagarLancamentos

    // cl("qtd",qtd)

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', id);
      bodyFormData.set('valor', valor);
      bodyFormData.set('qtd', qtd);
      bodyFormData.set('idagenda', idagenda);
      bodyFormData.set('user', global.gIdUser);
      const urlfull = 'https://api.youevolution.com.br/ApagarLanc.php';
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      if (!ignore) {
        //const redata = [{}];
        //const redata2 = response['data'];
        /*         if (redata2) {
                  setLancamentos(redata2);
                } else {
                  setLancamentos(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };

        //setLancamentos({0:0});
        //setAtuLancamentos({ idagenda: global.gidAgenda })
        //cl('gcontrato');
      }
      setAtuLancamentos({ idagenda: global.gParams })
    }
    fetchdata();

    // cl("Edit Lancamentos mudou", Lancamentos)
    return () => { ignore = true };
  }, [ApagarLancamentos])

  useEffect(() => {

    let eimgloadingReactTabulator = document.getElementById('imgloadingReactTabulator');
    if (eimgloadingReactTabulator) {
      eimgloadingReactTabulator.style.display = 'none';
    }

    let edivReactTabulator = document.getElementById('divReactTabulator');
    if (edivReactTabulator) {
      edivReactTabulator.style.display = '';
    }

    btnFPGresetAll();
    global.gSubTotal = 0;
    var i
    if (Lancamentos) {

      for (i = 0; i < Lancamentos.length; i++) {

        global.gSubTotal = global.gSubTotal + (realParaNumber(Lancamentos[i].valor) * (realParaNumber(Lancamentos[i].qtd)));

      }
      let edivFormadePG = document.getElementById('divFormadePG');
      if (edivFormadePG) { edivFormadePG.style.display = ''; }

      let einputVConta = document.getElementById('inputVConta');
      let eibtnPgConta = document.getElementById('btnPgConta');
      if (Lancamentos.length > 1) {
        //einputVConta.disabled = true;
        //eibtnPgConta.disabled = true;
      } else {
        //einputVConta.disabled = false;
        //eibtnPgConta.disabled = false;

      }



    } else {
      let edivFormadePG = document.getElementById('divFormadePG');
      if (edivFormadePG) { edivFormadePG.style.display = 'none'; }

    }
    let einputTotalCx = document.getElementById('inputTotalCx');
    if (einputTotalCx) { einputTotalCx.value = global.gSubTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
    //cl("global.gSubTotal", global.gSubTotal)
  //cl("Lancamentos mudou", Lancamentos)

  }, [Lancamentos])

  useEffect(() => {

    if (isFirstRunOpenCaixa.current) {
      isFirstRunOpenCaixa.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    if (global.gOpencaixa === 0) {

      //cl("openCaixa", openCaixa);
      //let iopen = dateFormat(Date.parse(openCaixa.open), "yyyy-mm-dd HH:MM:ss");
      let iopen = moment(openCaixa.open).format("YYYY-MM-DD");
      let itroco = realParaNumber(openCaixa.troco);
      //cl("iopen", iopen);
      //cl('itroco', itroco);

      async function fetchdata() {
        var bodyFormData = new FormData();
        bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
        bodyFormData.set('open', iopen);
        bodyFormData.set('troco', itroco);
        bodyFormData.set('user', global.gIdUser);

        const urlfull = 'https://api.youevolution.com.br/OpenCaixa.php';
        //cl("urlfull",urlfull);
        const response = await axios({
          method: 'post',
          url: urlfull,
          data: bodyFormData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
        //cl("response",response);
        if (response['data'] === 'caixa aberto ou já existe') {
          modalBase("Atenção", "O Caixa ainda está aberto, ou Já existe caixa fechado para esta data ou a data escolhida é anterior ao caixa já utilizado  !");
          //window.alert('O Caixa esta aberto ou Já existe caixa fechado para esta data !')
          return
        }
        //setrepCaixaOpen(response);

        setAtu({ '0': '0' });
        global.gOpencaixa = 1;
        if (!ignore) {

          let eleLoading = document.getElementById('divLoad');
          if (eleLoading) { eleLoading.style.display = 'none'; };
        }
      }


      fetchdata();
    }
    return () => { ignore = true };

  }, [openCaixa])


  useEffect(() => {

    if (repCaixaOpen['data']) {
      let epanelCxOpen = document.getElementById('panelCxOpen');
      if (epanelCxOpen) { epanelCxOpen.style.display = ''; }
      let ebtnGravarPG = document.getElementById('btnGravarPG');
      if (ebtnGravarPG) { ebtnGravarPG.style.display = ''; }
      let epanelCxClose = document.getElementById('panelCxClose');
      if (epanelCxClose) { epanelCxClose.style.display = 'none'; }
      let resp = repCaixaOpen['data'][0];
      let etituloCxOpen = document.getElementById('tituloCxOpen');
      if (etituloCxOpen) { etituloCxOpen.innerHTML = '>>> Caixa Aberto - ' + moment(resp.open).format("DD/MM/YYYY") + ' - ' + moment(resp.open).format("dddd, DD [de] MMMM YYYY") }
      //setAtuLanc({ '0': '0' });
      global.gCaixaId = resp['id'];
      if (global.gAlertaCaixa === 0) {


        let current = moment().startOf('day');
        //cl("dif",moment.duration(moment(resp.open).diff(current)).asDays())
        if ((moment.duration(moment(resp.open).diff(current)).asDays()) < 0) {
          let f1 = "O Caixa Aberto (" + moment(resp.open).format("DD/MM/YYYY") + ") não esta na data de hoje ! (" + moment(current).format("DD/MM/YYYY") + ")";
          modalBase("Atenção", f1, "Revise e feche o caixa logo!")
          global.gAlertaCaixa = 1;
        }
      }

      //global.gOpencaixa = 1;

      //cl("ok repCaixaOpen data", repCaixaOpen);
    } else {
      ////cl("no repCaixaOpen data");
      let epanelCxClose = document.getElementById('panelCxClose');
      if (epanelCxClose) { epanelCxClose.style.display = ''; }
      let epanelCxOpen = document.getElementById('panelCxOpen');
      if (epanelCxOpen) { epanelCxOpen.style.display = 'none'; }
      global.gOpencaixa = 0;
      setAtuSaldoAnterior([{ '0': '0' }]);

    }


  }, [repCaixaOpen])


  useEffect(() => {
    if (isFirstRunSaldoAnterior.current) {
      isFirstRunSaldoAnterior.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      //bodyFormData.set('comp', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaCaixaAnt.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //setrepCaixaOpen(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          if (redata2[0].vfinal) {
            global.gSaldoAnterior = parseInt(redata2[0].vfinal);
            let einputValorAnterior = document.getElementById('inputValorAnterior');
            einputValorAnterior.value = (global.gSaldoAnterior.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }));

          } else {
            global.gSaldoAnterior = 0;
          }

          //setrep(redata2);
          //cl("redata2",redata2);
        } else {
          //setrep(redata);
          //cl("redata",redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];



        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuSaldoAnterior])

  useEffect(() => {
    /*     if (isFirstRunDados.current) {
          isFirstRunDados.current = false;
          //cl("FirstRunDados false")
          return;
        } */
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      //bodyFormData.set('comp', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaCaixa.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      setrepCaixaOpen(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setrep(redata2);
          //cl("redata2",redata2);
        } else {
          setrep(redata);
          //cl("redata",redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];



        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [Atu])

  useEffect(() => {
    /*     if (isFirstRunAtuLanc.current) {
          isFirstRunAtuLanc.current = false;
          //cl("FirstRunDados false")
          return;
        } */
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');

      const urlfull = 'https://api.youevolution.com.br/listaFecha.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response['data']);
      //setrepCaixaOpen(response);
      if (response['data']) {
        setLanc(response['data']);

        setAtu({ '0': '0' });

      } else {
        setLanc([]);
        setAtu({ '0': '0' });

      }
      if (!ignore) {
        /*         const redata = [{}];
                const redata2 = response['data'];
                if (redata2) {
                  setrep(redata2);
                } else {
                  setrep(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
        let edualagenda = document.getElementById('dualagenda');
        if (edualagenda) { edualagenda.style.display = ''; }
        let eimgloadingDualAgenda = document.getElementById('imgloadingDualAgenda');
        if (eimgloadingDualAgenda) { eimgloadingDualAgenda.style.display = 'none'; }
      }
    }


    fetchdata();
    //cl('global.idCaixaMov',global.idCaixaMov);

    return () => { ignore = true };
  }, [AtuLanc])

  const ref = null;
  function paramLookup() {
    //do some processing and return the param object
    return { param1: "green" };
  }

  const columns = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Qtd.', field: 'qtd', hozAlign: 'center', visible: true, responsive: 0, headerSort: false },
    { title: 'Descrição', field: 'descricao', hozAlign: "left", width: 200, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
    { title: 'Valor R$', field: 'valor', hozAlign: "center", width: 70, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Total R$', field: 'total', hozAlign: "center", width: 70, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
    /*       {
      title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
        
        let textoAjustado = cell.getValue();
        
        return textoAjustado + " %";
      }
    }, */
    { title: 'Cod', field: 'idagenda', hozAlign: 'center', width: 45, visible: true, headerSort: false, responsive: 0 },
    { title: 'Atend.', field: 'prof', hozAlign: 'left', width: 80, visible: true, headerSort: false, responsive: 0 },
    { title: 'Editar', formatter: editIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'finalizado') { editLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } },
    { title: 'Apagar', formatter: apagarIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().tipo == 1) { apagaLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } }
    /* { title: 'Add', formatter: addIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { if (cell.getRow().getData().status == 'finalizado') { addLanc((cell.getRow().getData().id), (cell.getRow().getData().valor), (cell.getRow().getData().qtd), 'Adicionar : ' + (cell.getRow().getData().idagenda) + ' - ' + (cell.getRow().getData().nome) + ' / ' + (cell.getRow().getData().descricao), (cell.getRow().getData().idagenda)) } } } */
  ];



  const options = {
    //autoResize:false,
    //height: 125,
    // movableRows: true,
    clipboard: true,
    //resizableRows:true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    initialSort: [
      { column: "descricao", dir: "asc" }, //sort by this first
    ]
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };


  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }

  function novoCadastro(params) {


    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Novo Cadastro ..."

    global.gLog = [];


    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let eQtdInicial = document.getElementById('QtdInicial');
    eQtdInicial.style.display = '';

    let einputCodBarras = document.getElementById('inputCodBarras');
    einputCodBarras.value = '';
    einputCodBarras.focus();

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = '';


    let einputValorCusto = document.getElementById('inputValorCusto');
    einputValorCusto.value = '';

    let einputValor = document.getElementById('inputValor');
    einputValor.value = '';

    let einputUnidade = document.getElementById('inputUnidade');
    einputUnidade.value = '';

    let einputQtdMin = document.getElementById('inputQtdMin');
    einputQtdMin.value = '';


    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = '';

  }


  function editCadastro(params) {

    let eQtdInicial = document.getElementById('QtdInicial');
    eQtdInicial.style.display = 'none';

    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Editar Produto ..."

    var arrFound = rep.filter(function (item, num) {
      return item.id === params;
    });

    global.gLog = arrFound[0];
    //cl("global.gLog", global.gLog);
    let dtfinal = '';
    /*    if(arrFound[0].dtnasc){
          
          let dt = arrFound[0].dtnasc.split("/");
          dtfinal = ''+dt[2]+'-'+dt[1]+'-'+dt[0]+'';
        } else {
          dtfinal = '';
        } */

    //cl(dtfinal);

    let eleCodBarras = document.getElementById('inputCodBarras');
    eleCodBarras.value = arrFound[0].ean;

    let elenome = document.getElementById('inputNome');
    elenome.value = arrFound[0].descricao;
    //elenome.disabled = true;

    let einputValor = document.getElementById('inputValor');
    einputValor.value = arrFound[0].valor;

    let einputUnidade = document.getElementById('inputUnidade');
    einputUnidade.value = arrFound[0].unidade;

    let einputValorCusto = document.getElementById('inputValorCusto');
    einputValorCusto.value = arrFound[0].valor_custo;

    let einputQtdMin = document.getElementById('inputQtdMin');
    einputQtdMin.value = arrFound[0].estoque_min;

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = arrFound[0].grupo;

    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = parseFloat(arrFound[0].porc.replace(',', '.'));


    //validaFone();
    CalcSimule();


  }

  function editLanc(id, valor, qtd, descricao, idagenda) {


    // cl(id, valor, qtd);


    confirmAltLanc(id, valor, qtd, descricao, idagenda);

    let einputMQtd = document.getElementById('inputMQtd');
    einputMQtd.value = qtd;
    let einputMValor = document.getElementById('inputMValor');
    einputMValor.value = valor;
    /*       setEditLancamentos({
            id: 126,
            valor: "400,00",
            qtd: 1
          })
    
          setAtuLancamentos({ idagenda: global.gidAgenda }) */

  }

  function apagaLanc(id, valor, qtd, descricao, idagenda) {


    // cl(id, valor, qtd);


    confirmApagaLanc(id, valor, qtd, descricao, idagenda);



  }

  function addLanc(id, valor, qtd, descricao, idagenda) {


    // cl(id, valor, qtd);


    confirmAddLanc(id, valor, qtd, descricao, idagenda);

    /*     let einputMQtd = document.getElementById('inputMQtd');
        einputMQtd.value = qtd;
        let einputMValor = document.getElementById('inputMValor');
        einputMValor.value = valor;
     */    /*       setEditLancamentos({
        id: 126,
        valor: "400,00",
        qtd: 1
      })
 
      setAtuLancamentos({ idagenda: global.gidAgenda }) */

  }

  function btnFinalizarPG(params) {
    let ebtnGravarPG = document.getElementById('btnGravarPG');
    if (ebtnGravarPG) { ebtnGravarPG.style.display = 'none'; }

    let einputVCash = document.getElementById('inputVCash');
    let rVCash = realParaNumber(einputVCash.value);
    let einputVTroco = document.getElementById('inputVTroco');
    let rVTroco = realParaNumber(einputVTroco.value);
    let einputVCD = document.getElementById('inputVCD');
    let rVCD = realParaNumber(einputVCD.value);
    let einputVCC = document.getElementById('inputVCC');
    let rVCC = realParaNumber(einputVCC.value);
    let einputVCCx = document.getElementById('inputVCCx');
    let rVCCx = realParaNumber(einputVCCx.value);
    let einputVPix = document.getElementById('inputVPix');
    let rVPIX = realParaNumber(einputVPix.value);
    let einputVConta = document.getElementById('inputVConta');
    let rConta = realParaNumber(einputVConta.value);


    let somaFPG = rConta;
    somaFPG = somaFPG + rVCash;
    somaFPG = somaFPG + rVCD;
    somaFPG = somaFPG + rVCC;
    somaFPG = somaFPG + rVCCx;
    somaFPG = somaFPG + rVPIX;

    //cl("somaFPG",somaFPG);
    //cl("global.gSubTotal",somaFPG);
    var j;
    var i;
    var desc_compl = '';
    let array_explod = [];
    //cl("Lanc",Lanc);
    for (i = 0; i < LancSelected.length; i++) {

      for (j = 0; j < Lanc.length; j++) {
        if (Lanc[j]['value'] === LancSelected[i]) {
          // cl("LancJ",Lanc[j])
          array_explod = Lanc[j]['label'].split(' ')
          //cl("array_explod",array_explod)

          if (desc_compl.length <= 1) {
            desc_compl = array_explod[2] + array_explod[3];
            //cl("desc_compl",desc_compl)

          } else {
            desc_compl = desc_compl + ',' + array_explod[2] + array_explod[3];
            //cl("desc_compl",desc_compl)

          }

        }
      }
    }
    let descricao = '';
    if (global.VendaDireta) {
      descricao = 'Venda ' + desc_compl;
    } else {
      descricao = 'Agenda ' + desc_compl;
    }
    if ((global.gSubTotal === somaFPG) || (global.gSubTotal < somaFPG)) {



      confirmFinalizarPG(descricao, global.gSubTotal, rVCash, rVTroco, rVCD, rVCC, rVCCx, rVPIX, rConta);
    } else {
      //window.alert("A Soma das formas de Pagamento não confere, revise ou preencha!")
      modalBase("Atenção", "A Soma das formas de Pagamento não confere, revise ou preencha!");
    }





  }


  function confirmFinalizarPG(desc, rVTotal, rVCash, rVTroco, rVCD, rVCC, rVCCx, rVPIX, rConta) {
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Confirmação para Finalizar Pagamento</h4>
            </div>
            <div className="modal-body">
              <p>Após finalizar o Pagamento não será mais possível alterar!</p>
            </div>
            <div className="modal-body">
              <p>Tem Certeza ?</p>
            </div>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-success btn-sm float-right"
              onClick={() => {

                setFinalizaPag({
                  cliente: '',
                  caixa: global.gCaixaId,
                  idagenda: LancSelected.toString(),
                  tipo: 'E',
                  desc: desc,
                  valor: rVTotal,
                  cash: rVCash,
                  troco: rVTroco,
                  debito: rVCD,
                  creditoavista: rVCC,
                  creditoparc: rVCCx,
                  pix: rVPIX,
                  cc: rConta
                })

                onClose();
              }}
            >
              Sim, finalizar!
            </button>
          </div>
        );
      }
    });
  }

  function confirmVendaAvulsa(desc, rVTotal, rVCash, rVTroco, rVCD, rVCC, rVCCx, rVPIX, rConta) {
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Confirmação para Gerar Venda Avulsa</h4>
            </div>
            <div className="modal-body">
              <p>Gerar nova venda Avulsa direta no balcão.</p>
            </div>
            <div className="modal-body">
              <p>Tem Certeza ?</p>
            </div>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-success btn-sm float-right"
              onClick={() => {

                setReservaAgenda({ 'criar': 1 })

                onClose();

              }}
            >
              Sim, gerar agora!
            </button>
          </div>
        );
      }
    });
  }
  function confirmAltLanc(id, valor, qtd, descricao, idagenda) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{descricao}</h4>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Qtd.</label>
                <div className="input-group-prepend">
                  <input type="number" className="form-control" autocomplete="off" id="inputMQtd" />
                </div>
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="lEmail3">Valor R$</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autocomplete="off" id="inputMValor" />
                </div>
              </div>
            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let einputMValor = document.getElementById('inputMValor');
                let einputMQtd = document.getElementById('inputMQtd');


                setEditLancamentos({
                  id: id,
                  valor: realParaNumber(einputMValor.value),
                  qtd: einputMQtd.value,
                  idagenda: idagenda
                })

                onClose();
              }}
            >
              Sim
            </button>
          </div>
        );
      }
    });
  }

  function confirmApagaLanc(id, valor, qtd, descricao, idagenda) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Excluirr ?</h4>
            </div>
            <div className="form-row">
              <p>{descricao}</p>
            </div>

            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                let einputMValor = document.getElementById('inputMValor');
                let einputMQtd = document.getElementById('inputMQtd');


                setApagarLancamentos({
                  id: id,
                  idagenda: idagenda
                })

                onClose();
              }}
            >
              Sim, apague!
            </button>
          </div>
        );
      }
    });
  }

  function confirmAddLanc(id, valor, qtd, descricao, idagenda) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    let lqtd = qtd;

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{descricao}</h4>
            </div>
            <div className="form-row">
              <div className="form-group col-md-2">
                <label htmlFor="lEmail3">Qtd.</label>
                <div className="input-group-prepend">
                  <input type="number" className="form-control" autocomplete="off" id="inputMQtdAdd" />
                </div>
              </div>
              <div className="form-group col-md-7">
                <label htmlFor="lEmail3">Descrição</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autocomplete="off" id="inputMDescAdd" />
                </div>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="lEmail3">Valor R$</label>
                <div className="input-group-prepend">
                  <input type="text" className="form-control" autocomplete="off" id="inputMValorAdd" />
                </div>
              </div>
            </div>

            <button className="btn bg-gradient-danger btn-sm" onClick={onClose}>Cancelar</button>
            <button
              className="btn bg-gradient-success btn-sm float-right"
              onClick={() => {
                let einputMValorAdd = document.getElementById('inputMValorAdd');
                let einputMDescAdd = document.getElementById('inputMDescAdd');
                let einputMQtdAdd = document.getElementById('inputMQtdAdd');


                /*                 setEditLancamentos({
                                  id: id,
                                  valor: realParaNumber(einputMValor.value),
                                  qtd: einputMQtd.value,
                                  idagenda: idagenda
                                }) */

                onClose();
              }}
            >
              Incluir
            </button>
          </div>
        );
      }
    });
  }


  function realParaNumber(texto) {
    if ((texto === '') || (texto === undefined)) {
      return 0
    }
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  function ajustaInputMoney(params) {

    let eleVUnit = document.getElementById('inputValorTroco');
    let Vunit = realParaNumber(eleVUnit.value);
    eleVUnit.value = Vunit.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

  }

  function CalcSimule(params) {

    /*     var iNf_valor = document.getElementById("inputNf_valor");
        let V_Nf_valor = realParaNumber(iNf_valor.value);
        if (V_Nf_valor) { iNf_valor.value = V_Nf_valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    var iVlr_pago = document.getElementById("inputValor");
    let V_pago = realParaNumber(iVlr_pago.value);
    if (V_pago) { iVlr_pago.value = V_pago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
    /* 
        var iDif = document.getElementById("inputDif");
        let V_dif = realParaNumber(iDif.value);
        if (V_dif) { iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     var iPcc = document.getElementById("inputPcc");
        let V_Pcc = realParaNumber(iPcc.value);
        if (V_Pcc) { iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     if ((iNf_valor.disabled === false) && (iVlr_pago.disabled === false) && (iDif.disabled === false)) {
          V_dif = V_pago - V_Nf_valor;
          if (V_dif > 0) {
            iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */
    /* 
        if ((iNf_valor.disabled === false) && (iPcc.disabled === false)) {
          V_Pcc = V_Nf_valor * 0.045;
          if (V_Pcc > 0) {
            iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */




  }

  function validaOpenCaixa(params) {

    let einputDtCx = document.getElementById('inputDtCx');
    let einputValorTroco = document.getElementById('inputValorTroco');
    let einputValorAnterior = document.getElementById('inputValorAnterior');
    if (!einputDtCx.value) {
      window.alert('Selecione a Data de Abertura !!!')
      einputDtCx.focus();
      return
    };
    let totalopen = 0;
    totalopen = realParaNumber(einputValorAnterior.value) + realParaNumber(einputValorTroco.value)

    modalCaixaOpen(document.getElementById('inputDtCx').value, totalopen.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))

  }

  function modalBase(h1, p, p2, focus, inputquery) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">{h1}</h4>
            </div>
            <div className="modal-body">
              <p>{p}</p>
            </div>
            <div className="modal-body">
              <p>{p2}</p>
            </div>
            {/* <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button> */}
            <button id="btnModal"
              className="btn bg-gradient-info float-right"
              onClick={() => {
                if (focus) {
                  document.getElementById(focus).focus();
                  // cl("focus")
                }
                if (inputquery) {
                  let inputM = document.querySelectorAll('input');
                  //cl("inputmodal",inputM)
                  inputM[inputquery].focus();
                }

                //alert('Em desenvolvimento no backend/servidor');
                onClose();
              }}
            >
              Ok
            </button>
          </div >
        );
      }
    });
  }

  function modalCaixaOpen(data, troco) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <div className="modal-header">
              <h4 className="modal-title">Abertura de Caixa</h4>
            </div>
            <div className="modal-body">
              <p>Tem certeza que deseja realizar a Abertura para o</p>
              <p>Dia {moment(data).format("DD/MM/YYYY")} com Troco de {troco} ?</p>
            </div>
            <button className="btn bg-gradient-danger btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-success btn-sm float-right"
              onClick={() => {

                setopenCaixa({
                  open: data,
                  troco: troco,
                  user: global.gIdUser
                })
                onClose();

              }}
            >
              Sim, abrir agora!
            </button>
          </div>
        );
      }
    });
  }

  function confirmDelProp(numprop, cliente) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar este Contrato da proposta ?</h1><br></br><br></br>
            <p id='pmodal'><b>{numprop} - {cliente}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                /*                 setDelContr(
                                  {
                                    'numprop': numprop
                                  }); */
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }


  function CaixaFechado(params) {
    return (
      <div id="panelCxClose" name="panelCxClose" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-danger cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Caixa Fechado</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-2">
                        <label htmlFor="lNome">Data de Abertura</label>
                        <input type="date" className="form-control" id="inputDtCx" />
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="lEmail3">Adicionar Troco</label>
                        <div className="input-group-prepend">
                          <input type="text" onBlur={e => ajustaInputMoney()} defaultValue="R$ 0,00" className="form-control" id="inputValorTroco" />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="lEmail3">Saldo Caixa Anterior</label>
                        <div className="input-group-prepend">
                          <input type="text" className="form-control" disabled id="inputValorAnterior" />
                        </div>
                      </div>
                    </div>


                    <button type="button" className="btn btn-info float-right " id="btnGravar" onClick={e => validaOpenCaixa()}>Abrir Novo Caixa</button>
                    {/* <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={e => CloseEditContr()}>Cancelar</button> */}
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function CaixaAberto(params) {
    return (
      <div id="panelCxOpen" name="panelCxOpen" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-success cardAddBgg" id="cardSelCaixa" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloCxOpen">Caixa Aberto</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardSelCaixaBody">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label id="labelDualList" htmlFor="lNome">Agendamentos Finalizados em Aberto</label>
                        <button type="button" className="btn btn-info " id="btnRefresh" onClick={() => AtuRefresh()} ><i className="fa fa-refresh"></i>&nbsp;Atualizar </button>
                        <button type="button" className="btn btn-info " id="btnVendaAvulsa" onClick={() => confirmVendaAvulsa()} ><i className="fa fa-refresh"></i>&nbsp;Gerar Venda Avulsa </button>
                        <img id="imgloadingDualAgenda" style={{ display: 'none' }} className="row col-md-12" alt="Loading.." src={Loading}></img>
                        <DualListBox
                          id='dualagenda'
                          options={Lanc}
                          selected={LancSelected}
                          onChange={dualChangeLanc}
                        />
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }
  function ChangeAddProd(params) {

    //idagenda, idproduto, cliente, valor, qtd } = AddLanc
    //cl(params)

    let eSelectagenda = document.getElementById('SelectAgenda');
    let eSelectVendedor = document.getElementById('SelectVendedor');
    //cl("eSelectVendedor.value",eSelectVendedor.value)
    if ((eSelectVendedor.value === '0') ||(eSelectagenda.value === '0')){
      modalBase("Atenção", "Primeiro, selecione o Atendente e Id(Comanda)  da Agenda correspondente ao produto que deseja incluir !");
      return
    }
    let cli = 0;

    setAddLanc({
      idagenda: eSelectagenda.value,
      idprof : eSelectVendedor.value,
      idproduto: params[0].value,
      cliente: cli,
      valor: realParaNumber(params[0].valor),
      qtd: 1
    })

  }

  function ChangeAddServ(params) {

    //idagenda, idproduto, cliente, valor, qtd } = AddLanc
    //cl("params", params)
    //cl("global.idprof",global.idprof)

    let eSelectagenda = document.getElementById('SelectAgendaServ');

    if (eSelectagenda.value === '0'){
      modalBase("Atenção", "Primeiro, selecione o Id(Comanda) da Agenda correspondente ao serviço que deseja incluir !");
      return
    }


    setAddLancServ({
      idagenda: eSelectagenda.value,
      idservico: params[0].value,
      valor: realParaNumber(params[0].valor),
      porc: realParaNumber(params[0].porc),
      qtd: 1
    })

  }

  function CaixaFechamento(params) {
    return (
      <div id="panelCxOpen" name="panelCxOpen" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              {/* <div className="card card-info cardAddBgg" > */}
              <div className="card card-info cardAddBgg" id="cardSelFech" style={{ display: 'none' }}>
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloCxOpen">Fechamento Selecionado : {global.gLancSelected}</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body" id="cardSelFechBody">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-7">
                        <img id="imgloadingReactTabulator" style={{ display: 'none' }} className="row col-md-12" alt="Loading.." src={Loading}></img>
                        <div id="divReactTabulator" >
                          <label htmlFor="lNome">Itens Localizados</label>
                          <ReactTabulator
                            name="r1Caixa"
                            id="r1Caixa"
                            // ref={(ref)}
                            columns={columns}
                            data={Lancamentos}
                            /*               rowClick={rowClick}
                                          cellClick={rowClick} */
                            options={options}
                            events={{}}
                          //data-custom-attr="test-custom-attribute"
                          //className="#example-table-theme"
                          />
                          <label id="labelTotal" htmlFor="lCep">Total</label>
                          <div className="input-group-prepend">
                            <input type="text" className="form-control" disabled id="inputTotalCx" />
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Produto - Adicionar</h3>
                          </div>
                          <div className="form-row">
                            <div id="groupSel1" className="form-group col-md-11">
                              <label htmlFor="labelSelect">Selecione o Atendente</label>
                              <select className="form-control select2 select2-hidden-accessible" id="SelectVendedor" data-select2-id={1} tabIndex={-1} aria-hidden="true">
                                {/*                             <option selected="selected" data-select2-id={3}>Link para questionário Incial</option>
                            <option data-select2-id={29}>Cotação Inicial</option> */}
                              </select>
                            </div>
                          </div>
                          <div className="form-row">
                            <div id="groupSel1" className="form-group col-md-2">
                              <label htmlFor="labelSelect">Id Agenda</label>
                              <select className="form-control select2 select2-hidden-accessible" id="SelectAgenda" data-select2-id={1} tabIndex={-1} aria-hidden="true">
                                {/*                             <option selected="selected" data-select2-id={3}>Link para questionário Incial</option>
                            <option data-select2-id={29}>Cotação Inicial</option> */}
                              </select>
                            </div>
                            <div id="SelProd" className="form-group col-md-9" >
                              <label htmlFor="labelSelect">codigo de barras ou descrição</label>
                              <Select
                                closeMenuOnSelect={true}
                                components={global.animatedComponents}
                                onChange={ChangeAddProd}
                                value={searchProdutos}
                                //defaultValue={}
                                isMulti
                                options={ProdutosOpt}
                                placeholder='Adicionar Produto...'
                              />
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Serviço - Adicionar</h3>
                          </div>
                          <div className="form-row">
                            <div id="groupSel1" className="form-group col-md-2">
                              <label htmlFor="labelSelect">Id Agenda</label>
                              <select className="form-control select2 select2-hidden-accessible" id="SelectAgendaServ" data-select2-id={1} tabIndex={-1} aria-hidden="true">
                                {/*                             <option selected="selected" data-select2-id={3}>Link para questionário Incial</option>
                            <option data-select2-id={29}>Cotação Inicial</option> */}
                              </select>
                            </div>
                            <div id="SelServ" className="form-group col-md-9" >
                              <label htmlFor="labelSelect">para adicionar o Serviços</label>
                              <Select
                                closeMenuOnSelect={true}
                                components={global.animatedComponents}
                                onChange={ChangeAddServ}
                                value={searchServicos}
                                //defaultValue={}
                                isMulti
                                options={ServicosOpt}
                                placeholder='Adicionar Serviço...'
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div id="divFormadePG" style={{ display: 'none' }} className="form-group col-md-4">
                        <div className="row">
                          <label id="labelTCalc" name="labelTCalc">Formas de Pagamento</label>
                        </div>
                        <div id="divbtnsFPG" className="form-row">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3">Dinheiro</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgCash" className="btn btn-info " onClick={() => btnFPG('inputVCash')} ><i className="fa fa-money"></i> </button>
                              <input type="text" onBlur={() => calcTroco()} onKeyDown={e => keyDetect(e.key)} className="form-control" id="inputVCash" />
                            </div>
                          </div>
                          <div className="form-group col-md-4 ">
                            <label htmlFor="lEmail3">Troco</label>
                            <div className="input-group-prepend">
                              <input type="text" className="form-control" id="inputVTroco" disabled />
                            </div>
                          </div>
                        </div>
                        <div id="divbtnsFPG" className="form-row">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3">Débito</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgCD" className="btn btn-info " onClick={() => btnFPG('inputVCD')} ><i className="fa fa-credit-card-alt "></i> </button>
                              <input type="text" className="form-control" id="inputVCD" />
                            </div>
                          </div>
                        </div>
                        <div id="divbtnsFPG" className="form-row">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3">Crédito à vista</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgCC" className="btn btn-info " onClick={() => btnFPG('inputVCC')} ><i className="fa fa-credit-card"></i> </button>
                              <input type="text" className="form-control" id="inputVCC" />
                            </div>
                          </div>
                        </div>
                        <div id="divbtnsFPG" className="form-row">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3">Crédito Parcelado</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgCCx" className="btn btn-info " onClick={() => btnFPG('inputVCCx')} ><i className="fa fa-credit-card"></i> </button>
                              <input type="text" className="form-control" id="inputVCCx" />
                            </div>
                          </div>
                        </div>
                        <div id="divbtnsFPG" className="form-row">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3">PIX</label>
                            <div className="input-group-prepend">
                              <button type="button" id="btnPgPix" className="btn btn-info " onClick={() => btnFPG('inputVPix')} ><i className="fa fa-qrcode"></i> </button>
                              <input type="text" className="form-control" id="inputVPix" />
                            </div>
                          </div>
                        </div>
                        <div id="divbtnsFPG" className="form-row" title="Credito Corrente (Ficha Interna) - Somente um fechamento por vez">
                          <div className="form-group col-md-6 ">
                            <label htmlFor="lEmail3" id="labelCC" >CC</label>
                            <div className="input-group-prepend" id="groupCC">
                              <button type="button" id="btnPgConta" className="btn btn-info " onClick={() => btnFPG('inputVConta')} ><i className="fa fa-creative-commons "></i> </button>
                              <input type="text" className="form-control" id="inputVConta" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <button type="button" className="btn btn-info float-right" id="btnGravarPG" onClick={e => btnFinalizarPG()}>Finalizar Pagamento</button>
                        <button type="button" className="btn btn-info " id="btnGravarClose" onClick={e => CloseEditContr()}>Cancelar</button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </section >

          </div >

        </section >
      </div >

    )

  }

  function testCaixa(params) {




  }

  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCidade')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      //global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("(99) 99999-9999");
    im.mask(eNnome);

  }

  function formataFone(params) {
    const nome = params.replace(/_|-/g, '');
    ////cl(nome)
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    let result = im.mask(params);
    return result;

  }


  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function CloseEditContr(params) {
    btnFPGresetAll();
    dualChangeLancReset();
    AtuRefresh();
    let ecardSelCaixa = document.getElementById('cardSelCaixa');
    ecardSelCaixa.classList.remove("collapsed-card");

    let ecardSelCaixaBody = document.getElementById('cardSelCaixaBody');
    if (ecardSelCaixaBody) { ecardSelCaixaBody.style.display = ''; }

    let ecardSelFech = document.getElementById('cardSelFech');
    if (ecardSelFech) { ecardSelFech.style.display = 'none'; }

  }

  function AtuRefresh(params) {

    setAtuLanc({ '0': '0' });

  }

  function btnFPGresetAll(params) {
    let einputVCash = document.getElementById('inputVCash');
    if (einputVCash) { einputVCash.value = ''; }
    let einputVTroco = document.getElementById('inputVTroco');
    if (einputVTroco) { einputVTroco.value = ''; }
    let einputVCD = document.getElementById('inputVCD');
    if (einputVCD) { einputVCD.value = ''; }
    let einputVCC = document.getElementById('inputVCC');
    if (einputVCC) { einputVCC.value = ''; }
    let einputVCCx = document.getElementById('inputVCCx');
    if (einputVCCx) { einputVCCx.value = ''; }
    let einputVPix = document.getElementById('inputVPix');
    if (einputVPix) { einputVPix.value = ''; }
    let einputVConta = document.getElementById('inputVConta');
    if (einputVConta) { einputVConta.value = ''; }

    /*     let einputVCash = document.getElementById('inputVCash');
        if (einputVCash) { einputVCash.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVCD = document.getElementById('inputVCD');
        if (einputVCD) { einputVCD.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVCC = document.getElementById('inputVCC');
        if (einputVCC) { einputVCC.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVCCx = document.getElementById('inputVCCx');
        if (einputVCCx) { einputVCCx.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVPix = document.getElementById('inputVPix');
        if (einputVPix) { einputVPix.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
        let einputVConta = document.getElementById('inputVConta');
        if (einputVConta) { einputVConta.value = (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */
  }

  function btnFPG(params) {
    btnFPGresetAll();
    let eparams = document.getElementById(params);
    if (eparams) {
      eparams.value = global.gSubTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      eparams.focus();
    }

  }

  function calcTroco(params) {
    let einputVCash = document.getElementById('inputVCash');
    let einputVTroco = document.getElementById('inputVTroco');
    let cash = realParaNumber(einputVCash.value);
    let troco = 0;
    //cl(LancSelected);
    troco = cash - global.gSubTotal
    if (troco > 0) {
      let einputVCashFormat = cash.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      einputVCash.value = einputVCashFormat;
      //cl(einputVCashFormat);
      einputVTroco.value = troco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    } else {
      let trocozero = 0;
      let einputVCashFormat = einputVCash.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      einputVCash.value = einputVCash.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      //cl(einputVCashFormat);
      einputVTroco.value = trocozero.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    //cl("troco",troco);

  }

  function removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }

  function dualChangeLanc(params) {

    global.gParams = params;

    let eimgloadingReactTabulator = document.getElementById('imgloadingReactTabulator');
    if (eimgloadingReactTabulator) { eimgloadingReactTabulator.style.display = ''; }
    let edualagenda = document.getElementById('dualagenda');
    if (edualagenda) { edualagenda.style.display = 'none'; }
    let eimgloadingDualAgenda = document.getElementById('imgloadingDualAgenda');
    if (eimgloadingDualAgenda) { eimgloadingDualAgenda.style.display = ''; }

    //cl("params", params)


    setLancSelected(params)
    setAtuLancamentos({ idagenda: global.gParams })
    AtuRefresh();
    let qtd = 0;
    let qtds = 0;
    let qtdmax = 0;
    let qtdcli = 0;
    var i;
    var j;
    var s;
    var s1;
    var idcliente = 0;

    for (i = 0; i < params.length; i++) {
      //cl("params_i", params[i])
      let idfilter = params[i];
      var arrFound = Lanc.filter(function (item, num) {
        return item.value === idfilter;
      });
      if (idcliente !== arrFound[0]['cliente']) {
        idcliente = arrFound[0]['cliente'];
        qtdcli = qtdcli + 1;
      }
      // eslint-disable-next-line no-loop-func
      var arrFoundCli = Lanc.filter(function (item, num) {
        return item.cliente === idcliente;
      });

      //cl("arrFound", arrFound[0]['cliente'])
      //cl("arrFoundCli", arrFoundCli)

      for (j = 0; j < Lanc.length; j++) {
        //cl("compLanc",arrFound[0]['cliente'])
        if (Lanc[j]['cliente'] === arrFound[0]['cliente']) {
          // cl("Somou Lanc")
          qtd = qtd + 1;
        }
      }
      if (arrFoundCli.length > qtdmax) { qtdmax = arrFoundCli.length; }

    }
    //qtds = 0;
    for (s = 0; s < arrFoundCli.length; s++) {
      for (s1 = 0; s1 < params.length; s1++) {
        if (arrFoundCli[s]['value'] === params[s1]) {
          qtds = qtds + 1;
        }
      }
    }


    if (qtd > 1) {
      //window.alert("Atenção : existem mais de um fechamento em aberto deste cliente! ")
      setLanc(arrFoundCli);
      //cl("qtd", qtd);
      //cl("qtds", qtds);
      //cl("qtdmax", qtdmax);
      //cl("qtdcli", qtdcli);
      //cl("Lanc", Lanc);
      //cl("LancSelected", LancSelected);
      //cl("arrFoundCli", arrFoundCli);
      //cl("arrFound", arrFound);
      //cl("params", params)
      //cl("qtds", qtds);
      //cl("params.length", params.length)
      //cl("arrFoundCli.length", arrFoundCli.length)

      if ((qtds < qtdmax) && (params.length !== Lanc.length)) {

        modalBase("Atenção", "Existem mais de um fechamento em aberto deste cliente!");


      } else {
        qtd = 1;
      }
    }



    if (params.length > 0) {

      let egroupCC = document.getElementById('groupCC');
      let elabelCC = document.getElementById('labelCC');
      if (qtdcli > 1) {
        if (egroupCC) { egroupCC.style.display = 'none'; }
        elabelCC.innerHTML = 'CC (Selecione apenas 1 cliente)';
        //cl("bloqueia")
      } else {
        elabelCC.innerHTML = 'CC';
        if (egroupCC) { egroupCC.style.display = ''; }
        // cl("libera")
      }


      let ecardSelCaixa = document.getElementById('cardSelCaixa');

      let ecardSelCaixaBody = document.getElementById('cardSelCaixaBody');
      if (qtd > 1) {
        if (ecardSelCaixaBody) { ecardSelCaixaBody.style.display = ''; }
        ecardSelCaixa.classList.remove("collapsed-card");
      } else {
        if (ecardSelCaixaBody) { ecardSelCaixaBody.style.display = 'none'; }
        ecardSelCaixa.classList.add("collapsed-card");

      }

      let ecardSelFech = document.getElementById('cardSelFech');
      if (ecardSelFech) { ecardSelFech.style.display = ''; }


      let k;
      let eSelectagenda = document.getElementById('SelectAgenda');
      let eSelectagendaServ = document.getElementById('SelectAgendaServ');
      removeOptions(document.getElementById('SelectAgenda'));
      removeOptions(document.getElementById('SelectAgendaServ'));

      if(global.gParams.length > 1){
        let opts = document.createElement('option');
        opts.value = 0;
        opts.innerHTML = 'Selecione...';
        eSelectagenda.appendChild(opts);
        let opts2 = document.createElement('option');
        opts2.value = 0;
        opts2.innerHTML = 'Selecione...';
        eSelectagendaServ.appendChild(opts2);
        //cl("entruuuuuu")

       
      }      
      for (k = 0; k < global.gParams.length; k++) {
        let opt = document.createElement('option');
        opt.value = global.gParams[k];
        opt.innerHTML = global.gParams[k];
        eSelectagenda.appendChild(opt);
      }
      for (k = 0; k < global.gParams.length; k++) {
        let opt = document.createElement('option');
        opt.value = global.gParams[k];
        opt.innerHTML = global.gParams[k];
        eSelectagendaServ.appendChild(opt);
      }


      let w;
      let eSelectServ = document.getElementById('SelectVendedor');
      removeOptions(document.getElementById('SelectVendedor'));
      //cl(searchServicos)
      if(Profis.length > 1){
        let opt = document.createElement('option');
        opt.value = 0;
        opt.innerHTML = 'Selecione...';
        eSelectServ.appendChild(opt);
  
      }
      for (w = 0; w < Profis.length; w++) {
        let opt = document.createElement('option');
        opt.value = Profis[w].id;
        opt.innerHTML = Profis[w].name;
        eSelectServ.appendChild(opt);
      }


    } else {

      let ecardSelFech = document.getElementById('cardSelFech');
      if (ecardSelFech) { ecardSelFech.style.display = 'none'; }

    }


  }

  function keyDetect(params) {

    if (params === "Enter") {

      calcTroco();

      ////cl("key", params)
    }


  }

  function dualChangeLancReset(params) {
    global.gParams = [];
    setLancSelected();
    //cl("params", params)
    setAtuLancamentos({ idagenda: global.gParams })
    AtuRefresh();

  }


  if (repCaixaOpen.length < 1) {
    //if (0 == 1) {
    return (
      <div className="content-wrapper" >
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando Caixa ...</div>
      </div >
    )
  } else {
    return (
      <div className="content-wrapper">
        {CaixaFechado()}
        {CaixaAberto()}
        {CaixaFechamento()}
        {/* {dataTable()} */}
        {testCaixa()}
      </div>
    )

  }



}

export default Gerador   