/* eslint-disable */
//const jwt = require('jsonwebtoken');
export const TOKEN_KEY = "KEY_APP_BGG";
//sessionStorage.removeItem(TOKEN_KEY);
//export const isAuthenticated = () => { if(sessionStorage.getItem(TOKEN_KEY) != null){return true}  };
export const isAuthenticated = () => { if(sessionStorage.getItem(TOKEN_KEY) != null){return true}  };
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);//sessionStorage.getItem(TOKEN_KEY);
export const loginapi = async token => {
    await sessionStorage.setItem(TOKEN_KEY, token); //sessionStorage.setItem(TOKEN_KEY, token);
    //console.log('fbgg');
    //console.log(TOKEN_KEY);
    //console.log(token);
    //const decoded = jwt.verify(sessionStorage.getItem(TOKEN_KEY), new Buffer('dfsfsdfdsfsd').toString('base64url'), { algorithms: ['sha256'] });
    //const iatjwt = decoded.iat;
    //const expjwt = decoded.exp;
    //console.log(decoded);
    //console.log(timeConverter(iatjwt) );
    //console.log(timeConverter(expjwt) );
};
export const logout = () => {
    sessionStorage.removeItem(TOKEN_KEY); //sessionStorage.removeItem(TOKEN_KEY);
};

function timeConverter(UNIX_timestamp){
    var a = new Date(UNIX_timestamp * 1000);
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
    return time;
  }