import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { loginapi } from "./auth";
import Loading from "./assets/loading4.gif";
import Logo from "./assets/login.png";
import "./components/functions";
import { cl } from "./components/functions";
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import './gerCotacoesProf.css';
import './login.css';
const TOKEN_KEY = "KEY_APP_BGG";

function validaEmail(params, idinput) {
  const eNemail = document.getElementById('ilogin')
  const email = eNemail.value;
  global.cademail = email;
  if (EmailValidator.validate(email)) {
    eNemail.classList.add("is-valid")
    eNemail.classList.remove("is-invalid")
    global.inpAddFrmC.inputEmail = true;
    //cl("true global valida", global.inpAddFrmC)
  } else {
    eNemail.classList.add("is-invalid")
    eNemail.classList.remove("is-valid")
    global.inpAddFrmC.inputEmail = false;
    //cl("FALSE global valida", global.inpAddFrmC, params)
    let ebuttonLogin = document.getElementById('buttonLogin');
    let ebuttonLoading = document.getElementById('buttonLoading');
    if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
    if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }
    if (params === 1) { return eNemail.focus() }
  }
  if ((email) && (!global.remail)) { eNemail.value = params.toLowerCase() };

}

// adicinado para manter logado , trocado session por local e add if abaixo para redirecionar login

//if (sessionStorage.getItem(TOKEN_KEY) == "null") {
let whost = window.location.hostname.toString();
let wpathname = window.location.pathname
if ((sessionStorage.getItem(TOKEN_KEY)) && (wpathname === '/login')) {
  //cl("tem token", whost, wpathname)
  window.open("/", "_self");

}


async function login(params) {
  let eilogin = document.getElementById('ilogin');
  let eipassword = document.getElementById('ipassword');
  const btnLogin = document.getElementById("buttonLogin");
  const btnLoading = document.getElementById("buttonLoading")
  if (btnLogin) { btnLogin.style.display = 'none' };
  if (btnLoading) { btnLoading.style.display = '' };
  if ((!global.inpAddFrmC.inputEmail) || (global.inpAddFrmC.inputEmail === false)) {
    //cl("valida email");
    validaEmail(1);
    return
  }
  if ((eipassword.value.length < 6)) {
    window.alert("Senha precisa conter 6 caracteres no mínimo !")
    if (btnLogin) { btnLogin.style.display = '' };
    if (btnLoading) { btnLoading.style.display = 'none' };
    return
  }


  if (!eilogin || !eipassword) {
    document.getElementById('pError').innerHTML = 'Preencha e-mail e senha para continuar!';
    if (btnLogin) { btnLogin.style.display = '' };
    if (btnLoading) { btnLoading.style.display = 'none' };
  } else {
    try {
      var bodyFormData = new FormData();
      //localStorage.setItem('linkbase', 'https://api.youevolution.com.br');
      //const klinkbase = localStorage.getItem('linkbase');
      //global.gLinkBase = klinkbase;
      //console.log("linkbase",klinkbase);
      bodyFormData.set('apikey', '92023QV045NV$Vq3cVBNh@$MG@$OJG48ugyf9');
      bodyFormData.set('email', eilogin.value);
      bodyFormData.set('pass', eipassword.value);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',
        url: 'https://api.youevolution.com.br/login.php',
        //url: 'http://192.168.15.200:8085/appyou/login.php?apikey=79873WR9N8W4ABNAVNUQ4ÁW6937',
        // url: 'http://192.168.99.250:3333/loginusers',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      });

      //const response = await api.post("/loginusers2", { email, password });
      //console.log(response.data);
      //return
      //console.log("login-: ", response.data);
      //return
      if (response.data) {
        loginapi(response.data);
        //irHome();
        window.open("/", "_self");

      } else {
        document.getElementById('pError').innerHTML = 'Email/Usuário ou senha incorretos !';
        if (btnLogin) { btnLogin.style.display = '' };
        if (btnLoading) { btnLoading.style.display = 'none' };
      }
    } catch (err) {
      //cl("erro",err);
      if (!err.response) {
        const btnLogin = document.getElementById("buttonLogin");
        document.getElementById('pError').innerHTML = '>>> Problema de Conexão <<<';
        if (btnLogin) { btnLogin.style.display = '' };
        if (btnLoading) { btnLoading.style.display = 'none' };
      } else {
        document.getElementById('pError').innerHTML = 'Usuário ou Senha inválido !';
        if (btnLogin) { btnLogin.style.display = '' };
        if (btnLoading) { btnLoading.style.display = 'none' };
      }

    }
  }

}


function Gerador() {

  const { idchave, idemail } = useParams();
  let echave = document.getElementById('chave');
  if (echave) { echave.value = idchave; }
  global.rchave = idchave;
  global.remail = idemail;
  //global.urlbg="url('images/bg-4.jpg')";
  global.urlbg = "url('../images/bg-4.jpg')";
  //cl("idchave", idchave)

  const [UrlEmail, setUrlEmail] = useState([]);
  useEffect(() => {
    if (global.remail) {

      let eremail = document.getElementById('ilogin');
      if (eremail) { eremail.value = global.remail; }
      validaEmail();
    }


  }, [UrlEmail])

  function gravar(params) {
    let ebuttonLogin = document.getElementById('buttonLogin');
    if (ebuttonLogin) { ebuttonLogin.style.display = 'none'; }
    let ebuttonLoading = document.getElementById('buttonLoading');
    if (ebuttonLoading) { ebuttonLoading.style.display = ''; }

    let epassword = document.getElementById('password');
    let epassword2 = document.getElementById('password2');

    if ((epassword.value.length < 6) || (epassword.value.length < 6)) {
      window.alert("Senha precisa conter 6 caracteres no mínimo !")
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }
      return
    }

    if (epassword.value === epassword2.value) {

      login();

      if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }

    } else {
      window.alert("Senha não são iguais, favor revisar !")
      epassword.value = '';
      epassword2.value = '';
      epassword.focus();
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }
      return
    }
  }

  function olhoclick(params) {

    let eipassword = document.getElementById('ipassword');
    if (eipassword.type === 'password') {
      eipassword.type = 'text';

    } else {
      eipassword.type = 'password';

    }

  }


  function CaixaFechado(params) {
    return (
      <div>



        <div className="limiter">
          <div className="container-login100" style={{ backgroundImage: global.urlbg }}>
            <div className="wrap-login100">
              <span className="login100-form-logo">
                <img className="imglogo" src={Logo} alt="Login" />
                {/*<i className="zmdi zmdi-landscape" />*/}
              </span>
              <span className="login100-form-title p-b-76 p-t-27" id="spanTituloLogin">
                Ecossistema Universo You
              </span>
              <div className="wrap-input100 validate-input" data-validate="Enter username">
                <input className="input100" type="input" name="login" id="ilogin" onChange={e => validaEmail(e.target.value, 'ilogin')} autoComplete="username email" placeholder="Email" />
                <span className="focus-input100" data-placeholder="" />
              </div>
              <div className="validate-input wrap-input100bgg " data-validate="Enter password">
                <img src="../images/olho.png" id="olho" className="olho" onClick={e => olhoclick()} />
                <input className="input100" type="password" name="password" id="ipassword" autoComplete="current-password" placeholder="Senha" required />
                <span className="focus-input100" data-placeholder="" />
              </div>
              <div className="text-right" id="divtxtesq">
                <a className="txt2" href="/cadastro/reset">
                  {/* <a className="txt2" href="#"> */}
                  Esqueceu a senha ?
                </a>
              </div>
              {/*}  <div className="contact100-form-checkbox">
                                <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
                                <label className="label-checkbox100" htmlFor="ckb1">
                                Remember me
                            </label>
      </div>*/}
              <div className="container-login100-form-btn">
                <button onClick={() => login()} className="login100-form-btn" id="buttonLogin" name="buttonLogin">

                  <i className="fa fa-cube" aria-hidden="true"></i>
                  &nbsp;<span id="spanLogin">Entrar</span>
                </button>
                <button type="" className="login100-form-btn" id="buttonLoading" name="buttonLoading" style={{ display: 'none' }}>

                  <span id="spanLogin"><img id="imgloading" width="50px" alt="Loading.." src={Loading}></img>Aguarde</span>

                </button>
              </div>

              <div className="text-center p-t-20">
                <p className="pError" id="pError"></p>

              </div>
              <div className="text-center" id="txtdivnovocad">
                <a className="txt3" href="/cadastro">
                  {/* <a className="txt3" href="#"> */}
                  Ainda não tem cadastro ?<br></br>
                  Cadastre-se gratuitamente!<br></br>
                  Clique aqui 🎯
                </a>
              </div>
            </div>
            <div className="lights">
              <div className="light x1" />
              <div className="light x2" />
              <div className="light x3" />
              <div className="light x4" />
              <div className="light x5" />
              <div className="light x6" />
              <div className="light x7" />
              <div className="light x8" />
              <div className="light x9" />
            </div>
          </div>
        </div>
        <div id="dropDownSelect1" />

      </div>
    )

  }


  return (
    <div>
      {CaixaFechado()}
    </div>
  )

}

export default Gerador   