import React, { useState, useEffect, useRef, useContext } from "react";
//import disableDevtool from 'disable-devtool';
import axios from "axios";
import { cl } from "./components/functions";
//import publicIp from  'public-ip';

/* export default class Footer extends Component {

    render() {

        // (async () => {
        //     global.gIp = await publicIp.v4(); 
        //     console.log(global.gIp);
        //     // let p_ip = document.getElementById('p_ip');
        //     // p_ip.innerHTML = "Ip: "+global.gIp;
        //     //=> '46.5.21.123'

        //     //console.log(await publicIp.v6());
        //     //=> 'fe80::200:f8ff:fe21:67cf'
        // })();

        if ((global.gVersion) && (global.gNewVersion) && (global.gVersion !== global.gNewVersion)) {
            let msg = "Nova Atualização disponível do sistema, versão " + global.gNewVersion + " , saia do sistema e atualize seu navegador agora ou reinstale o APP!";
            window.alert(msg);
            //window.location.href = "/login";
        }

        return (
            <div>
                <footer className="main-footer">
                    <p>Responsabilidade Plena CNPJ 29.890.553/0001-49 - <a target="_blank" id="a_you" rel="noopener noreferrer" href="https://grupoviadelivery.com.br/">Kainon Mathias Souza dos Santos</a></p>
                    Desenvolvimento ©2023 by<a target="_blank" id="a_you" rel="noopener noreferrer" href="https://youevolution.com.br/desenvolvimento">   You Evolution </a>.
                    Todos os direitos intelectuais reservados.
                    <div className="float-right d-sm-inline-block">
                        <b>Versão</b> {global.gVersion}
                    </div>
                </footer>


            </div>
        )
    }
} */

function Gerador(params) {

    const [ip, setIP] = useState("");
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        //console.log(res.data);
        setIP(res.data.ip);
        global.gIp = res.data.ip;
    };
    getData();
    
    useEffect(() => {
        //passing getData method to the lifecycle method
        getData();
    }, []);
    
    async function getVersion () {
        var bodyFormData = new FormData();
        bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
        const urlfull = 'https://api.youevolution.com.br/listaVersion.php';
        const response = await axios({
          method: 'post',
          url: urlfull,
          data: bodyFormData,
          config: { headers: { 'Content-Type': 'multipart/form-data' } }
        })
        //cl("response",response['data'][0].ver);
        global.gNewVersion = response['data'][0].ver;
        if ((global.gVersion) && (global.gNewVersion) && (global.gVersion !== global.gNewVersion) && (global.showVersion !== 1)) {
            let msg = "Nova Atualização disponível do sistema, versão " + global.gNewVersion + " , saia do sistema e atualize seu navegador agora ou reinstale o APP!";
            window.alert(msg);
            global.showVersion = 1;
            //window.location.href = "/login";
        }    
    };

    getVersion();
    //cl("clglobal.gVersion",global.gVersion);
    //cl("clglobal.gNewVersion",global.gNewVersion);




    let whost = window.location.hostname.toString();

    let whref =  'nodev';

    //cl("whost",whost)

    //let url = 'https://app.riluk.com.br/nodev';
    let url = whref;

    if(whost !== 'localhost'){

        //disableDevtool({ url: url });
    }
    
    //console.log("habilitar disableDevtool");
    //cl("window.location.href",window.location.href)
    //cl("window.location.hostname",window.location.hostname)
    //cl("window.location.pathname",window.location.pathname)

    return (
        <div>
        <footer className="main-footer">
            Desenvolvimento ©2023 by<a target="_blank" id="a_you" rel="noopener noreferrer" href="https://youevolution.com.br/desenvolvimento">   You Evolution </a>.
            Todos os direitos intelectuais reservados.
            <div className="float-right d-sm-inline-block">
                <b>Versão</b> {global.gVersion}
            </div>
        </footer>


    </div>
    )

}
export default Gerador
