import React, { Component } from 'react'
import { Link } from "react-router-dom";
import './menu.css'


function MenuGestaoProf() {

    return (
        <li className="nav-item has-treeview menu-open" >

            <Link to="/home" className="nav-link">
                <i className="nav-icon fa fa-handshake-o" />
                <p>
                    Gestão
            <i className="fas fa-angle-left right" />
                </p>
            </Link>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                    <Link to="/home" className="nav-link">
                        <i className="fa fa-calendar nav-icon" />
                        <p>Agenda Geral
                    </p>
                    </Link>
                </li>

            </ul>
{/*             <ul className="nav nav-treeview">
                <li className="nav-item">
                    <Link to="/prof-consulta" className="nav-link">
                        <i className="fa fa-users nav-icon" />
                        <p>Comissões
                    </p>
                    </Link>
                </li>
            </ul> */}
        </li>
    );
}


class MenuProf extends Component {
    componentDidMount() {
        //window.addEventListener('load', this.handleSignOut);

        // const TOKEN_KEY = "KEY_APP_BGG";
        // const token = sessionStorage.getItem(TOKEN_KEY);
        // var base64Url = token.split('.')[1];
        // var decodedValue = JSON.parse(window.atob(base64Url));
        // console.log(decodedValue);
        // var novoObj = decodedValue;
        // let nome = novoObj.nome;
        // console.log(nome);

        // let buff = new Buffer(token, 'base64'); 
        // if (typeof Buffer.from === "function") {
        //     // Node 5.10+
        //     buff = Buffer.from(buff, 'base64'); // Ta-da
        // } else {
        //     // older Node versions, now deprecated
        //     buff = new Buffer(buff, 'base64'); // Ta-da
        // } 
        // let text = buff.toString('ascii');
        
    }
    render() {
        //const TOKEN_KEY = "KEY_APP_BGG";
        //const token = sessionStorage.getItem(TOKEN_KEY);
        //var base64Url = token.split('.')[1];
        //var decodedValue = JSON.parse(window.atob(base64Url));
        //console.log(decodedValue);
        //var novoObj = decodedValue;
        //let nome = novoObj.nome;
        //console.log(nome);
        let itemmenu1;
        let itemmenu2;
        let itemmenu3;
        let itemmenu4;
        let itemmenu5;
        

            itemmenu2 = MenuGestaoProf();
  

        
        return (
            <div >
                <aside className="main-sidebar sidebar-dark-primary elevation-4"  >
                    {/* Brand Logo */}
                    <Link to="/home" className="brand-link">
                        <img src="dist/img/login.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                        <span className="brand-text font-weight-light">Universo YOU</span>
                    </Link>
                    {/* Sidebar */}


                    <div className="sidebar">
                        <div className="divMenuBgg" style={{ backgroundImage: 'url("/images/bg-1.jpg")' }}></div>

                        <nav className="mt-2" >
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                                <li className="nav-item">
                                    <Link to="/home" className="nav-link">
                                        <i className="nav-icon fas fa-th" />
                                        <p>
                                            Painel Inicial
                                        </p>
                                    </Link>
                                </li>
                                {itemmenu2}
                                <li className="nav-item has-treeview menu-open" >
                                    <ul className="nav nav-treeview">
                                    </ul>
                                </li>
                                <li className="nav-header"></li>
                                <li className="nav-item">

                                </li>
                                <li className="nav-header"></li>
                                <li className="nav-item">

                                </li>
                            </ul>
                        </nav>
                    </div>
                </aside>


            </div>
        )
    }
}
export default MenuProf