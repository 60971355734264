import React, { useState, useEffect, useRef, useContext } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
//import { withRouter } from "react-router-dom";
import Loading from "./assets/loading11.gif";
import Loading2 from "./assets/loading4.gif";
//import Logo from "./assets/login.png";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './clientes.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
//import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import '../node_modules/moment/locale/pt-br';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
//import { Link, animateScroll as scroll } from "react-scroll";
import Hotkeys from 'react-hot-keys';
//import date from 'date-and-time';
/* import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType
} from "docx"; */
//import { CL } from "pdfmake/build/pdfmake";
//import * as fs from "fs";
//const fs = require('fs')

var gGlobal = require('./components/globalvar.js');

const wordIcon = function (cell, formatterParams) { //plain text value
  return "<i class='far fa-file-word'></i>";
};
const editIcon = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-edit'></i>";
};
const iconContrato = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-file-download'></i>";
};
const iconwhatsappweb = function (cell, formatterParams) { //plain text value
  return "<i class='fa fa-whatsapp'></i>";
}

const iconLixeira = function (cell, formatterParams) { //plain text value
  //if (cell.getRow().getData().progress < 11){
  //return "<i class='fa fa-trash-o'></i>";
  return "❌";
  //} else {
  //  return "-";
  //}
}

global.gLog = [];

function rowClick(e, row) {
  ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
  //cl("rowClick id: " + row.getData().id, row, e);
  ////cl(rep);

};

function Gerador() {

  const formatData = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    let dia = cell.getValue().substr(8, 2)
    let mes = cell.getValue().substr(5, 2)
    let ano = cell.getValue().substr(0, 4)
    let result = dia + '/' + mes + '/' + ano

    return result; //return the contents of the cell;  
  }

  const formatDataHora = function (cell, formatterParams, onRendered) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    //cl("clcell",cell.getValue());
    if (!cell.getValue()) { return }
    let dia = cell.getValue().substr(8, 2)
    let mes = cell.getValue().substr(5, 2)
    let ano = cell.getValue().substr(2, 2)
    let hora = cell.getValue().substr(11, 2)
    let minuto = cell.getValue().substr(14, 2)
    let result = dia + '/' + mes + '/' + ano + ' ' + hora + 'h' + minuto

    return result; //return the contents of the cell;  
  }

  const formatDataHoraSpan = function (params) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    //cl("clcell",cell.getValue());
    if (!params) { return }
    let dia = params.substr(8, 2)
    let mes = params.substr(5, 2)
    let ano = params.substr(2, 2)
    let hora = params.substr(11, 2)
    let minuto = params.substr(14, 2)
    let result = dia + '/' + mes + ' ' + hora + 'h' + minuto

    return result; //return the contents of the cell;  
  }

  const formatDataSpan = function (params) {
    //cell - the cell component
    //formatterParams - parameters set for the column
    //onRendered - function to call when the formatter has been rendered
    //cl("clcell",cell.getValue());
    if (!params) { return }
    let dia = params.substr(8, 2)
    let mes = params.substr(5, 2)
    let ano = params.substr(2, 2)
    let hora = params.substr(11, 2)
    let minuto = params.substr(14, 2)
    let result = dia + '/' + mes

    return result; //return the contents of the cell;  
  }


  const formatStatus = function (cell, formatterParams, onRendered) {
    let result = ''
    if (cell.getValue() === 'inicial') {
      result = '<div class="statusDisp">DISPONÍVEL</div>'
    }
    if (cell.getValue() === 'atendimento') {
      result = '<div class="statusAnda">ANDAMENTO</div>'
    }
    if (cell.getValue() === 'finalizado') {
      result = '<div class="aprovsim">FINALIZADA</div>'
    }

    return result; //return the contents of the cell;  
  }

  const formatProf = function (cell, formatterParams, onRendered) {
    let result = cell.getValue()
    if (cell.getValue() === 'Disponível') {
      result = 'localizando...'
    }


    return result; //return the contents of the cell;  
  }

  global.inicial = 0;



  const [Atu, setAtu] = useState([]);
  const [rep, setrep] = useState([]);
  const [repCliout, setrepCliout] = useState([]);
  const [AtuCad, setAtuCad] = useState([]);
  const [NovaObs, setNovaObs] = useState([]);
  const [EventoCancelar, setEventoCancelar] = useState([]);

  const [ClientesPre, setClientesPre] = useState([]);
  const [Clientes, setClientes] = useState([]);

  const [SetoresPre, setSetoresPre] = useState([]);
  const [Setores, setSetores] = useState([]);

  const [ServicosSelected, setServicosSelected] = useState([]);
  const [searchString, setsearchString] = useState('');
  const [searchServicos, setsearchServicos] = useState('');


  const [DocNow, setDocNow] = useState([]);
  const isFirstRunAtuCad = useRef(true);
  const isFirstRunEventoCancelar = useRef(true);
  const isFirstRunRep = useRef(true);

/*   React.useEffect(() => {
    document.addEventListener("keydown", function (event: any) {
      if (event.keyCode === 13 && event.target.nodeName === "textarea") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    });
  }, []); */

  function abreviar(str) {

    if (str) {
  
      const [nome, ...sobrenomes] = str.split(' ');
  
      const abreviaturas = sobrenomes.reduce((arr, str) => {
        const letraGrande = str.match(/[A-ZÖÄÅÀÁÂÃÌÍÒÓÉÊÚ]/);
        if (!letraGrande) return arr;
        return arr.concat(`${letraGrande[0]}.`);
      }, []);
  
      return [nome, ...abreviaturas].join(' ');
    } else {
      return str;
    }
  }

  function montarFichas(params) {
    let edivFichas = document.getElementById('divfichas');
    let edivFichasfim = document.getElementById('divfichasrealizadas');
    let edivFichasDetalhes = document.getElementById('divFichasDetalhes');
    if (edivFichasDetalhes) { edivFichasDetalhes.remove(); }
    let edivFichasDetalhesfim = document.getElementById('divFichasDetalhesfim');
    if (edivFichasDetalhesfim) { edivFichasDetalhesfim.remove(); }


    let divresult = document.createElement('div');
    divresult.className = 'row';
    divresult.id = 'divFichasDetalhes';

    let divresultfim = document.createElement('div');
    divresultfim.className = 'row';
    divresultfim.id = 'divFichasDetalhesfim';

    //cl("clrep",rep);
    let k = 0;
    let dia = formatDataSpan(rep[k].start)
    let diafor = formatDataSpan(rep[k].start)

    for (k = 0; k < rep.length; k++) {

      diafor = formatDataSpan(rep[k].start)
      if (dia !== diafor) {
        //cl("clk", k);
        //cl("cldia", dia);
        //cl("cldiafor", diafor);
        //cl("clid", rep[k].id);
        continue;
      }
      let div1 = document.createElement('div');
      div1.className = 'col-md-4 col-sm-6 col-12'
      let div2 = document.createElement('div');
      div2.className = 'info-box'
      let span1 = document.createElement('span')
      let statusLabel = ''

      switch (rep[k].status) {
        case 'inicial':
          span1.className = 'info-box-icon bg-info';
          statusLabel = 'Aguardando';
          break;
        case 'atendimento':
          span1.className = 'info-box-icon bg-warning';
          statusLabel = 'Em Andamento';
          break;
        case 'finalizado':
          span1.className = 'info-box-icon bg-success';
          statusLabel = 'Finalizado';
          break;
        default:
          span1.className = 'info-box-icon bg-info';
          break;
      }
      let repdatahora = formatDataHoraSpan(rep[k].start);
      span1.innerHTML = '<div class="datadivid">' + rep[k].id + '</div><br><div class="dataid">' + repdatahora + '<br>⏱' + rep[k].tempdisp + ' min.</div>'

      let div3 = document.createElement('div');
      div3.className = 'info-box-content'

      let spanTipo = document.createElement('span')
      spanTipo.className = 'info-box-text'
      spanTipo.innerHTML = '<b>' + rep[k].servicos + '</b> - ' + statusLabel;


      let span2 = document.createElement('span')
      span2.className = 'info-box-text';
      let letranomeent = rep[k].nomeentrega.substr(0, 1);
      let restnomeent = rep[k].nomeentrega.substr(1, 15);
      //span2.innerHTML = 'Coleta: ' + rep[k].nomeentrega.substr(0,16)+'...';
      span2.innerHTML = 'Para: ' + letranomeent.toUpperCase() + restnomeent.toLowerCase();

      let span3 = document.createElement('span')
      span3.className = 'info-box-text'
      span3.innerHTML = 'End.: ' + rep[k].entrega;

      let span4 = document.createElement('span')
      span4.className = 'info-box-text'
      let motorista = '';
      if (rep[k].resourceTitle === 'Disponível') {
        motorista = 'localizando...'
      } else {
        motorista = rep[k].resourceTitle;
      }
      span4.innerHTML = 'Profissional: ' + motorista;

      let btn1 = document.createElement('button');
      btn1.className = 'btn btn-danger bggcardbtn';
      btn1.innerHTML = 'Cancelar';
      let selId = rep[k].id;
      let selTitle = rep[k].title;
      let selColeta = rep[k].coleta;
      let selEntrega = rep[k].entrega;
      btn1.onclick = () => confirmDelProp(selId, selTitle, selColeta, selEntrega)//Notification.requestPermission().then(parm => {
      //alert(parm);
      //if (parm === 'granted') {
      //new Notification("Funciona!")
      // }
      //})//confirmDelProp(selId, selTitle, selColeta, selEntrega);

      //cl("rep", rep)

      let btn2 = document.createElement('button');
      btn2.className = 'btn btn-info bggcardbtn';
      btn2.innerHTML = 'Visualizar';
      let visId = rep[k].id;
      let visTitle = rep[k].title;
      let visColeta = rep[k].coleta;
      let visEntrega = rep[k].entrega;
      let visFoneEntrega = rep[k].foneentrega;
      let visNomeEntrega = rep[k].nomeentrega;
      let visSetor = rep[k].setor;
      let visPedido = rep[k].pedido;
      let visTempdisp = rep[k].tempdisp;
      let visValor = rep[k].valor;

      let visData = formatDataHoraSpan(rep[k].start);
      let visTipo = rep[k].servicos;
      btn2.onclick = () => visualizarProp(visId, visTitle, visColeta, visEntrega, visNomeEntrega, visFoneEntrega, motorista, visData, visTipo, statusLabel, visSetor, visPedido, visTempdisp, visValor);
      //numprop, cliente, Coleta, Entrega, motorista, data, tipo

      let btn3 = document.createElement('button');
      btn3.className = 'btn btn-secondary bggcardbtn';
      btn3.innerHTML = 'Obs.';
      btn3.onclick = () => incluirObs(visId, visTitle, visColeta, visEntrega, visNomeEntrega, visFoneEntrega, motorista, visData, visTipo, statusLabel, visSetor, visPedido, visTempdisp, visValor);

      let btnWhatsApp = document.createElement('i')
      btnWhatsApp.className = 'btn btn-success fa fa-whatsapp btnmot';
      let zapTel = rep[k].profFone;
      btnWhatsApp.onclick = () => whatsappweb(motorista, zapTel);

      let btnWhatsAppEntrega = document.createElement('i')
      btnWhatsAppEntrega.className = 'btn btn-success fa fa-whatsapp btnmot';
      let zapNomeEntrega = rep[k].nomeentrega;
      let zapTelEntrega = rep[k].foneentrega;

      btnWhatsAppEntrega.onclick = () => whatsappweb(zapNomeEntrega, zapTelEntrega);


      let divBtns = document.createElement('div');
      divBtns.id = 'divbtnsAction'



      div3.appendChild(spanTipo)
      div3.appendChild(span2)
      div3.appendChild(span3)
      div3.appendChild(span4)
      span2.appendChild(btnWhatsAppEntrega)
      if (rep[k].status !== 'inicial') {
        span4.appendChild(btnWhatsApp)
      }
      divBtns.appendChild(btn2)
      if (rep[k].status === 'inicial') {
        divBtns.appendChild(btn1)
        divBtns.appendChild(btn3)
      }
      if (rep[k].status === 'atendimento') {
        divBtns.appendChild(btn3)
      }
      div3.appendChild(divBtns)

      div2.appendChild(span1)
      div2.appendChild(div3)
      div1.appendChild(div2)
      if (rep[k].status === 'finalizado') {
        divresultfim.appendChild(div1)
      } else {

        divresult.appendChild(div1)
      }


    }
    edivFichas.appendChild(divresult);
    edivFichasfim.appendChild(divresultfim);




  }


  //atualiza automatico
  useEffect(() => {
    async function agendachecaAtu(atu) {

      if (global.gAtuOff === 1) {
        //cl("clglobal.gAtuOff",global.gAtuOff);
        //window.alert("global.gAtuOff === 1");
        return
      }
      //cl("Inicio Atu", atu)
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('user', global.gIdUser);
      bodyFormData.set('validauser', 1);

      const response = await axios({
        method: 'post',
        url: 'https://api.youevolution.com.br/listaAgendaAtu.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("clresponse['data']",response['data']);

      if (response['data'][0]['status'] === '0') {
        //cl("desativado")
        window.open("/logout", "_self");
      }

      global.AgendaAtu = new Date(response['data'][0]['res']);

      if (atu === 1) {
        global.AgendaAtuComp = new Date(response['data'][0]['res']);
        //cl("Atu", atu)

      }
      /*     //cl("Ax-AgendaAtu", global.AgendaAtu)
          //cl("AX-AgendaAtuComp", global.AgendaAtuComp) */

      global.gInterval = 0;

    }
    let timeset = 3000;

    agendachecaAtu(1);
    //cl("chamou 1 agendachecaAtu(1)");
    const interval = setInterval(() => {
      if (global.gInterval === 1) {
        //cl("clglobal.gInterval",global.gInterval);
        return
      }

      /*      //cl("Inter-AgendaAtu", global.AgendaAtu)
          //cl("Inter-AgendaAtuComp", global.AgendaAtuComp)  */
      ////cl("atualizando...linha 126")
      //new Notification("Funciona!")
      if ((global.AgendaAtuComp.getTime() !== global.AgendaAtu.getTime()) && (global.gAtuOff !== 1)) {
/*       //cl("-AgendaAtu", global.AgendaAtu)
      //cl("-AgendaAtuComp", global.AgendaAtuComp)
 */        agendachecaAtu(1);
        //cl("chamou 2 agendachecaAtu(1)");
        global.AgendaAtuComp = global.AgendaAtu;
        /*       //cl("-2AgendaAtu", global.AgendaAtu)
              //cl("-2AgendaAtuComp", global.AgendaAtuComp) */
        setAtu({ '0': '0' });
        //new Notification("Nova Atualização!")

      } else {
        /*       //cl("M-AgendaAtu", global.AgendaAtu)
              //cl("M-AgendaAtuComp", global.AgendaAtuComp) */
        agendachecaAtu();
        //cl("chamou 3 agendachecaAtu(1)");

      }
      global.gInterval = 1;
    }, timeset)


    return () => clearInterval(interval)
  }, [])


  useEffect(() => {
    /*     if (isFirstRunRep.current) {
          isFirstRunRep.current = false;
          return;
        }  */
    //cl("clrep", rep);
    if (rep.length > 0) {
      function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }

      async function loopDisplay(params) {
        let ecardOcorrencias = document.getElementById('cardOcorrenciasCli');
        let ecardOcorrenciasfechadas = document.getElementById('cardOcorrenciasfechadas');
        let ecardOcorrenciasgrid = document.getElementById('cardOcorrenciasgridCli');
        if (ecardOcorrencias) {
          ecardOcorrencias.style.display = params;
          ecardOcorrenciasfechadas.style.display = params;
          ecardOcorrenciasgrid.style.display = params;
          montarFichas();
        } else {
          await delay(1000);
          loopDisplay(params);
        };

      }

      if ((!rep[0]['id']) || (rep[0]['id'] === null) || (rep[0]['id'] === undefined)) {
        //cl("rep clentrou");

        loopDisplay('none');

        return
      } else {
        //cl("rep else");
        loopDisplay('');

      }
    }

  }, [rep])

  useEffect(() => {



  }, [DocNow])

  function removeOptions(selectElement) {
    var i, L = selectElement.options.length - 1;
    for (i = L; i >= 0; i--) {
      selectElement.remove(i);
    }
  }


  useEffect(() => {

    if (isFirstRunEventoCancelar.current) {
      isFirstRunEventoCancelar.current = false;
      return;
    }

    const { id, user } = EventoCancelar

    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', id);
      bodyFormData.set('user', user);
      bodyFormData.set('status', 'cancelado');
      const response = await axios({
        method: 'post',

        url: 'https://api.youevolution.com.br/EventoCancelar.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        //cl("result", response)
        setrepCliout({ '0': '0' });
        setAtu({ '0': '0' });
      };


      if (!ignore) {
      }
    }

    //cl('gEventoCancelar', global.gEventoCancelar)
    if (global.gEventoCancelar === 1) {

      fetchdata();
      global.gEventoCancelar = 0;

    } else { return };
    return () => { ignore = true };

  }, [EventoCancelar])

  useEffect(() => {

    cl("clNovaObs",NovaObs);
    if (global.setNovaObs !== 1) {
      return;
    }

    const { id, nome, obs, novaobs, user } = NovaObs
    let ignore = false;
    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', id);
      bodyFormData.set('nome', nome);
      bodyFormData.set('obs', obs);
      bodyFormData.set('novaobs', novaobs);
      bodyFormData.set('user', user);
      const response = await axios({
        method: 'post',

        url: 'https://api.youevolution.com.br/novaObs.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })

      if (response) {
        //cl("result", response)
        //setrepCliout({ '0': '0' });
        setAtu({ '0': '0' });
      };


      if (!ignore) {
      }
    }
    
    //cl('gEventoCancelar', global.gEventoCancelar)
    if (global.setNovaObs === 1) {

      fetchdata();
      global.setNovaObs = 0;

    } else { return };
    return () => { ignore = true };

  }, [NovaObs])

  useEffect(() => {
    //cl("clrepCliout['data']",repCliout['data']);
    //se for vazio sai
    if ((!repCliout['data']) || (repCliout['data'] === null)) {
      //cl("clentrou");
      let epanelActions = document.getElementById('panelActions');
      if (epanelActions) { epanelActions.style.display = 'none' };
      let eplistagenda = document.getElementById('plistagenda');
      if (eplistagenda) { eplistagenda.innerText = '❌ Nenhum agendamento disponível' };
      return
    } else {
      let epanelActions = document.getElementById('panelActions');
      if (epanelActions) { epanelActions.style.display = '' };

    }

    //    let k;

    /*     let eSelectServ = document.getElementById('SelectServ');
        if (eSelectServ) {
          removeOptions(document.getElementById('SelectServ'));
          //removeOptions(document.getElementById('SelectMedium'));
          //removeOptions(document.getElementById('SelectHorario'));
          if (global.inicial > 0) {
            //validaSelectServ();
            //validaSelectMedium();
          }
          let einputEndColeta = document.getElementById('inputEndColeta');
          let einputEndEntrega = document.getElementById('inputEndEntrega');
          let einputNome = document.getElementById('inputNome');
          let einputFone = document.getElementById('inputFone');
          einputEndColeta.value = '';
          einputEndEntrega.value = '';
          einputNome.value = '';
          einputFone.value = '';
          global.inicial = global.inicial + 1;
          let opt = document.createElement('option');
          opt.value = 0;
          opt.innerHTML = 'Selecione...';
          eSelectServ.appendChild(opt);
          global.Serv = repCliout['data'];
          //cl("global.Serv",global.Serv) ;
          /* var GiraFilter = global.Serv.filter(function (item, num) {
            return item.id === 4;
          });
          let startAgendam = GiraFilter[0]['start'].substr(0,11)+'08:35'+':00' */
    //cl("GiraFilter",GiraFilter) ;
    //cl("GiraFilterstart",GiraFilter[0]['start']) ;
    //cl("startAgendam",startAgendam) ;
    /*
          for (k = 0; k < repCliout['data'].length; k++) {
            let opt = document.createElement('option');
            //let dayname3 = moment(repCliout['data'][k]['start'], "YYYY-MM-DD HH:mm:ss").format('dddd').substring(0, 3);
            //let dayname = moment(repCliout['data'][k]['start'], "YYYY-MM-DD HH:mm:ss").format('dddd')
            opt.value = repCliout['data'][k]['id'];
            //opt.innerHTML = repCliout['data'][k]['start'].substr(8, 2) + '/' + repCliout['data'][k]['start'].substr(5, 2) + '/' + repCliout['data'][k]['start'].substr(0, 4) + '(' + dayname3.toUpperCase() + ')' + ' - ' + repCliout['data'][k]['titulo'];
            opt.innerHTML = repCliout['data'][k]['descricao'];
            eSelectServ.appendChild(opt);
    
          }
        } */


  }, [repCliout])


  useEffect(() => {
    /*     if (isFirstRunDados.current) {
          isFirstRunDados.current = false;
          //cl("FirstRunDados false")
          return;
        } */

    let ignore = false;

    async function fetchdataCli() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      //bodyFormData.set('comp', 1);
      bodyFormData.set('idcli', global.gIdCliFromUsu);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaAgendaCli.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //repCliout(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];

        if (redata2) {
          setrep(redata2);
        } else {
          setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);



        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
        let edivPross = document.getElementById('divPross');
        if (edivPross) { edivPross.style.display = 'none' };



      }
    }
    fetchdataCli();

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('comp', 1);
      bodyFormData.set('idcli', global.gIdCliFromUsu);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaServ.php';
      //const urlfull = 'https://api.youevolution.com.br/listaGira.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      setrepCliout(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];


        if (redata2) {
          //setrep(redata2);
        } else {
          //setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();

    /*     function delay(time) {
          return new Promise(resolve => setTimeout(resolve, time));
        } */

    async function fetchdataSetor() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('comp', 0);
      bodyFormData.set('cliente', global.gIdCliFromUsu);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaSetor.php';
      //const urlfull = 'https://api.youevolution.com.br/listaGira.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("responseSetores",response);
      //await delay(5000)
      setSetoresPre(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];


        if (redata2) {
          //setrep(redata2);
        } else {
          //setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdataSetor();
    setClientesPre({ '0': '0' });
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [Atu])

  useEffect(() => {
    if (isFirstRunAtuCad.current) {
      isFirstRunAtuCad.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', AtuCad.id);
      bodyFormData.set('descricao', AtuCad.descricao);
      bodyFormData.set('grupo', AtuCad.grupo);
      bodyFormData.set('valor', realParaNumber(AtuCad.valor));
      bodyFormData.set('porc', AtuCad.porc);
      bodyFormData.set('tempomedio', AtuCad.tempomedio);
      bodyFormData.set('log', JSON.stringify(global.gLog));
      bodyFormData.set('user', global.gIdUser);
      //cl("bodyFormData",bodyFormData);
      const urlfull = 'https://api.youevolution.com.br/ServAtu.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //setrepCliout(response);
      setAtu({ '0': '0' });
      if (!ignore) {
        /*         const redata = [{}];
                const redata2 = response['data'];
                if (redata2) {
                  setrep(redata2);
                } else {
                  setrep(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    if (global.gAtuCad === 1) {

      fetchdata();
      global.gAtuCad = 0;

    } else { return };
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuCad])

  useEffect(() => {
    //cl("clglobal.gIdCliFromUsu",global.gIdCliFromUsu);
    let ignoreProf = false;
    async function fetchclientes() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('cliente', Number(global.gIdCliFromUsu));
      //bodyFormData.set('doc', global.gDoc);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const response = await axios({
        method: 'post',

        //url: global.gLinkBase + '/appyou/rest.php?apikey=79873WR9N8W4ABNAVNUQ4ÁW69376',
        url: 'https://api.youevolution.com.br/listaCliDoCli.php',
        // url: 'http://192.168.99.250:3333/listNewContacs',

        //port: 3000,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })


      if (!ignoreProf) {
        const redata = response['data'];
        //cl('redata', redata);
        //cl('users', Users);

        if (response['data'].length > 0) {
          setClientes(redata);

        }
        //setLocalizer(ll);
        ignoreProf = true;

        //setEventosPRE(redata);

        //let eleLoading = document.getElementById('divLoad');
        //eleLoading.style.display = 'none';
      }
    }
    fetchclientes();


  }, [ClientesPre])


  useEffect(() => {



  }, [Clientes])

  useEffect(() => {

    //cl("clSetoresPre", SetoresPre['data']);


    let k;

    let SelectSetor = document.getElementById('SelectSetor');
    if (SelectSetor) {
      removeOptions(document.getElementById('SelectSetor'));
      //removeOptions(document.getElementById('SelectMedium'));
      //removeOptions(document.getElementById('SelectHorario'));
      if (global.inicial > 0) {
        //validaSelectSetor();
        //validaSelectMedium();
      }

      let opt = document.createElement('option');
      opt.value = 0;
      opt.innerHTML = 'Selecione...';
      SelectSetor.appendChild(opt);
      global.Setor = SetoresPre['data'];
      //cl("global.Serv",global.Serv) ;
      /* var GiraFilter = global.Serv.filter(function (item, num) {
        return item.id === 4;
      });
      let startAgendam = GiraFilter[0]['start'].substr(0,11)+'08:35'+':00' */
      //cl("GiraFilter",GiraFilter) ;
      //cl("GiraFilterstart",GiraFilter[0]['start']) ;
      //cl("startAgendam",startAgendam) ;

      for (k = 0; k < SetoresPre['data'].length; k++) {
        let opt = document.createElement('option');
        //let dayname3 = moment(SetoresPre['data'][k]['start'], "YYYY-MM-DD HH:mm:ss").format('dddd').substring(0, 3);
        //let dayname = moment(SetoresPre['data'][k]['start'], "YYYY-MM-DD HH:mm:ss").format('dddd')
        opt.value = SetoresPre['data'][k]['id'];
        //opt.innerHTML = SetoresPre['data'][k]['start'].substr(8, 2) + '/' + SetoresPre['data'][k]['start'].substr(5, 2) + '/' + SetoresPre['data'][k]['start'].substr(0, 4) + '(' + dayname3.toUpperCase() + ')' + ' - ' + SetoresPre['data'][k]['titulo'];
        opt.innerHTML = SetoresPre['data'][k]['setor'];
        SelectSetor.appendChild(opt);

      }
    }



  }, [SetoresPre])

  useEffect(() => {



  }, [Setores])

  useEffect(() => {

    ////cl("ServicosSelected", ServicosSelected)

  }, [ServicosSelected])

  function validaSelectSetor(params) {
    const eNnome = document.getElementById('SelectSetor')
    const nome = Number(eNnome.value);
    //cl("validaSelectServValue",nome);
    //cl("validaSelectServIndex",eNnome.selectedIndex);
    //cl("validaSelectServ",nome);
    //global.cadnome = nome;

    if (nome > 0) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputSelectSetor = true;
      //eNnome.disabled = true;
    } else {
      global.inpAddFrmC.inputSelectSetor = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (global.gValidaInit === 1) {
        //eNnome.classList.remove("is-invalid")
        //window.alert("gValidaInit")
        return
      }
      if ((params === 1) && (global.gValidaInit !== 1)) { eNnome.focus() }
      return ('pare');

    }

  }

  function validaSelectTempo(params) {
    const eNnome = document.getElementById('SelectTempo')
    const nome = Number(eNnome.value);
    //cl("validaSelectServValue",nome);
    //cl("validaSelectServIndex",eNnome.selectedIndex);
    //cl("validaSelectServ",nome);
    //global.cadnome = nome;

    if (nome >= 0) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputSelectTempo = true;
      //eNnome.disabled = true;
    } else {
      global.inpAddFrmC.inputSelectTempo = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (global.gValidaInit === 1) {
        //eNnome.classList.remove("is-invalid")
        //window.alert("gValidaInit")
        return
      }
      if ((params === 1) && (global.gValidaInit !== 1)) { eNnome.focus() }
      return ('pare');

    }
    let einputPedido = document.getElementById('inputPedido');
    einputPedido.focus();

  }

  function validaSelectServ(params) {
    const eNnome = document.getElementById('SelectServ')
    const nome = eNnome.value;
    //cl("validaSelectServValue",nome);
    //cl("validaSelectServIndex",eNnome.selectedIndex);
    //cl("validaSelectServ",nome);
    //global.cadnome = nome;

    if (nome > 0) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputSelectServ = true;
      //eNnome.disabled = true;
    } else {
      global.inpAddFrmC.inputSelectServ = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (global.gValidaInit === 1) {
        eNnome.classList.remove("is-invalid")
        //window.alert("gValidaInit")
        return
      }
      if ((params === 1) && (global.gValidaInit !== 1)) { eNnome.focus() }
      return ('pare');

    }
    global.gValidaInit = 0;
  }

  function validaSelectMedium(params) {
    const eNnome = document.getElementById('SelectMedium')
    const nome = eNnome.value;
    const eNnome2 = document.getElementById('SelectServ')
    //cl("validaSelectMedium",nome);
    //global.cadnome = nome;
    if (nome > 0) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputSelectMedium = true;
      // eNnome.disabled = true;
      // eNnome2.disabled = true;
    } else {
      global.inpAddFrmC.inputSelectMedium = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
      return ('pare');

    }
  }

  function validaSelectHorario(params) {
    const eNnome = document.getElementById('SelectHorario')
    if (eNnome.selectedIndex > 0) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputSelectHorario = true;
    } else {
      global.inpAddFrmC.inputSelectHorario = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
      return ('pare');

    }
  }
  function selectSetorOk(params) {
    let eSelectSetor = Number(document.getElementById('SelectSetor').value);
    let espanSetorVlr = document.getElementById('spanSetorVlr');

    validaSelectSetor();

    if (eSelectSetor === 0) {
      espanSetorVlr.innerText = '';
      return
    }

    var SetorFilter = global.Setor.filter(function (i, num) {
      return i.id === eSelectSetor;
    });

    //cl("clSetorFilter",SetorFilter);
    if (SetorFilter.length > 0) {
      //cl("clSetorFilter[0].valorcli",SetorFilter[0].valorcli);


      if (SetorFilter[0].valorcli === '0,00') {

        espanSetorVlr.innerText = 'R$ ' + SetorFilter[0].valor;
      } else {

        espanSetorVlr.innerText = 'R$ ' + SetorFilter[0].valorcli;
      }

    }



  }

  function selectServOk(params) {



    global.eSelectServ = 'ENTREGA';
    global.eSelectServValor = 1;
    let ebtnEntrega = document.getElementById('btnEntrega');
    ebtnEntrega.style.display = 'none';


    global.gAtuOff = 1;
    let ecardOcorrencias = document.getElementById('cardOcorrenciasCli');
    ecardOcorrencias.style.display = 'none';
    let ecardOcorrenciasfechadas = document.getElementById('cardOcorrenciasfechadas');
    ecardOcorrenciasfechadas.style.display = 'none';
    let ecardOcorrenciasgrid = document.getElementById('cardOcorrenciasgridCli');
    ecardOcorrenciasgrid.style.display = 'none';
    let edivdadosSol = document.getElementById('divdadosSol');
    edivdadosSol.style.display = '';


    let edivPross = document.getElementById('divPross');
    edivPross.style.display = '';

    //let eSelectServ = document.getElementById('SelectServ');

    let einputEndColeta = document.getElementById('inputEndColeta');
    let einputEndEntrega = document.getElementById('inputEndEntrega');
    let edivNomeZap = document.getElementById('divNomeZap');
    let einputNome = document.getElementById('inputNome');

    const input = document.querySelectorAll("input");
    setsearchString('a') //forçar troca sempre
    setsearchString('')
    setServicosSelected();
    //setsearchServicos('a');
    setsearchServicos(null);
    input[0].value = '';


    //cl("selectServOk")
    //cl("a",eSelectServ.options[eSelectServ.selectedIndex].innerHTML)

    //serv = eSelectServ.options[eSelectServ.selectedIndex].innerHTML;
    let serv = global.eSelectServ;
    if (serv === "ENTREGA") {
      einputEndColeta.value = global.gEnd + ',' + global.gNum + ' - ' + global.gCompl;
      einputEndEntrega.value = '';
      edivNomeZap.value = '';
      einputNome.value = '';
      edivNomeZap.style.display = '';
      einputNome.focus();
      input[0].focus();
      //einputEndEntrega.focus();


    }
    if (serv === "RETIRADA") {
      einputEndEntrega.value = global.gEnd + ',' + global.gNum + ' - ' + global.gCompl;
      einputEndColeta.value = '';
      edivNomeZap.value = '';
      einputNome.value = '';
      edivNomeZap.style.display = 'none';
      einputEndColeta.focus();

    }
    if (serv === "VIAGEM") {
      einputEndColeta.value = global.gEnd + ',' + global.gNum + ' - ' + global.gCompl;
      einputEndEntrega.value = '';
      edivNomeZap.value = '';
      einputNome.value = '';
      edivNomeZap.style.display = 'none';
      einputEndEntrega.focus();

    }

    //validaSelectServ();





    edivPross.style.display = 'none';

  }

  function selectMediumOk(params) {
    let edivPross = document.getElementById('divPross');
    edivPross.style.display = '';
    if (validaSelectMedium(1) == 'pare') {
      edivPross.style.display = 'none';
      CloseEditReset();
      return
    };


    let ignore = false;
    let eSelectMedium = document.getElementById('SelectMedium');
    let idProf = eSelectMedium.value;
    //let eSelectServ = document.getElementById('SelectServ');
    let idGira = global.eSelectServValor;
    let arrtransf = global.Serv;
    var arrFound = arrtransf.filter(function (item, num) {
      return item.id === Number(idGira);
    });
    //cl("arrFound",arrFound);

    let start = arrFound[0]['start'];
    let end = arrFound[0]['end'];
    let divAtendMin = arrFound[0]['divAtendMin'];
    //cl("idProf",idProf);
    //cl("idGira",idGira);
    //cl("start",start);
    //cl("end",end);
    //cl("divAtendMin",divAtendMin);

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('idGira', idGira);
      bodyFormData.set('idProf', idProf);
      bodyFormData.set('start', start);
      bodyFormData.set('end', end);
      bodyFormData.set('divAtendMin', divAtendMin);

      //cl("bodyFormData",bodyFormData);
      const urlfull = 'https://api.youevolution.com.br/listaHoraMedium.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //setrepCliout(response);
      //setAtu({ '0': '0' });
      if (!ignore) {
        let k;
        let eSelectHorario = document.getElementById('SelectHorario');
        if (eSelectHorario) {
          removeOptions(document.getElementById('SelectHorario'));
          let opt = document.createElement('option');
          opt.value = 0;
          opt.innerHTML = 'Selecione...';
          eSelectHorario.appendChild(opt);
          for (k = 0; k < response['data'].length; k++) {
            let opt = document.createElement('option');
            opt.value = response['data'][k]['hora'];
            opt.innerHTML = response['data'][k]['hora'];
            eSelectHorario.appendChild(opt);

          }
          edivPross.style.display = 'none';
          eSelectHorario.focus();
        }

      }

    }

    fetchdata();

  }

  const ref = null;


  const columns = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    { title: 'Id', field: 'id', resizable: false, hozAlign: 'center', width: 50, visible: true, responsive: 0 },
    { title: 'Data', field: 'start', resizable: false, width: 114, responsive: 0, formatter: formatDataHora },
    //{ title: 'Data', field: 'start' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Tipo', field: 'servicos', resizable: false, hozAlign: "left", width: 80, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
    { title: 'Profissional', field: 'resourceTitle', resizable: false, hozAlign: "center", width: 90, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc", formatter: formatProf },
    { title: 'Status', field: 'status', resizable: false, hozAlign: "center", width: 98, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc", formatter: formatStatus },
    { title: 'Para', field: 'nomeentrega', resizable: true, hozAlign: "left", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
    { title: 'Entrega', field: 'entrega', resizable: true, hozAlign: "left", width: 150, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
    { title: 'Setor', field: 'setor', resizable: true, hozAlign: "left", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
    { title: 'R$', field: 'valor', resizable: true, hozAlign: "left", width: 60, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" },
    /* { title: 'Coleta', field: 'coleta', resizable: true, hozAlign: "left", width: 150, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "asc" }, */
    /*     { title: 'Grupo', field: 'grupo', hozAlign: "left", width: 200, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
        { title: 'Valor R$', field: 'valor', hozAlign: "right", width: 100, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
        {
          title: 'Profis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
    
            let textoAjustado = cell.getValue();
    
            return textoAjustado + " %";
          }
        },
        {
          title: 'Tempo Médio', field: 'tempomedio', hozAlign: "center", width: 130, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {
    
            let textoAjustado = cell.getValue();
    
            return textoAjustado + " h";
          }
        },
     */    //{ title: 'Sindico', field: 'sindico', align: "center", width: 150, responsive: 0, headerSort: false },
    /*     {
      title: 'NF Data', field: 'nfe_data', align: 'center', width: 66, responsive: 0, formatter: "datetime", formatterParams: {
        inputFormat: "YYYY-MM-DD",
        outputFormat: "DD/MM/YY",
        invalidPlaceholder: "",
      }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
      }, */
    //{ title: 'Cidade', field: 'cidade', hozAlign: "left", width: 100, responsive: 0, },
    //{ title: 'Valor Parcelas', field: 'vlr_parc', align: "center", width: 150, responsive: 0, headerSort: false },
    //{ title: 'Prazo', field: 'qtdDias', align: "center", width: 80, responsive: 0, headerSort: false },
    //{ title: 'Editar', formatter: editIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) {cl(cell.getRow().getData().prop, cell.getRow().getData().nome, cell.getRow().getData().tipo, cell.getRow().getData().sindico, cell.getRow().getData().eleito, cell.getRow().getData().rg, cell.getRow().getData().cpf, cell.getRow().getData().profiss, cell.getRow().getData().estadocivil, cell.getRow().getData().nomet1, cell.getRow().getData().rgt1, cell.getRow().getData().nomet2, cell.getRow().getData().rgt2) } },
    //{ title: 'Editar', formatter: editIcon, width: 50, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { editCadastro(cell.getRow().getData().id) } },
    //{ title: 'Gerar', formatter: wordIcon, width: 60, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { geraWordContr(cell.getRow().getData().numcontr) } },
    /* { formatter: iconLixeira, width: 62, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().id, cell.getRow().getData().gira) } }, */
    //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { gGlobal.gContrato = cell.getRow().getData().prop; gerContratoLink(); } },

    // { title: 'Nome', field: 'nameC', width: 150, responsive: 0, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    // { title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
    // { title: 'Cidade/Estado', field: 'cidstate', align: 'center', width: 120, responsive: 0 },
    // { title: 'Fone', field: 'fone', align: 'center', width: 100, responsive: 0 },
    // { title: 'Coleta', field: 'origem', align: 'center', headerFilter: "input", width: 100, responsive: 0 },
    //{ title: 'Age', field: 'age', align: 'left', formatter: 'progress' },
    //{ title: 'Favourite Color', field: 'color' },
    //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
    //{ title: 'Rating', field: 'rating', align: 'center', formatter: 'star' },
    // { title: 'Link', field: 'passed', headerSort: false, align: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
    //{ title: 'Info', field: 'msginfo', align: "center", width: 80, headerSort: false, formatter: infoIcon, responsive: 0, cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
    //{ title: 'Q?', field: 'resp', headerSort: false, align: "center", formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
    //{ title: '@', field: 'passed', width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave) } },
    //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().name, cell.getRow().getData().fone) } },
    //{ title: 'Del', formatter: iconLixeira, width: 25, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { if (cell.getRow().getData().progress < 11){ confirmDel(cell.getRow().getData().nameC, cell.getRow().getData().chave) }} },
    // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    // { title: 'Custom', field: 'custom', align: 'center', formatter: reactFormatter(<SimpleButton />) },
    //{ title: 'Custom', field: 'custom', align: 'center' },
  ];

  /*   const columnsProp = [
      //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
      //{ title: 'Id', field: 'id', align: 'center', width: 40, visible: false, responsive: 0 },
      // {
      //  title: 'Data', field: 'createdAt', align: 'center', width: 80, responsive: 0, formatter: "datetime", formatterParams: {
      //    inputFormat: "YYYY-MM-DD",
      //   outputFormat: "DD/MM/YY",
      //   invalidPlaceholder: "(invalid date)",
      // }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
      // },
      // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
      { title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
      { title: 'Tipo', field: 'tipo', align: "center", width: 200, responsive: 0, },
      { title: 'Numero', field: 'prop', align: "center", width: 150, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Valor Total', field: 'vlr_total', align: "center", width: 150, responsive: 0, headerSort: false },
      { title: 'Parcelas', field: 'parc', align: "center", width: 80, responsive: 0, headerSort: false },
      { title: 'Valor Parcelas', field: 'vlr_parc', align: "center", width: 150, responsive: 0, headerSort: false },
      { title: 'Prazo', field: 'qtdDias', align: "center", width: 80, responsive: 0, headerSort: false },
      //{ title: 'Download', formatter: wordIcon, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { geraWordProp(cell.getRow().getData().chave, cell.getRow().getData().prop) } },
      //{ title: 'Deletar', formatter: iconLixeira, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().prop, cell.getRow().getData().tipo, cell.getRow().getData().vlr_total, cell.getRow().getData().chave) } },
      //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert('Finalizando o desenvolvimento...') } },
      //{ title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
      //{ title: 'Cidade/Estado', field: 'cidstate', align: 'center', width: 120, responsive: 0 },
      //{ title: 'Fone', field: 'fone', align: 'center', width: 130, responsive: 0 },
      //{ title: 'Info', field: 'msginfo', cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
      //{ title: 'Coleta', field: 'origem', align: 'center', headerFilter: "input", width: 100, responsive: 0 },
      //{ title: 'Age', field: 'age', align: 'left', formatter: 'progress' },
      //{ title: 'Favourite Color', field: 'color' },
      //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
      //{ title: 'Rating', field: 'rating', align: 'center', formatter: 'star' },
      // { title: 'Link', field: 'passed', headerSort: false, align: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
      //{ title: 'Q?', field: 'resp', headerSort: false, formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
      //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().nameC, cell.getRow().getData().fone) } },
      // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
      // { title: 'Custom', field: 'custom', align: 'center', formatter: reactFormatter(<SimpleButton />) },
      //{ title: 'Custom', field: 'custom', align: 'center' },
    ]; */

  const options = {
    //height: 215,
    movableRows: false,
    clipboard: true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    initialSort: [
      { column: "id", dir: "desc" }, //sort by this first
    ]
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  function Cadastro(params) {

    global.gAtuCad = 1;

    let setid = 0;
    //cl("global.gLog",global.gLog);
    //cl("global.gLog.id",global.gLog['id']);


    if (global.gLog['id'] > 0) { setid = global.gLog['id'] };




    setAtuCad(
      {
        'id': setid,
        'descricao': document.getElementById('inputNome').value,
        'grupo': document.getElementById('inputGrupo').value,
        'valor': document.getElementById('inputValor').value,
        'tempomedio': document.getElementById('inputTempoMedio').value,
        'porc': document.getElementById('inputPorc').value,
      });

    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';




  }

  function GravarAgenda(params) {
    let edivPross = document.getElementById('divPross');
    edivPross.style.display = '';

    let ignore = false;

    //let eSelectServ = document.getElementById('SelectServ');
    let einputEndEntrega = document.getElementById('inputEndEntrega');
    let einputEndColeta = document.getElementById('inputEndColeta');
    //let einputFone = document.getElementById('inputFone');
    let einputFone = document.getElementById('inputFone').value.replace(/_|-|\(|\)| /g, '');
    let einputNome = document.getElementById('inputNome');
    let eSelectSetor = document.getElementById('SelectSetor');
    let einputPedido = document.getElementById('inputPedido');
    let eSelectTempo = document.getElementById('SelectTempo');
    let espanSetorVlr = document.getElementById('spanSetorVlr');

    //let eSelectHorario = document.getElementById('SelectHorario');
    //let horIn = eSelectHorario.children[eSelectHorario.selectedIndex].textContent
    //cl("horIns", horIn);

    //cl("eSelectServValue", eSelectServ.value);
    //cl("global.Serv", global.Serv);

    /*     let GiraFilter2 = global.Serv.filter(function (item, num) {
          return item.id === Number(eSelectServ.value);
        }); */
    /*     let ServFilter = global.Serv.filter(function (item, num) {
          return item.id === Number(global.eSelectServValor);
        }); */

    var SetorFilter = global.Setor.filter(function (i, num) {
      return i.id === Number(eSelectSetor.value);
    });

    let valorserv = 0;

    //cl("clSetorFilter",SetorFilter);
    if (SetorFilter.length > 0) {
      //cl("clSetorFilter[0].valorcli",SetorFilter[0].valorcli);


      if (SetorFilter[0].valorcli === '0,00') {

        valorserv = SetorFilter[0].valor;
      } else {

        valorserv = SetorFilter[0].valorcli;
      }

    }
    //cl("GiraFilter", GiraFilter2);
    //let startAgendam = GiraFilter2[0]['start'].substr(0, 11) + horIn + ':00'
    let startAgendam = moment().format('YYYY-MM-DD HH:mm:ss');
    //cl("startAgendam", startAgendam);

    //let serv = ServFilter[0]['descricao'];
    //let tempomedio = ServFilter[0]['tempomedio'];
    let tempomedio = '00:30';
    let endAgendam = moment().add(tempomedio, 'hours').format('YYYY-MM-DD HH:mm:ss');
    //cl("endAgendam", endAgendam);

    //let eSelectMedium = document.getElementById('SelectMedium');
    //let idprof = eSelectMedium.value;
    //return;

    let jservval = '[{"id":' + global.eSelectServValor + ',"value":1,"label":"' + global.eSelectServ + ' - ' + tempomedio + 'h","porc":"100,00","valor":"' + valorserv + '"}]'
    let entrega = einputEndEntrega.value;
    let coleta = einputEndColeta.value;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEssdkgfsdjkgf2323');
      bodyFormData.set('idcli', global.gIdCliFromUsu);
      bodyFormData.set('idprof', 1);
      bodyFormData.set('start', startAgendam);
      bodyFormData.set('end', endAgendam);
      bodyFormData.set('servicos', global.eSelectServ);
      bodyFormData.set('status', 'inicial');
      bodyFormData.set('user', global.gIdUser);
      bodyFormData.set('usercreated', global.gIdUser);
      bodyFormData.set('jservicos', jservval);
      bodyFormData.set('foneEntrega', einputFone);
      bodyFormData.set('nomeEntrega', einputNome.value);
      bodyFormData.set('endEntrega', entrega);
      bodyFormData.set('idsetor', Number(eSelectSetor.value));
      bodyFormData.set('setor', eSelectSetor.options[eSelectSetor.selectedIndex].innerText);
      bodyFormData.set('pedido', einputPedido.value);
      bodyFormData.set('tempdisp', eSelectTempo.value);
      bodyFormData.set('endColeta', coleta);
      const response = await axios({
        method: 'post',

        url: 'https://api.youevolution.com.br/AgendaReservaTU.php',
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response erro=", response['data']);
      if (response['data'] === 'existe') {
        //cl("response existe?=", response['data']);
        alert('ATENÇÃO: Não realizado, já existe uma solicitação identica, atualize a pagina e tente novamente.');
        CloseEditReset();

      } else if (response['data'] === 'jareservado') {
        //cl("response jareservado?=", response['data']);
        alert('ATENÇÃO: Não realizado, o horário já foi reservado, é possível que outra pessoa reservou enquanto você confirmava, selecione outro horário e tente novamente !');


      } else if (response['data'] === undefined) {
        //cl("response jareservado?=", response['data']);
        alert('ATENÇÃO: Não realizado, erro de conexão por favor tente novamente !');


      } else if (response['data']['id'] > 0) {
        //cl("response jareservado?=", response['data']);
        //cl("response ?=", response['data']);
        let msg = 'AGENDA CONFIRMADA : Protocolo n° 2023/' + response['data']['id'];
        //alert(msg);
        CloseEditReset();
      } else {

        alert('ATENÇÃO: Não realizado, algo deu errado, por favor tente novamente !');

      }

      edivPross.style.display = 'none';


      setAtu({ '0': '0' });


    };

    fetchdata();
    let edivdadosSol = document.getElementById('divdadosSol');
    edivdadosSol.style.display = 'none';
    let ebtnEntrega = document.getElementById('btnEntrega');
    ebtnEntrega.style.display = '';
    global.gAtuOff = 0;
    global.gInterval = 0;

  }

  function nl2br(str, is_xhtml) {
    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br>' : '<br>'; // Adjust comment to avoid issue on phpjs.org display

    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
  }

  function confirmGravarAgenda(params) {
    if (!global.inpAddFrmC.inputFone) {
      validaFone(1)
      return
    }
    if (!global.inpAddFrmC.inputNome) {
      validaNome(1)
      return
    }
    if (!global.inpAddFrmC.inputEndEntrega) {
      validaEndentrega(1)
      return
    }
    if (!global.inpAddFrmC.inputSelectSetor) {
      validaSelectSetor(1)
      return
    }
    if (!global.inpAddFrmC.inputPedido) {
      validaPedido(1)
      return
    }
    if (!global.inpAddFrmC.inputSelectTempo) {
      validaSelectTempo(1)
      return
    }
    /*     if (!global.inpAddFrmC.inputSelectMedium) {
          validaSelectMedium()
          return
        }
        if (!global.inpAddFrmC.inputSelectHorario) {
          validaSelectHorario()
          return
        } */

    //let eSelectServ = document.getElementById('SelectServ');
    let einputFone = document.getElementById('inputFone');
    let einputNome = document.getElementById('inputNome');
    let einputEndEntrega = document.getElementById('inputEndEntrega');
    let eSelectSetor = document.getElementById('SelectSetor');
    let einputPedido = document.getElementById('inputPedido');
    let eSelectTempo = document.getElementById('SelectTempo');
    let espanSetorVlr = document.getElementById('spanSetorVlr');
    let einputEndColeta = document.getElementById('inputEndColeta');





    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <p id='pmodaltualertatitulo'><b>Confirme a Solicitação ?</b></p>
            <p id='pmodaltu'>Para <b>{einputNome.value} {einputFone.value}</b></p>
            <p id='pmodaltu'>Disponível em : <b>{eSelectTempo.value} minutos</b></p>
            <p id='pmodaltu'>Endereço: <b>{einputEndEntrega.value}</b></p>
            <p id='pmodaltu'>Setor: <b>{eSelectSetor.options[eSelectSetor.selectedIndex].innerText}</b></p>
            <p id='pmodaltu'>Valor do Frete : {espanSetorVlr.innerText}</p>
            <textarea className="form-control textarea" disabled id="inputPedido" defaultValue={einputPedido.value} rows={6} />
            <p id='pmodaltu'></p>
            <p id='pmodaltu'>Coleta: <b>{einputEndColeta.value}</b></p>

            <br></br>
            <button className="btn bg-gradient-info btn-sm"
              onClick={() => {
                onClose();
                /*                 CloseEditReset();
                                let edivdadosSol = document.getElementById('divdadosSol');
                                edivdadosSol.style.display = 'none';
                                global.gAtuOff = 0;
                                global.gInterval = 0; */
              }}
            >
              Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                GravarAgenda();
                onClose();
              }}
            >
              Sim, Solicitar agora!
            </button>
          </div>
        );
      }
    });



  }


  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }

  function novoCadastro(params) {
    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Novo Cadastro ..."

    global.gLog = [];


    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;
    elenome.focus();


    let einputValor = document.getElementById('inputValor');
    einputValor.value = '';

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = '';

    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = '';

  }


  function editCadastro(params) {

    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Editar Serviço ..."

    var arrFound = rep.filter(function (item, num) {
      return item.id === params;
    });

    global.gLog = arrFound[0];
    //cl("global.gLog", global.gLog);
    let dtfinal = '';
    /*    if(arrFound[0].dtnasc){
          
          let dt = arrFound[0].dtnasc.split("/");
          dtfinal = ''+dt[2]+'-'+dt[1]+'-'+dt[0]+'';
        } else {
          dtfinal = '';
        } */

    //cl(dtfinal);

    let elenome = document.getElementById('inputNome');
    elenome.value = arrFound[0].descricao;
    //elenome.disabled = true;

    let einputValor = document.getElementById('inputValor');
    einputValor.value = arrFound[0].valor;

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = arrFound[0].grupo;

    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = parseFloat(arrFound[0].porc.replace(',', '.'));

    let einputTempoMedio = document.getElementById('inputTempoMedio');
    einputTempoMedio.value = arrFound[0].tempomedio;

    //validaFone();
    CalcSimule();


  }




  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  function CalcSimule(params) {

    /*     var iNf_valor = document.getElementById("inputNf_valor");
        let V_Nf_valor = realParaNumber(iNf_valor.value);
        if (V_Nf_valor) { iNf_valor.value = V_Nf_valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    var iVlr_pago = document.getElementById("inputValor");
    let V_pago = realParaNumber(iVlr_pago.value);
    if (V_pago) { iVlr_pago.value = V_pago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
    /* 
        var iDif = document.getElementById("inputDif");
        let V_dif = realParaNumber(iDif.value);
        if (V_dif) { iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     var iPcc = document.getElementById("inputPcc");
        let V_Pcc = realParaNumber(iPcc.value);
        if (V_Pcc) { iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     if ((iNf_valor.disabled === false) && (iVlr_pago.disabled === false) && (iDif.disabled === false)) {
          V_dif = V_pago - V_Nf_valor;
          if (V_dif > 0) {
            iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */
    /* 
        if ((iNf_valor.disabled === false) && (iPcc.disabled === false)) {
          V_Pcc = V_Nf_valor * 0.045;
          if (V_Pcc > 0) {
            iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */




  }

  function confirmDelProp(numprop, cliente, Coleta, Entrega) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h3>Deseja Cancelar a solicitação ?</h3><br></br>
            <p id='pmodal'><b>{numprop} - {cliente}</b></p>
            <p id='pmodal'><b>Coleta: {Coleta}</b></p>
            <p id='pmodal'><b>Entrega: {Entrega}</b></p>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                global.gEventoCancelar = 1;
                setEventoCancelar(
                  {
                    'id': numprop,
                    'user': global.gIdUser
                  }

                );
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }

  function visualizarProp(numprop, cliente, Coleta, Entrega, nomeEntrega, foneEntrega, motorista, data, tipo, sstatus, visSetor, visPedido, visTempdisp, visValor) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h3>Dados da solicitação</h3><br></br>
            <p id='pmodal2'>Id :<b> {numprop} - {tipo}</b></p>
            <p id='pmodal2'>Para<b> {nomeEntrega} {foneEntrega}</b></p>
            {/* <p id='pmodal2'>Cole<b>ta: {nomeEntrega} {foneEntrega} {Coleta}</b></p> */}
            <p id='pmodal2'>End.:<b> {Entrega}</b></p>
            <p id='pmodal2'>Setor:<b> {visSetor}</b></p>
            <p id='pmodal2'>Profissional:<b> {motorista}</b></p>
            <p id='pmodal2'>Valor do serviço:<b> R${visValor}</b></p>
            <p id='pmodal2'>Data:<b> {data}</b></p>
            <p id='pmodal2'>Preparo:<b> ⏱ {visTempdisp} minutos</b></p>
            <p id='pmodal2'>Pedido<b></b></p>
            <textarea className="form-control textarea" disabled id="inputPedido" defaultValue={visPedido} rows={6} cols={40} />
            <p id='pmodal2'>Status:<b> {sstatus}</b></p>

            <button className="btn bg-gradient-info btn-sm " onClick={onClose}>Ok</button>
          </div>
        );
      }
    });
  }

  function incluirObs(numprop, cliente, Coleta, Entrega, nomeEntrega, foneEntrega, motorista, data, tipo, sstatus, visSetor, visPedido, visTempdisp, visValor) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h3>Incluir Obeservção</h3><br></br>
            <p id='pmodal2'>Id :<b> {numprop} - {tipo}</b></p>
            <p id='pmodal2'>Para<b> {nomeEntrega} {foneEntrega}</b></p>
            {/* <p id='pmodal2'>Cole<b>ta: {nomeEntrega} {foneEntrega} {Coleta}</b></p> */}
            <p id='pmodal2'>Profissional:<b> {motorista}</b></p>
            <p id='pmodal2'>Pedido<b></b></p>
            <textarea className="form-control textarea" disabled id="inputPedidoORG" defaultValue={visPedido} rows={6} cols={40} />
            <p id='pmodal2'>Status:<b> {sstatus}</b></p>
            <p id='pmodal2'>Nova Observação<b></b></p>
            <textarea className="form-control textarea" id="inputPedidoObs" rows={6} cols={40} />
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Cancelar</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                global.setNovaObs = 1;
               
                setNovaObs(
                  {
                    'id': numprop,
                    'nome' : abreviar(global.gNome),
                    'obs': document.getElementById('inputPedidoORG').value,
                    'novaobs': document.getElementById('inputPedidoObs').value,
                    'user' : global.gIdUser
                  }

                );
                onClose();
              }}
            >
              Enviar Observação
            </button>
          </div>
        );
      }
    });
  }

  function dataTable(params) {

    return (
      <>
        <div className="card card-info cardGridBgg" id="cardOcorrenciasCli" style={{ display: 'none' }}>
          <div className="card-header ui-sortable-handle" >
            <h3 className="card-title locCont">Solicitações em aberto</h3>

            <div className="card-tools locCont">
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={null} style={{ margin: '-3px 20px' }}>Novo Contato</button> */}
              <Hotkeys
                keyName="shift+c"
                onKeyDown={novoCadastro}
              //onKeyUp={novoContato}
              />
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={novoCadastro} style={{ margin: '-3px 20px' }}>Agendamento On-line &#40;Shift+C&#41;</button> */}
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggI" />
              </button>

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={enviarEmail} style={{ margin: '-3px 20px' }}>Email</button> */}

            </div>
          </div>
          <div className="card-body locCont">
            <div className="container-fluid" id="divfichas">
            </div>
          </div>
        </div>
        <div className="card card-success cardGridBgg" id="cardOcorrenciasfechadas" style={{ display: 'none' }}>
          <div className="card-header ui-sortable-handle" >
            <h3 className="card-title locCont">Solicitações Realizadas</h3>

            <div className="card-tools locCont">
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggIfechadas" />
              </button>

            </div>
          </div>
          <div className="card-body locCont">
            <div className="container-fluid" id="divfichasrealizadas">
            </div>
          </div>
        </div>
        <div className="card card-secondary cardGridBgg" id="cardOcorrenciasgridCli" style={{ display: 'none' }}>
          <div className="card-header ui-sortable-handle" >
            <h3 className="card-title locCont">Solicitações</h3>

            <div className="card-tools locCont">
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggIgrid" />
              </button>

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={enviarEmail} style={{ margin: '-3px 20px' }}>Email</button> */}

            </div>
          </div>
          <div className="card-body locCont" id="cardGridBggBodyCli">
            <ReactTabulator
              // ref={(ref)}
              columns={columns}
              data={rep}
              /*               rowClick={rowClick}
                            cellClick={rowClick} */
              options={options}
              events={{}}
            //data-custom-attr="test-custom-attribute"
            //className="#example-table-theme"
            />

            <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          </div>
        </div>
      </>
    )

  }



  function page1(params) {

    const handleOnSearch = (string, results) => {
      //console.log(results.length);
      /*       if (results.length === 0) {
              //global.idCliente = 0;
              global.newCliente = 1;
            } else {
      
              global.newCliente = 1;
            } */
    };

    const handleOnHover = (result) => {
      //console.log(result);
    };

    const handleOnSelect = (item) => {
      //console.log("selecionado", item);
      //global.newCliente = 0;
      //global.idCliente = item.id;
      //setsearchString(item.name);

      let inpfone = document.getElementById('inputFone');
      inpfone.value = item['fone'];
      //cl("clinpfone.value",inpfone.value);

      let einputNome = document.getElementById('inputNome');
      einputNome.value = item['name'];

      let einputEndEntrega = document.getElementById('inputEndEntrega');
      einputEndEntrega.value = item['entrega'];

      let eSelectSetor = document.getElementById('SelectSetor');
      eSelectSetor.value = item['idsetor'];

      if (!global.Setor) {
        setAtu({ '0': '0' });
        return
      }

      var SetorFilter = global.Setor.filter(function (i, num) {
        return i.id === item['idsetor'];
      });

      if (SetorFilter.length > 0) {
        //cl("clSetorFilter",SetorFilter);
        //cl("clSetorFilter[0].valorcli",SetorFilter[0].valorcli);

        let espanSetorVlr = document.getElementById('spanSetorVlr');

        if (SetorFilter[0].valorcli === '0,00') {

          espanSetorVlr.innerText = 'R$ ' + SetorFilter[0].valor;
        } else {

          espanSetorVlr.innerText = 'R$ ' + SetorFilter[0].valorcli;
        }

      }

      validaFone(1);
      validaNome(1);
      validaEndentrega(1);
      validaSelectSetor(1);
      validaSelectTempo(1);
      //validaPedido(1);


    };

    const handleOnFocus = () => {
      //console.log("Focused");
    };

    const handleOnClear = () => {
      //console.log("Cleared");
      let inpfone = document.getElementById('inputFone');
      inpfone.value = '';
      validaFone('inputFone')

      let inpemail = document.getElementById('inputEmail6');
      inpemail.value = '';
      //validaEmail6(inpemail.value)
    };

    const formatResult = (item) => {
      //console.log(item);
      function mtel(v) {
        v = v.replace(/\D/g, "");             //Remove tudo o que não é dígito
        v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
        v = v.replace(/(\d)(\d{4})$/, "$1-$2");    //Coloca hífen entre o quarto e o quinto dígitos
        return v;
      }

      let fonf = mtel(item.fone)

      return (
        <div className="result-wrapper">
          <img src="dist/img/user3-160x160.jpg" className="user-image-bgg" alt="User Image1"></img>
          <span className="result-span">{item.name}</span>
          <span className="result-span"> - </span>
          <span className="result-span">{fonf}</span>
          <span className="result-span"> - </span>
          <span className="result-span">{item.entrega}</span>
        </div>
      );
    };
    return (
      <div id="panelActions" name="panelActions" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-secondary cardAddBgg" >
                <div id="divPross" className="divimgloadingProcess" style={{ display: 'none' }}>
                  <img id="imgloadingProcess" style={{ display: '' }} alt="Loading.." src={Loading2}></img>
                </div>
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Selecione o Serviço</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewHC">
                    <Hotkeys
                      keyName="f4"
                      onKeyDown={e => selectServOk()}
                    //onKeyUp={novoContato}
                    />
                    <div className="form-row">
                      {/*                       <div className="form-group col-md-12">
                        <label htmlFor="lNome">Selecione o Serviço que deseja ?</label>
                        <select className="form-control select2 select2-hidden-accessible" id="SelectServ" onChange={() => selectServOk()} data-select2-id={1} tabIndex={-1} aria-hidden="true">
                        </select>
                      </div> */}
                      <button type="button" className="btn btn-primary  " id="btnEntrega" onClick={e => selectServOk()}>Nova Entrega (F4)</button>
                    </div>
                    <div id="divdadosSol" style={{ display: 'none' }}>

                      <div id="divDadosEntrega" >
                        <div id="divNomeZap" style={{ display: 'none' }}>
                          <div className="form-group " autoComplete="off">
                            <label htmlFor="labelEmail5">Pesquisar Cliente por parte do Nome ou Fone</label>
                            <ReactSearchAutocomplete
                              items={Clientes}
                              fuseOptions={{ threshold: 0.1, caseSensitive: false, keys: ["name", "fone"] }}
                              resultStringKeyName="name"
                              onSearch={handleOnSearch}
                              onHover={handleOnHover}
                              onSelect={handleOnSelect}
                              onFocus={handleOnFocus}
                              onClear={handleOnClear}
                              inputSearchString={searchString}
                              styling={{
                                height: '36px',
                                borderRadius: '0.25rem',
                                zIndex: 4
                              }} // To display it on top of the search box below
                              autoFocus
                              placeholder={'Nome...'}
                              formatResult={formatResult}
                            />
                          </div>
                          <div className="form-row">
                            <div className="form-group col-md-3" >
                              <label htmlFor="lFone" id="Lzap"><i className="fa fa-whatsapp" id="iconzap" aria-hidden="true"></i> Zap Cliente (com DDD) </label>
                              <div className="input-group-prepend">
                                <input type="text" className="form-control" onChange={e => validaFone(e.target.value)} placeholder="Ex: 64 99901-XXXX" id="inputFone" />
                              </div>
                            </div>
                            <div className="form-group col-md-9">
                              <label htmlFor="lNome">Nome - Entregar para:</label>
                              <input type="text" className="form-control" onChange={e => validaNome(e.target.value)} id="inputNome" placeholder="Nome" />
                            </div>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-8">
                            <label htmlFor="lNome">Endereço de Entrega (Rua, Av, Numero, Ap...)</label>
                            <input type="text" className="form-control" onChange={e => validaEndentrega(e.target.value)} id="inputEndEntrega" />
                          </div>
                          <div className="form-group col-md-4">
                            <div className="float-right d-sm-inline-block" id="byuser">
                              <span id="spanSetorVlr">
                                R$ 0,00
                              </span>
                            </div>
                            <label htmlFor="lNome">Setor (Bairro,Ref)</label>
                            <select className="form-control select2 select2-hidden-accessible" id="SelectSetor" onChange={() => selectSetorOk()} data-select2-id={1} aria-hidden="true"></select>
                          </div>

                        </div>
                        <div className="form-row">
                        <div className="form-group col-md-3">
                            <label htmlFor="lNome">⏱Tempo Disponibilidade</label>
                            <select className="form-control select2 select2-hidden-accessible" id="SelectTempo" onChange={() => validaSelectTempo()} data-select2-id={1} aria-hidden="true">
                              <option value="-1">Selecione...</option>
                              <option value="0">Liberado</option>
                              <option value="5">5 minutos</option>
                              <option value="10">10 minutos</option>
                              <option value="15">15 minutos</option>
                              <option value="20">20 minutos</option>
                              <option value="25">25 minutos</option>
                              <option value="30">30 minutos</option>
                              <option value="35">35 minutos</option>
                              <option value="40">40 minutos</option>
                              <option value="45">45 minutos</option>
                              <option value="50">50 minutos</option>
                              <option value="55">55 minutos</option>
                              <option value="60">60 minutos</option>
                            </select>
                          </div>
                          <div className="form-group col-md-9">
                            <label htmlFor="lNome">Detalhes da Entrega (Pedido, Pagamento, Pacote..)</label>
                            <textarea className="form-control textarea" id="inputPedido" rows={3} onChange={() => validaPedido()} />
                          </div>
                        </div>
                      </div>
                      <div className="form-group" hidden>
                        <label htmlFor="lNome">Coleta ou Partida (Detalhes, Endereço,...)</label>
                        <input type="text" className="form-control" id="inputEndColeta" />
                      </div>

                      <button type="button" className="btn btn-info " id="btnGravar" onClick={e => confirmGravarAgenda()}>Solicitar</button>
                      <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={e => CloseEditReset()}>Cancelar</button>
                    </div>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function page0(params) {
    return (
      <div id="panelListAgenda" name="panelListAgenda" style={{ position: 'relative', display: '' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-success cardAddBgg" >
                <div id="divPross0" className="divimgloadingProcess" style={{ display: 'none' }}>
                  <img id="imgloadingProcess" style={{ display: '' }} alt="Loading.." src={Loading2}></img>
                </div>
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Seus Agendamentos</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewHC">
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <p id="plistagenda">❌ Nenhum agendamento encontrado.</p>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }


  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      global.inpAddFrmC.inputNome = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaPedido(params) {
    const eNnome = document.getElementById('inputPedido')
    const nome = eNnome.value;

    //cl("clnome",nome);
    if (nome.length > 5) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputPedido = true;
    } else {
      global.inpAddFrmC.inputPedido = false;
      eNnome.classList.remove("is-valid")
      eNnome.classList.add("is-invalid")
      if (params === 1) {
        let eSelectTempo = document.getElementById('inputPedido');
        eSelectTempo.focus();
        eNnome.value = eNnome.value.trim();
        //eNnome.focus();
      }
    }
    //eNnome.focus()
    //eNnome.value = params.toLowerCase();
  }
  function validaEndentrega(params) {
    const eNnome = document.getElementById('inputEndEntrega')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputEndEntrega = true;
    } else {
      global.inpAddFrmC.inputEndEntrega = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCidade')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }

  function validaFoneOLD(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      //global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("(99) 99999-9999");
    im.mask(eNnome);

  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    global.cadfone = nomep;
    const nome = nomep.replace(/ |\(|\)|_|-/g, '');
    //cl("fone",nome)
    if (nome.length > 10) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
      let einputNome = document.getElementById('inputNome');
      einputNome.focus();
    } else {
      global.inpAddFrmC.inputFone = false;
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("(99) 99999-9999");
    im.mask(eNnome);
  }

  function formataFone(params) {
    const nome = params.replace(/_|-/g, '');
    ////cl(nome)
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    let result = im.mask(params);
    return result;

  }


  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function CloseEditContr(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;

    let einputValor = document.getElementById('inputValor');
    einputValor.value = '';

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = '';

    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = '';

  }

  function resetForm(params) {

    global.inpAddFrmC.inputFone = false;
    global.inpAddFrmC.inputNome = false;
    global.inpAddFrmC.inputEndEntrega = false;
    global.inpAddFrmC.inputSelectSetor = false;
    global.inpAddFrmC.inputPedido = false;
    global.inpAddFrmC.inputSelectTempo = false;

    let einputFone = document.getElementById('inputFone');
    einputFone.value = '';
    einputFone.classList.remove("is-valid")
    einputFone.classList.remove("is-invalid")

    let einputNome = document.getElementById('inputNome');
    einputNome.value = '';
    einputNome.classList.remove("is-valid")
    einputNome.classList.remove("is-invalid")

    let einputEndEntrega = document.getElementById('inputEndEntrega');
    einputEndEntrega.value = '';
    einputEndEntrega.classList.remove("is-valid")
    einputEndEntrega.classList.remove("is-invalid")

    let eSelectSetor = document.getElementById('SelectSetor');
    eSelectSetor.innerText = '';
    eSelectSetor.classList.remove("is-valid")
    eSelectSetor.classList.remove("is-invalid")

    let einputPedido = document.getElementById('inputPedido');
    einputPedido.value = '';
    einputPedido.classList.remove("is-valid")
    einputPedido.classList.remove("is-invalid")

    let eSelectTempo = document.getElementById('SelectTempo');
    eSelectTempo.value = -1;
    eSelectTempo.classList.remove("is-valid")
    eSelectTempo.classList.remove("is-invalid")

    let espanSetorVlr = document.getElementById('spanSetorVlr');
    espanSetorVlr.innerText = '';


  }

  function CloseEditReset(params) {
    let edivPross = document.getElementById('divPross');
    if (edivPross) { edivPross.style.display = '' };
    resetForm();


    //let eSelectServ = document.getElementById('SelectServ');
    //let eSelectMedium = document.getElementById('SelectMedium');
    //let eSelectHorario = document.getElementById('SelectHorario');

    //if (eSelectServ) { removeOptions(eSelectServ) };

    //if (eSelectMedium) { removeOptions(eSelectMedium) };
    //if (eSelectHorario) { removeOptions(eSelectHorario) };
    global.gValidaInit = 1;
    //validaSelectServ();
    //validaSelectMedium();
    //validaSelectHorario();
    setAtu({ '0': '0' });
    let edivdadosSol = document.getElementById('divdadosSol');
    edivdadosSol.style.display = 'none';
    global.gAtuOff = 0;
    let ebtnEntrega = document.getElementById('btnEntrega');
    ebtnEntrega.style.display = '';
    ebtnEntrega.focus();
    //    eSelectServ.focus();

  }

  if (repCliout.length < 1) {
    //if (0 == 0) {
    return (
      <div className="content-wrapper" >
        <div className="container-login100-form-btn">
          <img id="imgloadingbg" width="110%" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30"></div>
      </div >
    )
  } else {
    return (
      <div className="content-wrapper">
        {/*      {page0()} */}
        {page1()}
        {dataTable()}


      </div>
    )

  }


}

export default Gerador   