import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import Loading from "./assets/loading4.gif";
import Logo from "./assets/login.png";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './clientes.css';
import './gerCotacoes.css';
import './SearchBar.css'
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link, animateScroll as scroll } from "react-scroll";
import Hotkeys from 'react-hot-keys';
import date from 'date-and-time';
import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType
} from "docx";
//import * as fs from "fs";
//const fs = require('fs')

var gGlobal = require('./components/globalvar.js');

const wordIcon = function (cell, formatterParams) { //plain text value
  return "<i class='far fa-file-word'></i>";
};
const editIcon = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-edit'></i>";
};
const iconContrato = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-file-download'></i>";
};
const iconAgendas = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-calendar'></i>";
};
const iconwhatsappweb = function (cell, formatterParams) { //plain text value
  return "<i class='fa fa-whatsapp'></i>";
}

const iconLixeira = function (cell, formatterParams) { //plain text value
  //if (cell.getRow().getData().progress < 11){
  return "<i class='fa fa-trash-o'></i>";
  //} else {
  //  return "-";
  //}
}

function formatAprovado(params) {
  return params;
  
}

global.gLog = [];

function rowClick(e, row) {
  ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
  //cl("rowClick id: " + row.getData().id, row, e);
  ////cl(rep);

};

function Gerador() {

  const [Atu, setAtu] = useState([]);
  const [rep, setrep] = useState([]);
  const [AtuHist, setAtuHist] = useState([]);
  const [repHist, setrepHist] = useState([]);
  const [repCliout, setrepCliout] = useState([]);
  const [AtuCad, setAtuCad] = useState([]);


  const [DocNow, setDocNow] = useState([]);

  const isFirstRunAtuCad = useRef(true);
  const isFirstRunAtuHist = useRef(true);



  useEffect(() => {


  }, [rep])

  useEffect(() => {


  }, [repHist])

  useEffect(() => {



  }, [DocNow])


  useEffect(() => {


  }, [repCliout])


  useEffect(() => {
    /*     if (isFirstRunDados.current) {
          isFirstRunDados.current = false;
          //cl("FirstRunDados false")
          return;
        } */
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('comp', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaCli.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      setrepCliout(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setrep(redata2);
        } else {
          setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [Atu])

  useEffect(() => {
    if (isFirstRunAtuHist.current) {
      isFirstRunAtuHist.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    const { idcli } = AtuHist;


    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('idcli', idcli);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaCliHist.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      setrepCliout(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setrepHist(redata2);
        } else {
          setrepHist(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
        let edivLoadHist = document.getElementById('divLoadHist');
        if (edivLoadHist) { edivLoadHist.style.display = 'none'; }
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuHist])

  useEffect(() => {
    if (isFirstRunAtuCad.current) {
      isFirstRunAtuCad.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', AtuCad.id);
      bodyFormData.set('name', AtuCad.name);
      bodyFormData.set('email', AtuCad.email);
      bodyFormData.set('aprovado', AtuCad.aprovado);
      bodyFormData.set('fone', AtuCad.fone);
      bodyFormData.set('dtnasc', AtuCad.dtnasc);
      bodyFormData.set('end', AtuCad.end);
      bodyFormData.set('num', AtuCad.num);
      bodyFormData.set('compl', AtuCad.compl);
      bodyFormData.set('cidade', AtuCad.cidade);
      bodyFormData.set('estado', AtuCad.estado);
      bodyFormData.set('cep', AtuCad.cep);
      bodyFormData.set('bairro', AtuCad.bairro);
      bodyFormData.set('obs', AtuCad.obs);
      bodyFormData.set('log', JSON.stringify(global.gLog));
      bodyFormData.set('user', global.gIdUser);

      const urlfull = 'https://api.youevolution.com.br/CliAtu.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //setrepCliout(response);
      setAtu({ '0': '0' });
      if (!ignore) {
        /*         const redata = [{}];
                const redata2 = response['data'];
                if (redata2) {
                  setrep(redata2);
                } else {
                  setrep(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    if (global.gAtuCad === 1) {

      fetchdata();
      global.gAtuCad = 0;

    } else { return };
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuCad])

  const ref = null;


  function visualizarHist(id, nome) {
    let eleLoading = document.getElementById('divLoad');
    if (eleLoading) { eleLoading.style.display = ''; };    
    let edivLoadHist = document.getElementById('divLoadHist');
    if (edivLoadHist) { edivLoadHist.style.display = ''; }
    let etituloHist = document.getElementById('tituloHist');
    etituloHist.innerHTML = nome + ' - Histórico de Serviços';
    let epanelHist = document.getElementById('panelHist');
    if(epanelHist){epanelHist.style.display = '';}
    setAtuHist({ idcli: id })
  }


  const columns = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    { title: 'Id', field: 'id', hozAlign: 'center', width: 60, responsive: 0,resizable: false, },
    { title: 'Aprovado', field: 'aprovado', hozAlign: 'center', width: 60, responsive: 0,resizable: false, formatter: function (cell, formatterParams, onRendered) 
    { let ap = cell.getValue();

      if (cell.getValue() === 'S') {
        ap = '<div class="aprovsim">'+ap+"</div>"
      } else {
        ap = '<div class="aprovnao">'+ap+"</div>"
      }
      
      return (ap)}

      
    },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Nome', field: 'name', hozAlign: "left", width: 150, responsive: 0, headerSort: true, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    //{ title: 'Email', field: 'email', hozAlign: "left", width: 140, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
/*     {
      title: 'Fone', field: 'fone', hozAlign: "left", width: 120, responsive: 0, headerSort: true, sorter: "string", headerFilter: "input", headerSortStartingDir: "asc", formatter: function (cell, formatterParams, onRendered) {

        let textoAjustado = cell.getValue();
        if (textoAjustado) {
          textoAjustado = textoAjustado.replace(/(\d{2})(\d{5})(\d{4})/,
            function (regex, arg1, arg2, arg3) {
              return '(' + arg1 + ') ' + arg2 + '-' + arg3;
            });
        }
        return textoAjustado;
      }
    }, */
/*     {
      title: 'Nasc', field: 'dtnasc', hozAlign: 'center', width: 100, responsive: 0, sorter: "datetime", headerSortStartingDir: "desc", headerSortTristate: true
    }, */
    //{ title: 'Obs', field: 'obs', hozAlign: "left", width: 180, responsive: 0, headerSort: true, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    //{ title: 'Sindico', field: 'sindico', align: "center", width: 150, responsive: 0, headerSort: false },
    /*     {
      title: 'NF Data', field: 'nfe_data', align: 'center', width: 66, responsive: 0, formatter: "datetime", formatterParams: {
        inputFormat: "YYYY-MM-DD",
        outputFormat: "DD/MM/YY",
        invalidPlaceholder: "",
      }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
      }, */
    //{ title: 'Cidade', field: 'cidade', hozAlign: "left", width: 100, responsive: 0, },
    //{ title: 'Valor Parcelas', field: 'vlr_parc', align: "center", width: 150, responsive: 0, headerSort: false },
    //{ title: 'Prazo', field: 'qtdDias', align: "center", width: 80, responsive: 0, headerSort: false },
    //{ title: 'Editar', formatter: editIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { cl(cell.getRow().getData().prop, cell.getRow().getData().nome, cell.getRow().getData().tipo, cell.getRow().getData().sindico, cell.getRow().getData().eleito, cell.getRow().getData().rg, cell.getRow().getData().cpf, cell.getRow().getData().profiss, cell.getRow().getData().estadocivil, cell.getRow().getData().nomet1, cell.getRow().getData().rgt1, cell.getRow().getData().nomet2, cell.getRow().getData().rgt2) } },
    //{ title: 'Gerar', formatter: wordIcon, width: 60, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { geraWordContr(cell.getRow().getData().numcontr) } },
    //{ title: 'Deletar', formatter: iconLixeira, width: 60, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().prop, cell.getRow().getData().nome) } },

    // { title: 'Nome', field: 'nameC', width: 150, responsive: 0, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    // { title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
    // { title: 'Cidade/Estado', field: 'cidstate', align: 'center', width: 120, responsive: 0 },
    // { title: 'Fone', field: 'fone', align: 'center', width: 100, responsive: 0 },
    // { title: 'Origem', field: 'origem', align: 'center', headerFilter: "input", width: 100, responsive: 0 },
    //{ title: 'Age', field: 'age', align: 'left', formatter: 'progress' },
    //{ title: 'Favourite Color', field: 'color' },
    //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
    //{ title: 'Rating', field: 'rating', align: 'center', formatter: 'star' },
    // { title: 'Link', field: 'passed', headerSort: false, align: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
    //{ title: 'Info', field: 'msginfo', align: "center", width: 80, headerSort: false, formatter: infoIcon, responsive: 0, cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
    //{ title: 'Q?', field: 'resp', headerSort: false, align: "center", formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
    //{ title: '@', formatter: emailIcon, width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { iconSendMail(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave) } },
    { title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().name, cell.getRow().getData().fone) } },
    { title: 'Serviços', formatter: iconAgendas, width: 70, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { visualizarHist(cell.getRow().getData().id, cell.getRow().getData().name) } },
    { title: 'Editar', formatter: editIcon, width: 50, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { editCadastro(cell.getRow().getData().id) } },
    //{ title: 'Del', formatter: iconLixeira, width: 25, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { if (cell.getRow().getData().progress < 11){ confirmDel(cell.getRow().getData().nameC, cell.getRow().getData().chave) }} },
    // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    // { title: 'Custom', field: 'custom', align: 'center', formatter: reactFormatter(<SimpleButton />) },
    //{ title: 'Custom', field: 'custom', align: 'center' },
  ];

  const columnsHist = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    { title: 'Comanda(id)', field: 'id', hozAlign: 'center', width: 110, responsive: 0, headerFilter: "input" },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'Profissional', field: 'prof', hozAlign: "left", width: 150, responsive: 0, headerSort: true, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    { title: 'Agendamento', field: 'start', hozAlign: "left", width: 150, responsive: 0, headerSort: true, sorter: "string" },
    { title: 'Serviços', field: 'servicos', hozAlign: "left", width: 300, responsive: 0, headerFilter: "input", headerSort: true, sorter: "string" },
    { title: 'Status', field: 'status', hozAlign: "left", width: 120, responsive: 0, headerSort: true, sorter: "string" },
    { title: 'Obs', field: 'obs', hozAlign: "left", width: 200, responsive: 0 },
    { title: 'Caixa(dia)', field: 'caixa', hozAlign: "center", width: 150, responsive: 0 },
    { title: 'ID Caixa', field: 'idcaixamov', hozAlign: "center", width: 100, responsive: 0, headerSort: true, sorter: "string" }
  ];

  /*   const columnsProp = [
      //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
      //{ title: 'Id', field: 'id', align: 'center', width: 40, visible: false, responsive: 0 },
      // {
      //  title: 'Data', field: 'createdAt', align: 'center', width: 80, responsive: 0, formatter: "datetime", formatterParams: {
      //    inputFormat: "YYYY-MM-DD",
      //   outputFormat: "DD/MM/YY",
      //   invalidPlaceholder: "(invalid date)",
      // }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
      // },
      // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
      { title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
      { title: 'Tipo', field: 'tipo', align: "center", width: 200, responsive: 0, },
      { title: 'Numero', field: 'prop', align: "center", width: 150, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Valor Total', field: 'vlr_total', align: "center", width: 150, responsive: 0, headerSort: false },
      { title: 'Parcelas', field: 'parc', align: "center", width: 80, responsive: 0, headerSort: false },
      { title: 'Valor Parcelas', field: 'vlr_parc', align: "center", width: 150, responsive: 0, headerSort: false },
      { title: 'Prazo', field: 'qtdDias', align: "center", width: 80, responsive: 0, headerSort: false },
      //{ title: 'Download', formatter: wordIcon, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { geraWordProp(cell.getRow().getData().chave, cell.getRow().getData().prop) } },
      //{ title: 'Deletar', formatter: iconLixeira, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().prop, cell.getRow().getData().tipo, cell.getRow().getData().vlr_total, cell.getRow().getData().chave) } },
      //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert('Finalizando o desenvolvimento...') } },
      //{ title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
      //{ title: 'Cidade/Estado', field: 'cidstate', align: 'center', width: 120, responsive: 0 },
      //{ title: 'Fone', field: 'fone', align: 'center', width: 130, responsive: 0 },
      //{ title: 'Info', field: 'msginfo', cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
      //{ title: 'Origem', field: 'origem', align: 'center', headerFilter: "input", width: 100, responsive: 0 },
      //{ title: 'Age', field: 'age', align: 'left', formatter: 'progress' },
      //{ title: 'Favourite Color', field: 'color' },
      //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
      //{ title: 'Rating', field: 'rating', align: 'center', formatter: 'star' },
      // { title: 'Link', field: 'passed', headerSort: false, align: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
      //{ title: 'Q?', field: 'resp', headerSort: false, formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
      //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().nameC, cell.getRow().getData().fone) } },
      // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
      // { title: 'Custom', field: 'custom', align: 'center', formatter: reactFormatter(<SimpleButton />) },
      //{ title: 'Custom', field: 'custom', align: 'center' },
    ]; */

  const options = {
    height: 300,
    // movableRows: true,
    clipboard: true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    initialSort: [
      { column: "name", dir: "asc" }, //sort by this first
    ]
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };
  const optionsHist = {
    height: 315,
    // movableRows: true,
    clipboard: true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    initialSort: [
      { column: "name", dir: "asc" }, //sort by this first
    ]
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  function Cadastro(params) {

    global.gAtuCad = 1;

    let setid = 0;
    //cl("global.gLog",global.gLog);
    //cl("global.gLog.id",global.gLog['id']);

    if (!global.inpAddFrmC.inputNome) { return validaNome(1, 'inputNome') }
    if (!global.inpAddFrmC.inputFone) { return validaFone(1, 'inputFone') }


    if (global.gLog['id'] > 0) { setid = global.gLog['id'] };




    setAtuCad(
      {
        'id': setid,
        'name': document.getElementById('inputNome').value,
        'email': document.getElementById('inputEmail4').value,
        'aprovado': document.getElementById('inputAprovado').value,
        'fone': document.getElementById('inputFone').value,
        'dtnasc': document.getElementById('inputDtnasc').value,
        'end': document.getElementById('inputEnd').value,
        'num': document.getElementById('inputNum').value,
        'compl': document.getElementById('inputCompl').value,
        'cidade': document.getElementById('inputCid').value,
        'estado': document.getElementById('inputEstado').value,
        'cep': document.getElementById('inputCep').value,
        'bairro': document.getElementById('inputBairro').value,
        'obs': document.getElementById('inputObs').value
      });

    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';

    global.inpAddFrmC.inputFone = false;
    global.inpAddFrmC.inputNome = false;


  }


  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }

  function novoCadastro(params) {
    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Novo Cadastro ..."

    global.gLog = [];


    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;
    elenome.focus();

    let einputEmail4 = document.getElementById('inputEmail4');
    einputEmail4.value = '';

    let einputDtnasc = document.getElementById('inputDtnasc');
    einputDtnasc.value = '';

    let einputFone = document.getElementById('inputFone');
    einputFone.value = '';

    let einputEnd = document.getElementById('inputEnd');
    einputEnd.value = '';

    let einputNum = document.getElementById('inputNum');
    einputNum.value = '';

    let einputCep = document.getElementById('inputCep');
    einputCep.value = '';

    let einputCompl = document.getElementById('inputCompl');
    einputCompl.value = '';

    let einputBairro = document.getElementById('inputBairro');
    einputBairro.value = '';

    let einputCid = document.getElementById('inputCid');
    einputCid.value = '';

    let einputEstado = document.getElementById('inputEstado');
    einputEstado.value = '';

    let einputObs = document.getElementById('inputObs');
    einputObs.value = '';

  }

  function editCadastro(params) {

    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Editar Cadastro ..."

    var arrFound = rep.filter(function (item, num) {
      return item.id === params;
    });

    global.gLog = arrFound[0];
    //cl("global.gLog",global.gLog);
    let dtfinal = '';
    if (arrFound[0].dtnasc) {

      let dt = arrFound[0].dtnasc.split("/");
      dtfinal = '' + dt[2] + '-' + dt[1] + '-' + dt[0] + '';
    } else {
      dtfinal = '';
    }

    //cl(dtfinal);

    let elenome = document.getElementById('inputNome');
    elenome.value = arrFound[0].name;
    //elenome.disabled = true;

    let einputEmail4 = document.getElementById('inputEmail4');
    einputEmail4.value = arrFound[0].email;

    let einputAprovado = document.getElementById('inputAprovado');
    einputAprovado.value = arrFound[0].aprovado;

    let einputDtnasc = document.getElementById('inputDtnasc');
    einputDtnasc.value = dtfinal;

    let einputFone = document.getElementById('inputFone');
    einputFone.value = arrFound[0].fone;

    let einputEnd = document.getElementById('inputEnd');
    einputEnd.value = arrFound[0].end;

    let einputNum = document.getElementById('inputNum');
    einputNum.value = arrFound[0].num;

    let einputCep = document.getElementById('inputCep');
    einputCep.value = arrFound[0].cep;

    let einputCompl = document.getElementById('inputCompl');
    einputCompl.value = arrFound[0].compl;

    let einputBairro = document.getElementById('inputBairro');
    einputBairro.value = arrFound[0].bairro;

    let einputCid = document.getElementById('inputCid');
    einputCid.value = arrFound[0].cidade;

    let einputEstado = document.getElementById('inputEstado');
    einputEstado.value = arrFound[0].estado;

    let einputObs = document.getElementById('inputObs');
    einputObs.value = arrFound[0].obs;

    validaFone();


  }




  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  function CalcSimule(params) {

    var iNf_valor = document.getElementById("inputNf_valor");
    let V_Nf_valor = realParaNumber(iNf_valor.value);
    if (V_Nf_valor) { iNf_valor.value = V_Nf_valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }

    var iVlr_pago = document.getElementById("inputVlr_pago");
    let V_pago = realParaNumber(iVlr_pago.value);
    if (V_pago) { iVlr_pago.value = V_pago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }

    var iDif = document.getElementById("inputDif");
    let V_dif = realParaNumber(iDif.value);
    if (V_dif) { iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }

    var iPcc = document.getElementById("inputPcc");
    let V_Pcc = realParaNumber(iPcc.value);
    if (V_Pcc) { iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }

    if ((iNf_valor.disabled === false) && (iVlr_pago.disabled === false) && (iDif.disabled === false)) {
      V_dif = V_pago - V_Nf_valor;
      if (V_dif > 0) {
        iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      }
    }

    if ((iNf_valor.disabled === false) && (iPcc.disabled === false)) {
      V_Pcc = V_Nf_valor * 0.045;
      if (V_Pcc > 0) {
        iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
      }
    }




  }

  function confirmDelProp(numprop, cliente) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar este Contrato da proposta ?</h1><br></br><br></br>
            <p id='pmodal'><b>{numprop} - {cliente}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                /*                 setDelContr(
                                  {
                                    'numprop': numprop
                                  }); */
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }

  function dataTable(params) {

    return (
      <>
        <div className="card card-info cardGridBgg" id="cardOcorrenciasCli" style={{ display: '' }}>
          <div className="card-header ui-sortable-handle gertitle locCont" >
            <h3 className="card-title locCont">Clientes Cadastrados</h3>

            <div className="card-tools locCont">
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={null} style={{ margin: '-3px 20px' }}>Novo Contato</button> */}
              <Hotkeys
                keyName="shift+c"
                onKeyDown={novoCadastro}
              //onKeyUp={novoContato}
              />
              <button type="button" className="btn bg-gradient-info btn-sm " onClick={novoCadastro} style={{ margin: '-3px 20px' }}>Novo Cadastro &#40;Shift+C&#41;</button>
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggI" />
              </button>

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={enviarEmail} style={{ margin: '-3px 20px' }}>Email</button> */}

            </div>
          </div>
          <div className="card-body locCont" id="cardGridBggBodyCli">
            <ReactTabulator
              // ref={(ref)}
              columns={columns}
              data={rep}
              /*               rowClick={rowClick}
                            cellClick={rowClick} */
              options={options}
              events={{}}
            //data-custom-attr="test-custom-attribute"
            //className="#example-table-theme"
            />

            <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          </div>
        </div>
      </>
    )

  }


  function page1(params) {
    return (
      <div id="panelActions" name="panelActions" style={{ position: 'relative', display: 'none' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Novo Cadastro...</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-5">
                        <label htmlFor="lNome">Nome*</label>
                        <input type="text" className="form-control" onChange={e => validaNome(e.target.value)} id="inputNome" placeholder="Nome" />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="lEmail4">Email*</label>
                        <div className="input-group-prepend">
                          <span className="input-group-text">@</span>
                          <input type="email" className="form-control" onChange={e => validaEmail4(e.target.value, 'inputEmail4')} id="inputEmail4" placeholder="Email" />
                        </div>
                      </div>
                      <div className="form-group col-md-1">
                        <label htmlFor="lEmail4">Aprovado</label>
                        <div className="input-group-prepend">
                        <select className="form-control select2 select2-hidden-accessible" id="inputAprovado" data-select2-id={1} tabIndex={-1} aria-hidden="true">
                        <option value="N">Não</option>
                        <option value="S">Sim</option>
                        </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="lFone">Fone*</label>
                        <div className="input-group-prepend">
                          <span className="input-group-text">📲</span>
                          <input type="text" className="form-control" onChange={e => validaFone(e.target.value)} placeholder="Ex: 11 99901-XXXX" id="inputFone" />
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="lEmail4">Nascimento</label>
                        <div className="input-group-prepend">
                          <input type="date" className="form-control" id="inputDtnasc" />
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="lCep">CEP</label>
                        <div className="input-group-prepend">
                          <input type="text" className="form-control" id="inputCep" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-9">
                        <label htmlFor="lEnd">Endereço</label>
                        <div className="input-group-prepend">
                          <input type="text" className="form-control" placeholder="Ex: Rua, Av, ..." id="inputEnd" />
                        </div>
                      </div>
                      <div className="form-group col-md-1">
                        <label htmlFor="lNum">Nº</label>
                        <div className="input-group-prepend">
                          <input type="text" className="form-control" placeholder="Ex: 315A" id="inputNum" />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="lCompl">Compl</label>
                        <div className="input-group-prepend">
                          <input type="text" className="form-control" placeholder="Ex: AP12" id="inputCompl" />
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-5">
                        <label htmlFor="lBairro">Bairro</label>
                        <input type="text" className="form-control" placeholder="Ex: São Paulo - SP" id="inputBairro" />
                      </div>
                      <div className="form-group col-md-5">
                        <label htmlFor="lCity">Cidade</label>
                        <input type="text" className="form-control" placeholder="Ex: São Paulo - SP" id="inputCid" />
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="lEst">Estado</label>
                        <input type="text" className="form-control" placeholder="Ex: São Paulo - SP" id="inputEstado" />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="lObs">Obs</label>
                      <input type="text" className="form-control" id="inputObs" placeholder="Obs" />
                    </div>
                    <button type="button" className="btn btn-info " id="btnGravar" onClick={e => Cadastro()}>Gravar</button>
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={e => CloseEditContr()}>Cancelar</button>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }

  function cliHist(params) {
    return (
      <div id="panelHist" name="panelHist" style={{ position: 'relative', display: 'none' }}>
        <div id="divLoadHist" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
        <section className="content">
          <div className="row">
            <section className="col-lg-12 connectedSortable ui-sortable">

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloHist">Histórico de Serviços</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body locCont" id="cardGridBggBody">
                  <ReactTabulator
                    // ref={(ref)}
                    columns={columnsHist}
                    data={repHist}
                    /*               rowClick={rowClick}
                                  cellClick={rowClick} */
                    options={optionsHist}
                    events={{}}
                  //data-custom-attr="test-custom-attribute"
                  //className="#example-table-theme"
                  />

                </div>
              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }


  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCidade')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("(99) 99999-9999");
    im.mask(eNnome);

  }

  function formataFone(params) {
    const nome = params.replace(/_|-/g, '');
    ////cl(nome)
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    let result = im.mask(params);
    return result;

  }


  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function CloseEditContr(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;

    let einputEmail4 = document.getElementById('inputEmail4');
    einputEmail4.value = '';

    let einputDtnasc = document.getElementById('inputDtnasc');
    einputDtnasc.value = '';

    let einputFone = document.getElementById('inputFone');
    einputFone.value = '';

    let einputEnd = document.getElementById('inputEnd');
    einputEnd.value = '';

    let einputNum = document.getElementById('inputNum');
    einputNum.value = '';

    let einputCep = document.getElementById('inputCep');
    einputCep.value = '';

    let einputCompl = document.getElementById('inputCompl');
    einputCompl.value = '';

    let einputBairro = document.getElementById('inputBairro');
    einputBairro.value = '';

    let einputCid = document.getElementById('inputCid');
    einputCid.value = '';

    let einputEstado = document.getElementById('inputEstado');
    einputEstado.value = '';

    let einputObs = document.getElementById('inputObs');
    einputObs.value = '';


  }

  if (repCliout.length < 1) {
    //if (0 == 1) {
    return (
      <div className="content-wrapper" >
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando Clientes ...</div>
      </div >
    )
  } else {
    return (
      <div className="content-wrapper">
        {page1()}
        {dataTable()}
        {cliHist()}



      </div>
    )

  }


}

export default Gerador   