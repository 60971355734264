import React, { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import Loading from "./assets/loading4.gif";
import Logo from "./assets/login.png";
import "./components/functions";
import { cl } from "./components/functions";
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import './cadastro.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import inputmask from "inputmask";
import moment from "moment";
import '../node_modules/moment/locale/pt-br';
import dateFormat from "dateformat";

const TOKEN_KEY = "KEY_APP_BGG";

const iconwhatsappweb = function (cell, formatterParams) { //plain text value
  return "<i class='fa fa-whatsapp'></i>";
}

global.emailok = 0;

async function trocarsenha(params) {

  try {
    var bodyFormData = new FormData();
    //localStorage.setItem('linkbase', 'https://api.youevolution.com.br');
    //const klinkbase = localStorage.getItem('linkbase');
    //global.gLinkBase = klinkbase;
    //console.log("linkbase",klinkbase);
    bodyFormData.set('apikey', '92023QV045NV$Vq3cVBNh@$MG@$OJG48ugyf9');
    bodyFormData.set('chave', global.rchave);
    bodyFormData.set('pass', document.getElementById("password").value);
    //bodyFormData.append('password', 'senha');
    const response = await axios({
      method: 'post',
      url: 'https://api.youevolution.com.br/resetsenha.php',
      data: bodyFormData,
      config: { headers: { 'Content-Type': 'multipart/form-data' } }
    });

    if (response.data) {
      document.getElementById('pError').innerHTML = 'Senha Alterada !!!';
      //console.log("login-: ", response.data);
      //loginapi(response.data);
      //irHome();
      const teste = sessionStorage.removeItem(TOKEN_KEY); //sessionStorage.removeItem(TOKEN_KEY);
      window.alert("Senha alterada com sucesso! Você será redicionado para tela de acesso do APP, preencha seu \"Usuário ou Email\" de acesso e a senha que acabou de criar,  sugerimos que adicione a próxima tela como atalho em sua tela inicial!")
      window.open("/", "_self");

    } else {

      document.getElementById('pError').innerHTML = 'Chave não localizada !';

    }
  } catch (err) {
    console.log(err);
    if (!err.response) {

    } else {

    }

  }

}


function Gerador() {

  const { pedirreset } = useParams();
  //let echave = document.getElementById('chave');
  //if (echave) { echave.value = idchave; }
  global.pedirReset = pedirreset;
  if (pedirreset === 'reset') {
    global.tituloForm = 'Recuperar senha!';
    global.lmelhorEmailtxt = 'Qual email utilizado ?';
  } else {
    global.tituloForm = 'Novo Cadastro';
    global.lmelhorEmailtxt = 'Qual seu melhor email ?';
  }
  //cl("pedirreset", pedirreset);


  const [Atu, setAtu] = useState([]);

  useEffect(() => {

    let espanTitulo = document.getElementById('spanTitulo');
    if (espanTitulo) { espanTitulo.innerHTML = global.tituloForm; }

    let elmelhorEmail = document.getElementById('lmelhorEmail');
    if (elmelhorEmail) { elmelhorEmail.innerHTML = global.lmelhorEmailtxt; }

  }, [Atu])

  function gravar(params) {
    let ebuttonLogin = document.getElementById('buttonLogin');
    if (ebuttonLogin) { ebuttonLogin.style.display = 'none'; }
    let ebuttonLoading = document.getElementById('buttonLoading');
    if (ebuttonLoading) { ebuttonLoading.style.display = ''; }

    let epassword = document.getElementById('password');
    let epassword2 = document.getElementById('password2');

    if ((epassword.value.length < 6) || (epassword.value.length < 6)) {
      window.alert("Senha precisa conter 6 caracteres no mínimo !")
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }
      return
    }

    if (epassword.value === epassword2.value) {

      trocarsenha();

      if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }

    } else {
      window.alert("Senha não são iguais, favor revisar !")
      epassword.value = '';
      epassword2.value = '';
      epassword.focus();
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }
      return
    }
  }

  function enviarCadastro(params) {

    let ignore = false;
    async function enviaCad() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '92023QV045NV$Vq3cVBNh@$MG@$OJG48ugyf9');
      bodyFormData.set('emailCli', global.cademail);
      bodyFormData.set('nomeCli', global.cadnome + " " + global.cadsobrenome);
      bodyFormData.set('foneCli', global.cadfone);
      /* bodyFormData.set('dtNasc', global.caddtnasc); */
      bodyFormData.set('tipoPessoa', global.tipoPessoa);
      bodyFormData.set('doc', global.cpfcnpj);
      bodyFormData.set('rz', global.cadRz);
      bodyFormData.set('end', global.cadEnd);
      bodyFormData.set('num', global.cadNum);
      bodyFormData.set('compl', global.cadCompl);
      bodyFormData.set('ref', global.cadRef);
      bodyFormData.set('user', 99);
      bodyFormData.set('reset', 0);
      const urlfull = 'https://api.youevolution.com.br/novoCadastro.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response", response);
      //cl("responsedata", response['data']);
      //cl("responsedatazero", response['data'][0]);
      //setrepCliout(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'][0];
        let val = redata2.id;
        cl("redata2", val);
        if (val > 1) {
          let edivFormcad = document.getElementById('divFormcad');
          edivFormcad.style.display = 'none';
          let edivcadOk = document.getElementById('divcadOk');
          edivcadOk.style.display = '';
        }
      }
    }
    enviaCad();
  }

  function criarResetSenha(params) {
    let ebuttonLogin = document.getElementById('buttonLogin');
    if (ebuttonLogin) { ebuttonLogin.style.display = 'none'; }
    let ebuttonLoading = document.getElementById('buttonLoading');
    if (ebuttonLoading) { ebuttonLoading.style.display = ''; }

    let ignore = false;
    async function enviaCad() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '92023QV045NV$Vq3cVBNh@$MG@$OJG48ugyf9');
      bodyFormData.set('emailCli', global.cademail);
      bodyFormData.set('reset', 1);
      const urlfull = 'https://api.youevolution.com.br/novoCadastro.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response", response);
      //cl("responsedata", response['data']);
      //cl("responsedatazero", response['data'][0]);
      //setrepCliout(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'][0];
        let val = redata2.id;
        //cl("redata2", val);
        if (val > 1) {
          let edivFormcad = document.getElementById('divFormcad');
          edivFormcad.style.display = 'none';
          let edivresetOk = document.getElementById('divresetOk');
          edivresetOk.style.display = '';
        }
        if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
        if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }
      }
    }
    enviaCad();
  }


  function verificaCad() {

    if (!global.inpAddFrmC.inputEmail) { return validaEmail(1, 'inputEmail') }


    let ebuttonLogin = document.getElementById('buttonLogin');
    if (ebuttonLogin) { ebuttonLogin.style.display = 'none'; }
    let ebuttonLoading = document.getElementById('buttonLoading');
    if (ebuttonLoading) { ebuttonLoading.style.display = ''; }
    let eEmail = document.getElementById('inputEmail');
    let idcli = eEmail.value;
    
    
    let ignore = false;
    async function verificaEmail() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '92023QV045NV$Vq3cVBNh@$MG@$OJG48ugyf9');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('idcli', idcli);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/validaEmail.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response['data'][0]);
      //setrepCliout(response);
      
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'][0];
        let val = redata2.existe;
        //cl("redata2",val);
        if (val === 1) {
          //let msg = "O endereço de email " + idcli + " já esta sendo utilizado ";
          //window.alert(msg);

          if (global.pedirReset === 'reset') {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
                    <h1>Atenção !</h1><br></br><br></br>
                    <p id='pmodal'><b>Enviar o link para cadastrar novamente sua senha no email ({idcli}) agora ?</b></p>
                    <br></br>
                    <button className="btn bg-gradient-info btn-sm"
                      onClick={() => {
                        eEmail.value = '';
                        validaEmail(1, 'inputEmail');
                        onClose();
                      }}
                    >
                      Não</button>
                    <button
                      className="btn bg-gradient-danger btn-sm float-right"
                      onClick={() => {
                        criarResetSenha();
                        onClose();
                      }}
                    >
                      Sim, enviar agora!
                    </button>
                  </div>
                );
              }
            });

          } else {
            confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
                    <h1>Atenção !</h1><br></br><br></br>
                    <p id='pmodal'><b>Este endereço de email ({idcli}) já esta sendo utilizado!</b></p>
                    <p> <b>Deseja que seja enviado o link para recuperar a sua senha ?</b></p>
                    <br></br>
                    <button className="btn bg-gradient-info btn-sm"
                      onClick={() => {
                        eEmail.value = '';
                        validaEmail(1, 'inputEmail');
                        onClose();
                      }}
                    >
                      Não</button>
                    <button
                      className="btn bg-gradient-danger btn-sm float-right"
                      onClick={() => {
                        criarResetSenha();
                        onClose();
                      }}
                    >
                      Sim, esqueci minha senha!
                    </button>
                  </div>
                );
              }
            });

          }
        } else {

          if (global.pedirReset === 'reset') {
            alert("Endereço não localizado, confira a digitação !");
            return
          }
          eEmail.disabled = true;

          let edivcont = document.getElementById('divcont');
          if (edivcont) { edivcont.style.display = '' };
          let einputNome = document.getElementById('inputNome');
          einputNome.focus();
          global.emailok = 1;

        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
        if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }
      }
    }
    if (global.emailok === 0) {
       verificaEmail() 
      return
      };
    if (!global.inpAddFrmC.inputNome) {
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; };
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; };
      return validaNome(1, 'inputNome')
    }
    if (!global.inpAddFrmC.inputSobreNome) {
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; };
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; };
      return validaSobreNome(1, 'inputSobreNome')
    }
    if (!global.inpAddFrmC.inputFone) {
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; };
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; };
      return validaFone(1, 'inputFone')
    }
    /*     if (!global.inpAddFrmC.inputDtNasc) {
          if (ebuttonLogin) { ebuttonLogin.style.display = ''; };
          if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; };
          return validaFone(1, 'inputDtNasc')
        } */
    if (global.tipoPessoa === 'J') {

      if (!global.inpAddFrmC.inputRz) {
        if (ebuttonLogin) { ebuttonLogin.style.display = ''; };
        if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; };
        return validaRz(1, 'inputRz')
      }
    }
    if (!global.inpAddFrmC.inputEnd) {
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; };
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; };
      return validaEnd(1, 'inputEnd')
    }
    if (!global.inpAddFrmC.inputNum) {
      if (ebuttonLogin) { ebuttonLogin.style.display = ''; };
      if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; };
      return validaNum(1, 'inputNum')
    }
    let einputNome = document.getElementById('inputNome').value;
    let einputSobreNome = document.getElementById('inputSobreNome').value;
    let einputFone = document.getElementById('inputFone').value;
    let einputCPFCNPJ = document.getElementById('inputCPFCNPJ').value;
    let einputRz = document.getElementById('inputRz').value;
    let einputEnd = document.getElementById('inputEnd').value;
    let einputNum = document.getElementById('inputNum').value;
    let einputCompl = document.getElementById('inputCompl').value;
    let einputRef = document.getElementById('inputRef').value;
    //let einputdtNasc = document.getElementById('inputDtNasc').value;

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <p id='pmodaltualerta'><b>Confirme os dados Iniciais...</b></p>
            <p id='pmodal'><b>Email: {idcli}</b></p>
            <p id='pmodal'><b>Nome: {einputNome} {einputSobreNome}</b></p>
            <p id='pmodal'><b>WhatsApp: {einputFone}</b></p>
            {/* <p id='pmodal'><b>Nasc.: {moment(einputdtNasc, 'YYYY-MM-DD').format('DD/MM/YYYY')}</b></p> */}
            <p id='pmodal'><b>Doc: {einputCPFCNPJ}</b></p>
            <p id='pmodal'><b>{einputRz}</b></p>
            <p id='pmodal'><b>End: {einputEnd},{einputNum},{einputCompl}</b></p>
            <p id='pmodal'><b>Ref.: {einputRef}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm"
              onClick={() => {
                let ebuttonLogin = document.getElementById('buttonLogin');
                if (ebuttonLogin) { ebuttonLogin.style.display = ''; }
                let ebuttonLoading = document.getElementById('buttonLoading');
                if (ebuttonLoading) { ebuttonLoading.style.display = 'none'; }

                onClose();
              }}
            >
              Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                enviarCadastro();
                onClose();
              }}
            >
              Sim, cadastrar agora!
            </button>
          </div>
        );
      }
    });
  }

  function validaEmail(params, idinput) {
    const eNemail = document.getElementById('inputEmail')
    const email = eNemail.value;
    global.cademail = email;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    global.cadfone = nomep;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 13) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("(99) 99999-9999");
    im.mask(eNnome);

  }

  function validaCpfCnpj(val) {
    if (val.length == 11) {
      var cpf = val.trim();

      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p;
      }

      v1 = ((v1 * 10) % 11);

      if (v1 == 10) {
        v1 = 0;
      }

      if (v1 != cpf[9]) {
        return false;
      }

      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p;
      }

      v2 = ((v2 * 10) % 11);

      if (v2 == 10) {
        v2 = 0;
      }

      if (v2 != cpf[10]) {
        return false;
      } else {
        return true;
      }
    } else if (val.length == 14) {
      var cnpj = val.trim();

      cnpj = cnpj.replace(/\./g, '');
      cnpj = cnpj.replace('-', '');
      cnpj = cnpj.replace('/', '');
      cnpj = cnpj.split('');

      var v1 = 0;
      var v2 = 0;
      var aux = false;

      for (var i = 1; cnpj.length > i; i++) {
        if (cnpj[i - 1] != cnpj[i]) {
          aux = true;
        }
      }

      if (aux == false) {
        return false;
      }

      for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v1 += cnpj[i] * p1;
        } else {
          v1 += cnpj[i] * p2;
        }
      }

      v1 = (v1 % 11);

      if (v1 < 2) {
        v1 = 0;
      } else {
        v1 = (11 - v1);
      }

      if (v1 != cnpj[12]) {
        return false;
      }

      for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
        if (p1 >= 2) {
          v2 += cnpj[i] * p1;
        } else {
          v2 += cnpj[i] * p2;
        }
      }

      v2 = (v2 % 11);

      if (v2 < 2) {
        v2 = 0;
      } else {
        v2 = (11 - v2);
      }

      if (v2 != cnpj[13]) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  function validaCPFCNPJ(params) {
    const eNCPFCNPJ = document.getElementById('inputCPFCNPJ')
    const CPFCNPJp = eNCPFCNPJ.value;

    const CPFCNPJ = CPFCNPJp.replace(/[^0-9]/g, '');
    const valido = validaCpfCnpj(CPFCNPJ);
    ////cl(CPFCNPJ)
    if ((CPFCNPJ.length === 11) && (valido === true)) {
      eNCPFCNPJ.classList.add("is-valid")
      eNCPFCNPJ.classList.remove("is-invalid")
      global.inpAddFrmC.inputCPFCNPJ = true;
      global.tipoPessoa = 'F';
      global.cpfcnpj = CPFCNPJ;
    } else if ((CPFCNPJ.length === 14) && (valido === true)) {
      eNCPFCNPJ.classList.add("is-valid")
      eNCPFCNPJ.classList.remove("is-invalid")
      global.inpAddFrmC.inputCPFCNPJ = true;
      global.tipoPessoa = 'J';
      global.cpfcnpj = CPFCNPJ;
    } else {
      eNCPFCNPJ.classList.add("is-invalid")
      eNCPFCNPJ.classList.remove("is-valid")
      if (params === 1) { eNCPFCNPJ.focus() }
    }
    //eNCPFCNPJ.value = params.toLowerCase();
    if ((CPFCNPJ.length === 11) && (valido === true)) {
      let im = new Inputmask("999.999.999-99");
      im.mask(eNCPFCNPJ);
      let efgRz = document.getElementById('fgRz');
      efgRz.style.display = 'none';
    } else if ((CPFCNPJ.length === 14) && (valido === true)) {
      let im = new Inputmask("99.999.999/9999-99");
      im.mask(eNCPFCNPJ);
      let efgRz = document.getElementById('fgRz');
      efgRz.style.display = '';
    } else {
      let im = new Inputmask("99999999999999");
      im.mask(eNCPFCNPJ);
      let efgRz = document.getElementById('fgRz');
      efgRz.style.display = 'none';
    }

  }

  function validaDtNasc(params) {
    const eNnome = document.getElementById('inputDtNasc')
    const nomep = eNnome.value;
    global.caddtnasc = nomep;
    const nome = nomep.replace(/_|-/g, '');
    //cl("validaDtNasc", nome)
    if (nome.length > 7) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputDtNasc = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    //let im = new Inputmask("99 99999-9999");
    //im.mask(eNnome);

  }

  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    global.cadnome = nome;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
      let str = eNnome.value.toLowerCase();
      let capitalized = str[0].toUpperCase() + str.substr(1);
      eNnome.value = capitalized;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
  }

  function validaSobreNome(params) {
    const eNnome = document.getElementById('inputSobreNome')
    const nome = eNnome.value;
    global.cadsobrenome = nome;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputSobreNome = true;
      let str = eNnome.value.toLowerCase();
      let capitalized = str[0].toUpperCase() + str.substr(1);
      eNnome.value = capitalized;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
  }

  function validaRz(params) {
    const eNnome = document.getElementById('inputRz')
    const nome = eNnome.value;
    global.cadRz = nome;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputRz = true;
      let str = eNnome.value.toLowerCase();
      let capitalized = str[0].toUpperCase() + str.substr(1);
      //eNnome.value = capitalized;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
  }

  function validaEnd(params) {
    const eNnome = document.getElementById('inputEnd')
    const nome = eNnome.value;
    global.cadEnd = nome;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputEnd = true;
      let str = eNnome.value.toLowerCase();
      let capitalized = str[0].toUpperCase() + str.substr(1);
      eNnome.value = capitalized;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
  }

  function validaNum(params) {
    const eNnome = document.getElementById('inputNum')
    const nome = eNnome.value;
    global.cadNum = nome;
    if (nome.length > 1) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNum = true;
      let str = eNnome.value.toLowerCase();
      let capitalized = str[0].toUpperCase() + str.substr(1);
      eNnome.value = capitalized;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
  }

  function validaRef(params) {
    const eNnome = document.getElementById('inputRef')
    const nome = eNnome.value;
    global.cadRef = nome;
    if (nome.length > -1) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputRef = true;
      let str = eNnome.value.toLowerCase();
      let capitalized = str[0].toUpperCase() + str.substr(1);
      eNnome.value = capitalized;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
  }

  function validaCompl(params) {
    const eNnome = document.getElementById('inputCompl')
    const nome = eNnome.value;
    global.cadCompl = nome;
    if (nome.length > -1) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputCompl = true;
      let str = eNnome.value.toLowerCase();
      let capitalized = str[0].toUpperCase() + str.substr(1);
      eNnome.value = capitalized;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
  }

  function CaixaFechado(params) {
    return (
      <div>
        <div className="limiter">
          <div className="container-login100" style={{ backgroundImage: 'url("../images/bg-4.jpg")' }}>
            <div className="wrap-login100">
              <span className="login100-form-logo">
                <img className="imglogo" src={Logo} alt="Login" />
                {/*<i className="zmdi zmdi-landscape" />*/}
              </span>
              <div id="divFormcad">
                <span className="login100-form-title p-b-76 p-t-27" id="spanTitulo">
                  Novo Cadastro
                </span>
                {/*               <div className="form-group">
                <label htmlFor="email">E-mail:</label>
                <input type="email" className="form-control" id="email" name="email" required />
                <div className="invalid-feedback">
                  <i className="fas fa-times" />
                </div>
              </div> */}
                <div className="form-group">
                  <label htmlFor="lEmail" id="lmelhorEmail">Qual seu melhor email ?</label>
                  <div className="input-group">
                    {/* <span className="input-group-text">@</span> */}
                    <input type="email" className="form-control" onChange={e => validaEmail(e.target.value, 'inputEmail')} id="inputEmail" placeholder="Email" />
                  </div>
                </div>
                <div id="divcont" style={{ display: 'none' }}>

                  <div className="form-group">
                    <label htmlFor="lNome">Seu primeiro Nome</label>
                    <input type="text" className="form-control" onChange={e => validaNome(e.target.value)} id="inputNome" placeholder="Nome" />
                  </div>


                  <div className="form-group">
                    <label htmlFor="lSobreNome">Sobrenome</label>
                    <input type="text" className="form-control" onChange={e => validaSobreNome(e.target.value)} id="inputSobreNome" placeholder="Sobrenome" />
                  </div>

                  <div className="form-row">
                    <div className="form-group" id="divWhats">

                      <label htmlFor="lFone" id="Lzap"><i className="fa fa-whatsapp" id="iconzap" aria-hidden="true"></i> WhatsApp</label>
                      <div className="input-group-prepend">
                        <input type="text" className="form-control" onChange={e => validaFone(e.target.value)} placeholder="Ex: 11 99901-XXXX" id="inputFone" />
                      </div>
                    </div>
                    {/*                     <div className="form-group" id="divdtNasc">
                      <label htmlFor="lEmail44">Data de Nascimento</label>
                      <div className="input-group-prepend">
                        <input type="date" className="form-control" onChange={e => validaDtNasc(e.target.value)} id="inputDtNasc" />
                      </div>
                    </div> */}
                  </div>
                  <div className="form-group">
                    <label htmlFor="lCPFCNPJ">CPF ou CNPJ</label>
                    <input type="text" className="form-control" onChange={e => validaCPFCNPJ(e.target.value)} id="inputCPFCNPJ" placeholder="só numeros" />
                  </div>
                  <div className="form-group" id="fgRz" style={{ display: 'none' }}>
                    <label htmlFor="lRz">Razão Social</label>
                    <input type="text" className="form-control" onChange={e => validaRz(e.target.value)} id="inputRz" placeholder="Igual do CNPJ" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lend">Endereço - Nome da rua</label>
                    <input type="text" className="form-control" onChange={e => validaEnd(e.target.value)} id="inputEnd" placeholder="Rua, Av, Alameda" />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <label htmlFor="lend">Número</label>
                      <input type="text" className="form-control" onChange={e => validaNum(e.target.value)} id="inputNum" placeholder="ex.: 123A.." />
                    </div>
                    <div className="form-group col-md-8">
                      <label htmlFor="lend">Complemento</label>
                      <input type="text" className="form-control" onChange={e => validaCompl(e.target.value)} id="inputCompl" placeholder="ex.: Ap 7 - 7andar" />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="lRef">Referência do endereço</label>
                    <input type="text" className="form-control" onChange={e => validaRef(e.target.value)} id="inputRef" placeholder="ex.:próximo a, esquina com" />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lRz">Montividiu - GO</label>
                  </div>

                </div>



                {/*}  <div className="contact100-form-checkbox">
                              <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" />
                              <label className="label-checkbox100" htmlFor="ckb1">
                                  Remember me
                          </label>
    </div>*/}
                <div className="container-login100-form-btn">
                  <button onClick={() => verificaCad()} className="login100-form-btn" id="buttonLogin" name="buttonLogin">

                    <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    &nbsp;<span id="spanLogin">avançar</span>
                  </button>
                  <button type="" className="login100-form-btn" id="buttonLoading" name="buttonLoading" style={{ display: 'none' }}>

                    <span id="spanLogin"><img id="imgloading" width="50px" alt="Loading.." src={Loading}></img>Aguarde</span>

                  </button>
                </div>
              </div>
              <div id="divcadOk" style={{ display: 'none' }}>
                <span className="login100-form-title p-b-76 p-t-27" id="spanTituloCad">
                  Tudo certo 👍🏻
                </span>
                <p className="pCadOk">Cadastro realizado com sucesso !</p>
                <p className="pCadOk">Enviamos uma mensagem de email para sua caixa postal, deve chegar em minutos.</p>
                <p className="pCadOk">Siga as orientações contidas na mensagem para cadastrar sua senha, caso não localize o email, verifique a pasta spam ou lixo eletrônico.</p>
                <p className="pCadOk">Até breve !</p>
              </div>

              <div id="divresetOk" style={{ display: 'none' }}>
                <span className="login100-form-title p-b-76 p-t-27" id="spanTituloReset">
                  Tudo certo 👍🏻
                </span>
                <p className="pCadOk">Reset de senha realizado com sucesso !</p>
                <p className="pCadOk">Enviamos uma mensagem de email para sua caixa postal, deve chegar em minutos.</p>
                <p className="pCadOk">Siga as orientações contidas na mensagem para cadastrar sua nova senha, caso não localize o email, verifique a pasta spam.</p>
                <p className="pCadOk">Até breve !</p>
              </div>

              <div className="text-center p-t-20">
                <p className="pError" id="pError"></p>

              </div>
              <div className="text-right" id="txtdivnovocad">
                <a className="txt3" href="/">
                  voltar ↩
                </a>
              </div>
              {/* <div className="text-center p-t-30">
                  <a className="txt1" href="/">
                    Esqueceu a senha ?
  </a>
                </div> */}
            </div>
          </div>
        </div>
        <div id="dropDownSelect1" />

      </div>
    )

  }


  return (
    <div>
      {CaixaFechado()}
    </div>
  )

}

export default Gerador   