import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import Loading from "./assets/loading4.gif";
import Logo from "./assets/login.png";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import 'react-tabulator/lib/css/tabulator.min.css'; // theme
import { ReactTabulator } from 'react-tabulator'; // for React 15.x, use import { React15Tabulator }
import { cl } from "./components/functions";
import './clientes.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link, animateScroll as scroll } from "react-scroll";
import Hotkeys from 'react-hot-keys';
import date from 'date-and-time';
import {
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  VerticalAlign,
  WidthType
} from "docx";
//import * as fs from "fs";
//const fs = require('fs')

var gGlobal = require('./components/globalvar.js');

const wordIcon = function (cell, formatterParams) { //plain text value
  return "<i class='far fa-file-word'></i>";
};
const editIcon = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-edit'></i>";
};
const iconContrato = function (cell, formatterParams) { //plain text value
  return "<i class='fas fa-file-download'></i>";
};
const iconwhatsappweb = function (cell, formatterParams) { //plain text value
  return "<i class='fa fa-whatsapp'></i>";
}

const iconLixeira = function (cell, formatterParams) { //plain text value
  //if (cell.getRow().getData().progress < 11){
  return "<i class='fa fa-trash-o'></i>";
  //} else {
  //  return "-";
  //}
}

global.gLog = [];

function rowClick(e, row) {
  ////cl('ref table: ', this.ref.table); // this is the Tabulator table instance
  //cl("rowClick id: " + row.getData().id, row, e);
  ////cl(rep);

};

function Gerador() {

  const [Atu, setAtu] = useState([]);
  const [rep, setrep] = useState([]);
  const [repCliout, setrepCliout] = useState([]);
  const [AtuCad, setAtuCad] = useState([]);


  const [DocNow, setDocNow] = useState([]);
  const isFirstRunAtuCad = useRef(true);




  useEffect(() => {


  }, [rep])

  useEffect(() => {



  }, [DocNow])


  useEffect(() => {


  }, [repCliout])


  useEffect(() => {
    /*     if (isFirstRunDados.current) {
          isFirstRunDados.current = false;
          //cl("FirstRunDados false")
          return;
        } */
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('comp', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaProd.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      setrepCliout(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setrep(redata2);
        } else {
          setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [Atu])

  useEffect(() => {
    if (isFirstRunAtuCad.current) {
      isFirstRunAtuCad.current = false;
      //cl("FirstRunDados false")
      return;
    }
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      bodyFormData.set('id', AtuCad.id);
      bodyFormData.set('ean', AtuCad.ean);
      bodyFormData.set('descricao', AtuCad.descricao);
      bodyFormData.set('grupo', AtuCad.grupo);
      bodyFormData.set('valor', realParaNumber(AtuCad.valor));
      bodyFormData.set('unidade', AtuCad.unidade);
      bodyFormData.set('valor_custo', realParaNumber(AtuCad.valor_custo));
      bodyFormData.set('porc', AtuCad.porc);
      bodyFormData.set('estoque_min', AtuCad.estoque_min);
      bodyFormData.set('estoque', AtuCad.estoque);
      bodyFormData.set('log', JSON.stringify(global.gLog));
      bodyFormData.set('user', global.gIdUser);

      const urlfull = 'https://api.youevolution.com.br/ProdAtu.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      //setrepCliout(response);
      setAtu({ '0': '0' });
      if (!ignore) {
        /*         const redata = [{}];
                const redata2 = response['data'];
                if (redata2) {
                  setrep(redata2);
                } else {
                  setrep(redata);
                } */
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    if (global.gAtuCad === 1) {

      fetchdata();
      global.gAtuCad = 0;

    } else { return };
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [AtuCad])

  const ref = null;


  const columns = [
    //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
    { title: 'Id', field: 'id', hozAlign: 'center', width: 40, visible: false, responsive: 0 },
    // // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    { title: 'QTD', field: 'estoque', hozAlign: 'center', width: 60, visible: true, responsive: 0 },
    { title: 'Descrição', field: 'descricao', hozAlign: "left", width: 300, responsive: 0, headerSort: true, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    { title: 'Codigo Barras', field: 'ean', hozAlign: "left", width: 180, responsive: 0, headerSort: true, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    { title: 'Grupo', field: 'grupo', hozAlign: "left", width: 120, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Valor R$', field: 'valor', hozAlign: "right", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Unidade', field: 'unidade', hozAlign: "left", width: 50, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'Custo R$', field: 'valor_custo', hozAlign: "right", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc" },
    { title: 'QTD_MIN', field: 'estoque_min', hozAlign: 'center', width: 80, visible: true,headerSort: false, responsive: 0 },
    {
      title: 'Comis.(%)', field: 'porc', hozAlign: "center", width: 100, responsive: 0, headerSort: false, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {

        let textoAjustado = cell.getValue();

        return textoAjustado + " %";
      }
    },
/*     {
      title: 'Tempo Médio', field: 'tempomedio', hozAlign: "center", width: 130, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc", formatter: function (cell, formatterParams, onRendered) {

        let textoAjustado = cell.getValue();

        return textoAjustado + " h";
      }
    }, */
    //{ title: 'Sindico', field: 'sindico', align: "center", width: 150, responsive: 0, headerSort: false },
    /*     {
      title: 'NF Data', field: 'nfe_data', align: 'center', width: 66, responsive: 0, formatter: "datetime", formatterParams: {
        inputFormat: "YYYY-MM-DD",
        outputFormat: "DD/MM/YY",
        invalidPlaceholder: "",
      }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
      }, */
    //{ title: 'Cidade', field: 'cidade', hozAlign: "left", width: 100, responsive: 0, },
    //{ title: 'Valor Parcelas', field: 'vlr_parc', align: "center", width: 150, responsive: 0, headerSort: false },
    //{ title: 'Prazo', field: 'qtdDias', align: "center", width: 80, responsive: 0, headerSort: false },
    //{ title: 'Editar', formatter: editIcon, width: 60, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { cl(cell.getRow().getData().prop, cell.getRow().getData().nome, cell.getRow().getData().tipo, cell.getRow().getData().sindico, cell.getRow().getData().eleito, cell.getRow().getData().rg, cell.getRow().getData().cpf, cell.getRow().getData().profiss, cell.getRow().getData().estadocivil, cell.getRow().getData().nomet1, cell.getRow().getData().rgt1, cell.getRow().getData().nomet2, cell.getRow().getData().rgt2) } },
    { title: 'Editar', formatter: editIcon, width: 50, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { editCadastro(cell.getRow().getData().id) } },
    //{ title: 'Gerar', formatter: wordIcon, width: 60, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { geraWordContr(cell.getRow().getData().numcontr) } },
    //{ title: 'Deletar', formatter: iconLixeira, width: 60, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().prop, cell.getRow().getData().nome) } },
    //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { gGlobal.gContrato = cell.getRow().getData().prop; gerContratoLink(); } },

    // { title: 'Nome', field: 'nameC', width: 150, responsive: 0, headerFilter: "input", sorter: "string", headerSortStartingDir: "asc" },
    // { title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
    // { title: 'Cidade/Estado', field: 'cidstate', align: 'center', width: 120, responsive: 0 },
    // { title: 'Fone', field: 'fone', align: 'center', width: 100, responsive: 0 },
    // { title: 'Origem', field: 'origem', align: 'center', headerFilter: "input", width: 100, responsive: 0 },
    //{ title: 'Age', field: 'age', align: 'left', formatter: 'progress' },
    //{ title: 'Favourite Color', field: 'color' },
    //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
    //{ title: 'Rating', field: 'rating', align: 'center', formatter: 'star' },
    // { title: 'Link', field: 'passed', headerSort: false, align: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
    //{ title: 'Info', field: 'msginfo', align: "center", width: 80, headerSort: false, formatter: infoIcon, responsive: 0, cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
    //{ title: 'Q?', field: 'resp', headerSort: false, align: "center", formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
    //{ title: '@', formatter: emailIcon, width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { iconSendMail(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave) } },
    //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, hozAlign: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().name, cell.getRow().getData().fone) } },
    //{ title: 'Del', formatter: iconLixeira, width: 25, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { if (cell.getRow().getData().progress < 11){ confirmDel(cell.getRow().getData().nameC, cell.getRow().getData().chave) }} },
    // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
    //{ title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
    // { title: 'Custom', field: 'custom', align: 'center', formatter: reactFormatter(<SimpleButton />) },
    //{ title: 'Custom', field: 'custom', align: 'center' },
  ];

  /*   const columnsProp = [
      //{ formatter: "responsiveCollapse", headerSort: false, width: 30, minWidth: 30, align: "center", resizable: false, responsive: 0 },
      //{ title: 'Id', field: 'id', align: 'center', width: 40, visible: false, responsive: 0 },
      // {
      //  title: 'Data', field: 'createdAt', align: 'center', width: 80, responsive: 0, formatter: "datetime", formatterParams: {
      //    inputFormat: "YYYY-MM-DD",
      //   outputFormat: "DD/MM/YY",
      //   invalidPlaceholder: "(invalid date)",
      // }, sorter: "string", headerSortStartingDir: "desc", headerSortTristate: true
      // },
      // { title: 'Data', field: 'createdAt' ,align: 'center', formatter: DataFormatter, width: 80, responsive:0, headerFilter:"input"},
      { title: 'Chave', field: 'chave', align: 'center', width: 40, visible: false, responsive: 0 },
      { title: 'Tipo', field: 'tipo', align: "center", width: 200, responsive: 0, },
      { title: 'Numero', field: 'prop', align: "center", width: 150, responsive: 0, headerSort: true, sorter: "string", headerSortStartingDir: "desc" },
      { title: 'Valor Total', field: 'vlr_total', align: "center", width: 150, responsive: 0, headerSort: false },
      { title: 'Parcelas', field: 'parc', align: "center", width: 80, responsive: 0, headerSort: false },
      { title: 'Valor Parcelas', field: 'vlr_parc', align: "center", width: 150, responsive: 0, headerSort: false },
      { title: 'Prazo', field: 'qtdDias', align: "center", width: 80, responsive: 0, headerSort: false },
      //{ title: 'Download', formatter: wordIcon, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { geraWordProp(cell.getRow().getData().chave, cell.getRow().getData().prop) } },
      //{ title: 'Deletar', formatter: iconLixeira, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { confirmDelProp(cell.getRow().getData().prop, cell.getRow().getData().tipo, cell.getRow().getData().vlr_total, cell.getRow().getData().chave) } },
      //{ title: 'Contrato', formatter: iconContrato, width: 80, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert('Finalizando o desenvolvimento...') } },
      //{ title: 'Email', field: 'email', width: 170, responsive: 0, headerFilter: "input" },
      //{ title: 'Cidade/Estado', field: 'cidstate', align: 'center', width: 120, responsive: 0 },
      //{ title: 'Fone', field: 'fone', align: 'center', width: 130, responsive: 0 },
      //{ title: 'Info', field: 'msginfo', cellClick: function (e, cell) { modalBase(cell.getRow().getData().nameC, cell.getRow().getData().msginfo) } },
      //{ title: 'Origem', field: 'origem', align: 'center', headerFilter: "input", width: 100, responsive: 0 },
      //{ title: 'Age', field: 'age', align: 'left', formatter: 'progress' },
      //{ title: 'Favourite Color', field: 'color' },
      //{ title: "Progresso", field: "progress", headerSort: false, responsive: 0, formatter: "progress", formatterParams: { color: ["#ff730096", "#00bddc", "#18cc3d"] }, sorter: "number", width: 100 },
      //{ title: 'Rating', field: 'rating', align: 'center', formatter: 'star' },
      // { title: 'Link', field: 'passed', headerSort: false, align: 'center', formatter: 'tickCross', width: 60, responsive: 0 },
      //{ title: 'Q?', field: 'resp', headerSort: false, formatter: 'tickCross', width: 30, responsive: 0, cellClick: function (e, cell) { cardquestion(cell.getRow().getData().nameC, cell.getRow().getData().email, cell.getRow().getData().chave, cell.getRow().getData().resp) } },
      //{ title: 'Zap', formatter: iconwhatsappweb, width: 30, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { whatsappweb(cell.getRow().getData().nameC, cell.getRow().getData().fone) } },
      // { formatter: printIcon, width: 40, headerSort: false, responsive: 0, align: "center", cellClick: function (e, cell) { alert("Em desenvolvimento, você clickou no nome : " + cell.getRow().getData().nameC) } },
      // { title: 'Custom', field: 'custom', align: 'center', formatter: reactFormatter(<SimpleButton />) },
      //{ title: 'Custom', field: 'custom', align: 'center' },
    ]; */

  const options = {
    height: 215,
    // movableRows: true,
    clipboard: true,
    //invalidOptionWarnings: false,
    responsiveLayoutCollapseStartOpen: false,
    //groupBy:"origem",
    //groupValues:["APP_Teste"],
    //selectable:1,
    //selectablePersistence:false,
    responsiveLayout: "collapse",
    initialSort: [
      { column: "descricao", dir: "asc" }, //sort by this first
    ]
    // pagination:"local",
    // paginationSize:12,
    // paginationSizeSelector:[10, 25, 50, 100],

  };

  function Cadastro(params) {

    global.gAtuCad = 1;

    let setid = 0;
    //cl("global.gLog",global.gLog);
    //cl("global.gLog.id",global.gLog['id']);


    if (global.gLog['id'] > 0) { setid = global.gLog['id'] };




    setAtuCad(
      {
        'id': setid,
        'ean': document.getElementById('inputCodBarras').value,
        'descricao': document.getElementById('inputNome').value,
        'grupo': document.getElementById('inputGrupo').value,
        'valor': document.getElementById('inputValor').value,
        'unidade': document.getElementById('inputUnidade').value,
        'valor_custo': document.getElementById('inputValorCusto').value,
        'porc': document.getElementById('inputPorc').value,
        'estoque_min': document.getElementById('inputQtdMin').value,
        'estoque': document.getElementById('inputQtdInicial').value,
      });

    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';




  }


  function whatsappweb(nome, fone) {
    let link = "https://api.whatsapp.com/send?phone=55" + fone.replace(/_|-| |\(|\)/g, '') + "&text=Olá " + nome
    window.open(link, '_blank');

  }

  function novoCadastro(params) {
    
    
    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Novo Cadastro ..."

    global.gLog = [];


    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let eQtdInicial = document.getElementById('QtdInicial');
    eQtdInicial.style.display = '';

    let einputCodBarras = document.getElementById('inputCodBarras');
    einputCodBarras.value = '';
    einputCodBarras.focus();

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = '';


    let einputValorCusto = document.getElementById('inputValorCusto');
    einputValorCusto.value = '';

    let einputValor = document.getElementById('inputValor');
    einputValor.value = '';
    
    let einputUnidade = document.getElementById('inputUnidade');
    einputUnidade.value = '';

    let einputQtdMin = document.getElementById('inputQtdMin');
    einputQtdMin.value = '';


    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = '';

    }
    

  function editCadastro(params) {

    let eQtdInicial = document.getElementById('QtdInicial');
    eQtdInicial.style.display = 'none';

    let ele = document.getElementById('panelActions');
    ele.style.display = '';

    let etituloContr = document.getElementById('tituloContr');
    etituloContr.innerHTML = "Editar Produto ..."

    var arrFound = rep.filter(function (item, num) {
      return item.id === params;
    });

    global.gLog = arrFound[0];
    cl("global.gLog", global.gLog);
    let dtfinal = '';
    /*    if(arrFound[0].dtnasc){
          
          let dt = arrFound[0].dtnasc.split("/");
          dtfinal = ''+dt[2]+'-'+dt[1]+'-'+dt[0]+'';
        } else {
          dtfinal = '';
        } */

    //cl(dtfinal);

    let eleCodBarras = document.getElementById('inputCodBarras');
    eleCodBarras.value = arrFound[0].ean;

    let elenome = document.getElementById('inputNome');
    elenome.value = arrFound[0].descricao;
    //elenome.disabled = true;

    let einputValor = document.getElementById('inputValor');
    einputValor.value = arrFound[0].valor;
    
    let einputUnidade = document.getElementById('inputUnidade');
    einputUnidade.value = arrFound[0].unidade;

    let einputValorCusto = document.getElementById('inputValorCusto');
    einputValorCusto.value = arrFound[0].valor_custo;
    
    let einputQtdMin = document.getElementById('inputQtdMin');
    einputQtdMin.value = arrFound[0].estoque_min;

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = arrFound[0].grupo;

    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = parseFloat(arrFound[0].porc.replace(',','.'));


    //validaFone();
    CalcSimule();


  }




  function realParaNumber(texto) {
    var compativelComParseFloat = texto.replace(/R|\$|\./g, '');
    // //cl("texto R$",compativelComParseFloat);
    compativelComParseFloat = compativelComParseFloat.replace(/,/g, '.');
    ////cl("texto ,.",compativelComParseFloat);
    var valor = parseFloat(compativelComParseFloat);
    // //cl("valor",valor);

    return valor;
  }

  function CalcSimule(params) {

    /*     var iNf_valor = document.getElementById("inputNf_valor");
        let V_Nf_valor = realParaNumber(iNf_valor.value);
        if (V_Nf_valor) { iNf_valor.value = V_Nf_valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    var iVlr_pago = document.getElementById("inputValor");
    let V_pago = realParaNumber(iVlr_pago.value);
    if (V_pago) { iVlr_pago.value = V_pago.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); }
    /* 
        var iDif = document.getElementById("inputDif");
        let V_dif = realParaNumber(iDif.value);
        if (V_dif) { iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     var iPcc = document.getElementById("inputPcc");
        let V_Pcc = realParaNumber(iPcc.value);
        if (V_Pcc) { iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }); } */

    /*     if ((iNf_valor.disabled === false) && (iVlr_pago.disabled === false) && (iDif.disabled === false)) {
          V_dif = V_pago - V_Nf_valor;
          if (V_dif > 0) {
            iDif.value = V_dif.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */
    /* 
        if ((iNf_valor.disabled === false) && (iPcc.disabled === false)) {
          V_Pcc = V_Nf_valor * 0.045;
          if (V_Pcc > 0) {
            iPcc.value = V_Pcc.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
          }
        } */




  }

  function confirmDelProp(numprop, cliente) {
    //    var testeStr = 'Isso é um teste <<nome>>';
    //    testeStr = testeStr.replace('<<nome>>', nome)
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            {/* <h1>Deseja Deletar este registro ? {testeStr}</h1><br></br><br></br> */}
            <h1>Deseja Deletar este Contrato da proposta ?</h1><br></br><br></br>
            <p id='pmodal'><b>{numprop} - {cliente}</b></p>
            <br></br>
            <button className="btn bg-gradient-info btn-sm" onClick={onClose}>Não</button>
            <button
              className="btn bg-gradient-danger btn-sm float-right"
              onClick={() => {
                //alert('Em desenvolvimento no backend/servidor');
                /*                 setDelContr(
                                  {
                                    'numprop': numprop
                                  }); */
                onClose();
              }}
            >
              Sim, apague agora!
            </button>
          </div>
        );
      }
    });
  }

  function dataTable(params) {

    return (
      <>
        <div className="card card-info cardGridBgg" id="cardOcorrencias" style={{ display: '' }}>
          <div className="card-header ui-sortable-handle gertitle locCont" >
            <h3 className="card-title locCont">Produtos Cadastrados</h3>

            <div className="card-tools locCont">
              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={null} style={{ margin: '-3px 20px' }}>Novo Contato</button> */}
              <Hotkeys
                keyName="shift+c"
                onKeyDown={novoCadastro}
              //onKeyUp={novoContato}
              />
              <button type="button" className="btn bg-gradient-info btn-sm " onClick={novoCadastro} style={{ margin: '-3px 20px' }}>Novo Cadastro &#40;Shift+C&#41;</button>
              <button type="button" className="btn btn-tool" data-widget="collapse">
                <i className="fas fa-minus" id="cardGridBggI" />
              </button>

              {/* <button type="button" className="btn bg-gradient-info btn-sm " onClick={enviarEmail} style={{ margin: '-3px 20px' }}>Email</button> */}

            </div>
          </div>
          <div className="card-body locCont" id="cardGridBggBody">
            <ReactTabulator
              // ref={(ref)}
              columns={columns}
              data={rep}
              /*               rowClick={rowClick}
                            cellClick={rowClick} */
              options={options}
              events={{}}
            //data-custom-attr="test-custom-attribute"
            //className="#example-table-theme"
            />

            <div id="divLoad" className="load" style={{ display: 'none' }}> <i className="fa fa-cog fa-spin fa-5x fa-fw"></i></div>
          </div>
        </div>
      </>
    )

  }



  function page1(params) {
    return (
      <div id="panelActions" name="panelActions" style={{ position: 'relative', display: 'none' }}>
        <section className="content">
          <div className="row">
            {/* Left col */}
            <section className="col-lg-12 connectedSortable ui-sortable">
              {/* Custom tabs (Charts with tabs)*/}

              <div className="card card-info cardAddBgg" >
                <div className="card-header ui-sortable-handle" >
                  <h3 className="card-title" id="tituloContr">Novo Cadastro...</h3>
                  <div className="card-tools">
                    <button type="button" className="btn btn-tool" data-widget="collapse">
                      <i className="fas fa-minus" />
                    </button>
                  </div>
                </div>
                {/* /.card-header */}
                <div className="card-body">
                  {/* Conversations are loaded here */}
                  <form id="formAddNewContact">
                    <div className="form-row">
                      <div className="form-group col-md-2">
                        <label htmlFor="lNome">Cod. Barras*</label>
                        <input type="text" className="form-control" id="inputCodBarras" />
                      </div>
                      <div className="form-group col-md-10">
                        <label htmlFor="lNome">Descrição*</label>
                        <input type="text" className="form-control" id="inputNome" />
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="lFone">Grupo*</label>
                        <div className="input-group-prepend">
                          <input type="text" className="form-control" id="inputGrupo" />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="lEmail3">Valor de Custo</label>
                        <div className="input-group-prepend">
                          <input type="text" className="form-control" id="inputValorCusto" />
                        </div>
                      </div>
                      <div className="form-group col-md-1">
                        <label htmlFor="lEmail3">Unidade</label>
                        <div className="input-group-prepend">
                          <input type="text" className="form-control"  id="inputUnidade" />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="lEmail3">Valor de Venda</label>
                        <div className="input-group-prepend">
                          <input type="text" className="form-control" id="inputValor" />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="lCep">Comissão</label>
                        <div className="input-group-prepend">
                          <span className="input-group-text">%</span>
                          <input type="number" className="form-control" id="inputPorc" />
                        </div>
                      </div>
                      <div className="form-group col-md-2">
                        <label htmlFor="lCep">Qtd. mínima</label>
                        <div className="input-group-prepend">
                          <input type="number" className="form-control" id="inputQtdMin" />
                        </div>
                      </div>
                      <div id="QtdInicial" className="form-group col-md-2" display='none'>
                        <label htmlFor="lCep">Qtd. Inicial</label>
                        <div className="input-group-prepend">
                          <input type="number" className="form-control" id="inputQtdInicial" />
                        </div>
                      </div>
                    </div>

                    <button type="button" className="btn btn-info " id="btnGravar" onClick={e => Cadastro()}>Gravar</button>
                    <button type="button" className="btn btn-info float-right" id="btnGravarClose" onClick={e => CloseEditContr()}>Cancelar</button>
                  </form>
                </div>

              </div>
            </section>

          </div>

        </section>
      </div>

    )

  }


  function validaNome(params) {
    const eNnome = document.getElementById('inputNome')
    const nome = eNnome.value;
    if (nome.length > 3) {
      eNnome.classList.add("is-valid");
      eNnome.classList.remove("is-invalid");
      global.inpAddFrmC.inputNome = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaCid(params) {
    const eNnome = document.getElementById('inputCidade')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputCid = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function validaEmail4(params, idinput) {
    const eNemail = document.getElementById('inputEmail4')
    const email = eNemail.value;
    if (EmailValidator.validate(email)) {
      eNemail.classList.add("is-valid")
      eNemail.classList.remove("is-invalid")
      global.inpAddFrmC.inputEmail4 = true;
      //cl("true global valida", global.inpAddFrmC)
    } else {
      eNemail.classList.add("is-invalid")
      eNemail.classList.remove("is-valid")
      //cl("FALSE global valida", global.inpAddFrmC, params)
      if (params === 1) { return eNemail.focus() }
    }
    if (email) { eNemail.value = params.toLowerCase() };

  }

  function validaFone(params) {
    const eNnome = document.getElementById('inputFone')
    const nomep = eNnome.value;
    const nome = nomep.replace(/_|-/g, '');
    ////cl(nome)
    if (nome.length > 9) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      //global.inpAddFrmC.inputFone = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("(99) 99999-9999");
    im.mask(eNnome);

  }

  function formataFone(params) {
    const nome = params.replace(/_|-/g, '');
    ////cl(nome)
    //eNnome.value = params.toLowerCase();
    let im = new Inputmask("99-999999999");
    let result = im.mask(params);
    return result;

  }


  function validaObs(params) {
    const eNnome = document.getElementById('inputObs')
    const nome = eNnome.value;
    if (nome.length > 5) {
      eNnome.classList.add("is-valid")
      eNnome.classList.remove("is-invalid")
      global.inpAddFrmC.inputObs = true;
    } else {
      eNnome.classList.add("is-invalid")
      eNnome.classList.remove("is-valid")
      if (params === 1) { eNnome.focus() }
    }
    //eNnome.value = params.toLowerCase();
  }

  function CloseEditContr(params) {
    let ele = document.getElementById('panelActions');
    ele.style.display = 'none';

    let elenome = document.getElementById('inputNome');
    elenome.value = '';
    elenome.disabled = false;

    let einputValor = document.getElementById('inputValor');
    einputValor.value = '';

    let einputGrupo = document.getElementById('inputGrupo');
    einputGrupo.value = '';

    let einputPorc = document.getElementById('inputPorc');
    einputPorc.value = '';

  }

  if (repCliout.length < 1) {
    //if (0 == 1) {
    return (
      <div className="content-wrapper" >
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando Clientes ...</div>
      </div >
    )
  } else {
    return (
      <div className="content-wrapper">
        {page1()}
        {dataTable()}


      </div>
    )

  }


}

export default Gerador   