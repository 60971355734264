module.exports = {

    gContrato : '',
    gTeste : '',
    gProp : '',
    gTipo : '',
    gNome : '',
    gDoc : '',
    gEmail : ''


};