import React, { useState, useEffect, useRef, useContext } from "react";
import Loading from "./assets/loading4.gif";
import Logo from "./assets/login.png";
import "./components/functions";
import 'react-tabulator/lib/styles.css'; // required styles
import { cl } from "./components/functions";
import './homeBase.css';
import axios from "axios";
import * as EmailValidator from 'email-validator';
import Inputmask from "inputmask";
import moment from "moment";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { Link, animateScroll as scroll } from "react-scroll";
import Hotkeys from 'react-hot-keys';


function Gerador() {

  const [Atu, setAtu] = useState([]);
  const [rep, setrep] = useState([]);
  const [repCliout, setrepCliout] = useState([]);


  useEffect(() => {


  }, [rep])

  useEffect(() => {


  }, [repCliout])


  useEffect(() => {
    /*     if (isFirstRunDados.current) {
          isFirstRunDados.current = false;
          //cl("FirstRunDados false")
          return;
        } */
    let ignore = false;

    async function fetchdata() {
      var bodyFormData = new FormData();
      bodyFormData.set('apikey', '9QV045NV$Vq3cVBNh@$MG@$OJG48ugyf987nsmIlHoEs');
      //bodyFormData.set('numcontr', gGlobal.gContrato);
      bodyFormData.set('comp', 1);
      //bodyFormData.set('password', password);
      //bodyFormData.append('password', 'senha');
      const urlfull = 'https://api.youevolution.com.br/listaProd.php';
      //cl("urlfull",urlfull);
      const response = await axios({
        method: 'post',
        url: urlfull,
        data: bodyFormData,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
      //cl("response",response);
      setrepCliout(response);
      if (!ignore) {
        const redata = [{}];
        const redata2 = response['data'];
        if (redata2) {
          setrep(redata2);
        } else {
          setrep(redata);
        }
        //const redata = [{prop:"1210140551/19", contr:"C1210140551/19",cliente:"CONDOMINIO EDIFICIO VILA CAROLINA",tipo:"Auditoria de Gestão",status:"Editando"}];
        //cl(redata);


        let eleLoading = document.getElementById('divLoad');
        if (eleLoading) { eleLoading.style.display = 'none'; };
      }
    }
    fetchdata();
    //cl('gcontrato', gGlobal.gContrato);

    return () => { ignore = true };
  }, [Atu])
  
  function randomNotification() {
    const randomItem = Math.floor(Math.random() * 5);
    const notifTitle = 'Titulo';
    const notifBody = 'Creado por Teste';
    const notifImg = Logo;
    const options = {
      body: notifBody,
      icon: notifImg,
    };
    new Notification(notifTitle, options);
    setTimeout(randomNotification, 30000);
  }

  function setNotificar(params) {

    Notification.requestPermission().then((result) => {
      if (result === "granted") {
        randomNotification();
      }
    });
    


  }
  
  function setVideo(params) {

    let url ="https://player.vimeo.com/video/691880559?h=453c22ab75&background=1&badge=0&muted=1&autoplay=1&autopause=0&loop=1&player_id=0&app_id=58479";
    let eibanneryouflix1 = document.getElementById('ibanner-youflix-1');

    eibanneryouflix1.src = url;
    cl("clurl",url);
    
  }

  function menuHB(params) {


    return (
      <div className="menuHB">

      </div>
    )
  }
  function page1(params) {
    return (
      <div className="banner-youflix">
        <div className="banner-youflix-1">
          <iframe src="" frameBorder={0} allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="ibanner-youflix-1" id="ibanner-youflix-1" />
        </div>
      <button className="login100-form-btn" onClick={() => setVideo()}> ok</button>
      <button className="login100-form-btn" onClick={() => setNotificar()}> Notificar</button>
      </div>
    )

  }


  if (repCliout.length < 1) {
    //if (0 == 1) {
    return (
      <div className="you-home-base" >
        <div className="container-login100-form-btn">
          <img id="imgloading" width="250px" alt="Loading.." src={Loading}></img>

        </div>
        <div className="text-center p-t-30">Aguarde, Localizando ...</div>
      </div >
    )
  } else {
    return (
      <div className="you-home-base">
        {menuHB()}
        {page1()}
      </div>
    )

  }


}

export default Gerador   