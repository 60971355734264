import React, { Component, useState, useEffect, useRef, useContext, Children } from "react";

function abreviar(str) {

if (str) {
  
  const [nome, ...sobrenomes] = str.split(' ');

  const abreviaturas = sobrenomes.reduce((arr, str) => {
    const letraGrande = str.match(/[A-ZÖÄÅÀÁÂÃÌÍÒÓÉÊÚ]/);
    if (!letraGrande) return arr;
    return arr.concat(`${letraGrande[0]}.`);
  }, []);

  return [nome, ...abreviaturas].join(' ');
} else {
  return str;
}
}

function checkPago(idcaixamov) {

  //console.log("idcaixamov", idcaixamov)
  
    if ((idcaixamov === null) || (idcaixamov === '')) {
      return ;
    } else {
      return '💰 ';
    }
  }

function abrevia(str) {
  const nome = str.replace(/\s+/gi, ' ').trim();
  var array_nome = nome.split(' ');
  if (array_nome.length > 2) {
    return array_nome.map((parte, index, nomes) => (index != 1) ? parte : `${parte[0]}.`).join(' ');
  } else {
    return str;
  }
}



function serv(params, type) {
  //console.log("params", params,type)
  let result = '';
  if ((params) && (params !== 'undefined') && (params !== 'Undefined')) {

    result = params;
  } else if (type === 'bloqueado') {

    result = ''

  } else {

    return 'serviço ?';
  }

  return result
}

function cli(params, type) {
  console.log("params", params,type)
  let result = '';
  if ((params) && (params !== 'undefined') && (params !== 'Undefined') && (type !== 'faltou')) {

    result = params;
  } else if (type === 'bloqueado') {

    result = '********************  Bloqueado ********************'

  } else if (type === 'faltou') {

    result = '(*faltou) ' + params;;

  } else {

    result = params;
  }

  return result
}

export default class AgendaCustomEvent extends React.Component {
  render() {
    return (
      <div id="eventlabel">
        <p id="eventlabelTitleAgenda">
          {'Profissional: '+this.props.event.resourceTitle}{' ('+this.props.event.id+')'}
        </p>
        <p id="eventlabelTitleAgenda"><b>
        {checkPago(this.props.event.idcaixamov)}{cli(this.props.title,this.props.event.type)}
          </b></p>
        <p id="eventlabelServices">
          {serv(this.props.event.services,this.props.event.type)}
        </p>
        {/* <button onClick={() => console.log(this.props)}>ok</button> */}
      </div>
    );
  }
}